import React from 'react'

// stylesheet
import styles from '../filtersort.module.css'

// general imports
import { IconsRepository } from '../../../../repository/icons/icon.repository'

type Props = {
  event: string
  action: (str: string) => void
}

const SortController: React.FC<Props> = ({ event, action }): JSX.Element => {
  return (
    <div className={styles.controller}>
      <button
        onClick={() => {
          action('sort')
        }}
      >
        {event}
        <IconsRepository.SortIcon />
      </button>
    </div>
  )
}

export default SortController
