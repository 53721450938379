import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Button from '../../molecules/button/button.molecule'

import styles from './blocks.module.css'
import { BlockClickProps } from '../email-verification/Block1.template'
import { ButtonTypes } from '../../atoms/enums'
import PinInput from 'react-pin-input'
import Loader from '../../atoms/loader/Loader'
import { publicRequest } from '../../../constants/configs'
import Popup from '../../organisms/popup/Popup.organism'

interface Block3Props extends BlockClickProps {
  pin: string
  setPin: (p: string) => void
}

const Block3: React.FC<Block3Props> = ({ setBlock, email, pin, setPin }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    const fields = document.getElementsByClassName('pincode-input-text')

    if (pin.length === 5) {
      const field = fields[fields.length] as HTMLElement
      if (field) field.style.borderColor = 'var(--platform-secondary-700)'
    }

    for (let i = 0; i < fields.length; i++) {
      if (fields[i] !== undefined && (fields[i]?.ariaLabel as string)?.length > 0) {
        ;(fields[i] as HTMLElement).style.borderColor = 'var(--platform-secondary-700)'
      }
    }
  }, [pin])

  const verifyOTP = async () => {
    try {
      setLoading(true)
      const response = await publicRequest.post('/auth/reset/password/check-code', {
        // eslint-disable-next-line
        verification_code: pin,
        email: email,
      })
      if (response.data.valid) {
        setBlock(3)
      } else {
        setError(true)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {loading ? <Loader /> : <></>}
      {error ? (
        <Popup toggleActive={setError} active={error} title='error' msg={'Invalid OTP Code'} />
      ) : (
        ''
      )}
      <div className={styles.container}>
        <h3 style={{ textAlign: 'center' }}>Email Verification</h3>
        <p>
          Enter Otp Sent to your email: <span color='red'>{email}</span>
        </p>
        <div className={styles.pin__container}>
          <span className={styles.field__title}></span>
          <div style={{ marginBottom: '2.4rem', marginTop: '0.8rem' }}>
            <PinInput
              length={5}
              initialValue=''
              secret={false}
              onChange={(value: string) => {
                setPin(value)
              }}
              type='numeric'
              inputMode='number'
              style={{
                padding: '10px 0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                // flexWrap: 'wrap',
              }}
              inputStyle={{
                border: '2px solid #717171',
                color: 'var(--platform-secondary-700)',
                marginRight: '0.4rem',
                fontWeight: 800,
                fontSize: '40px',
                borderRadius: '16px',
                width: '65px',
                height: '65px',
              }}
              inputFocusStyle={{
                border: '2px solid var(--platform-secondary-700)',
                boxShadow: '0px 0px 8px var(--platform-secondary-700)',
              }}
              onComplete={(value: string) => {
                setPin(value)
              }}
              autoSelect={true}
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
          </div>
        </div>

        <Button
          type={ButtonTypes.PRIMARY}
          text={'Confirm'}
          width='100%'
          fontSize='16px'
          height='48px'
          disabled={pin.length !== 5}
          onClick={() => {
            verifyOTP()
          }}
        />
        <p className={styles.resend}>
          Didn&apos;t receive the email? <Link to=''>Click Resend</Link>
        </p>
      </div>
    </>
  )
}

export default Block3
