import React from 'react'

// stylesheet
import styles from './loader.module.css'

type Props = {
  activity?: string
}

const Loader: React.FC<Props> = ({ activity }) => {
  return (
    <div className={styles.loader}>
      {activity !== undefined ? <h3>{activity}</h3> : null}
      <div className={styles.ellipsis}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default Loader
