/* eslint-disable */
import React, { Fragment } from 'react'

import styles from './featuredcourses.module.css'

import Swiper from '../../../organisms/swiper/Swiper.organism'
import { IconsRepository } from '../../../../repository/icons/icon.repository'
import { ButtonTypes, IconDirection } from '../../../atoms/enums'
import Button from '../../../molecules/button/button.molecule'
import FeaturedCard from '../../../organisms/cards/features-cards/FeaturedCard.organism'
import { Link } from 'react-router-dom'
import { appPaths } from '../../../../constants/app-paths'
import useWindowSize from '../../../../lib/hooks/useWindowSize'
import { useAppSelector } from '../../../../lib/hooks'
import { IdName } from '../../../../types'
import CourseCard from '../../../organisms/cards/course-card/CourseCard.organism'

const FeaturedCourses: React.FC = () => {
  const { width } = useWindowSize()
  const { courses, isLoading } = useAppSelector((state) => state.course)

  return (
    <section className={styles.wrapper}>
      <div className={styles.body}>
        <div className={styles.title}>
          <p>Best Courses at Your Fingertips</p>
          <h1>Our Featured Courses</h1>
        </div>
        {isLoading ? (
          <Swiper singleSlideWidth={`${317}px`} gap='1.6rem'>
            {Array(16)
              .fill(0)
              .map((_course, index) => {
                return (
                  <Fragment key={index}>
                    <CourseCard isLoading={true} cover={undefined} name={undefined} />
                  </Fragment>
                )
              })}
          </Swiper>
        ) : (
          ''
        )}
        <div className={styles.swiperBody}>
          <Swiper
            singleSlideWidth={`${width < 500 ? 300 : 400}px`}
            gap='2.4rem'
            p={styles.swiperPadding}
          >
            {courses.map((course) => {
              return (
                <Fragment key={course.id}>
                  <FeaturedCard
                    categories={course.categories!}
                    title={course.name as string}
                    description={course.description as string}
                    lessons={course.lessons as IdName[]}
                    length={6700}
                    image={course.cover?.url || ''}
                    id={course.id}
                  />
                </Fragment>
              )
            })}
          </Swiper>
        </div>
        <div className={styles.more}>
          <p>Actually we have more courses for you. </p>
          <Link
            style={{
              textDecoration: 'none',
            }}
            to={appPaths.COURSES_PAGE}
          >
            <Button
              type={ButtonTypes.SECONDARY}
              iconDir={IconDirection.RIGHT}
              text={'View All Courses'}
              icon={
                <span
                  style={{
                    transform: 'rotate(-180deg)',
                  }}
                >
                  <IconsRepository.ArrowLeftIcon size={16} />
                </span>
              }
              fontSize='16px'
              className={styles.custom}
            />
          </Link>
        </div>
      </div>
    </section>
  )
}

export default FeaturedCourses
