import React from 'react'
import { IconProps } from '../types'

const PlusIcon = ({ size = 32, color = 'currentColor', stroke = 2, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      stroke={`${stroke}`}
      viewBox='0 0 33 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M5.5 16.894H27.5'
        stroke='black'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill={color}
        {...props}
      />
      <path
        d='M16.5 5.89404V27.894'
        stroke='black'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill={color}
      />
    </svg>
  )
}

export default PlusIcon
