import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ButtonTypes } from '../../../components/atoms/enums'
import Loader from '../../../components/atoms/loader/Loader'
import Button from '../../../components/molecules/button/button.molecule'
import ImageContainer from '../../../components/molecules/image-container/ImageContainer.module'
import CustomInputField from '../../../components/molecules/input/CustomInput.molecule'
import { appPaths } from '../../../constants/app-paths'
// import { setUser } from '../../../features/auth/slices/auth.slice'
// import { useAppDispatch } from '../../../lib/hooks'
import ValidateFunction, {
  ValidationTypes,
  min8DigitRegex,
  oneNumericCharacterRegex,
  specialCharacterRegex,
  upperCaseRegex,
} from '../../../lib/utils/validate-function'
import { loginImage } from '../../../repository/assets'
import { IconsRepository } from '../../../repository/icons/icon.repository'
import { RegisterDataType } from '../../../types/auth.types'
// import GoogleIcon from '../../../Icons/GoogleIcon'

import styles from '../login/login.module.css'

import { useAppDispatch, useAppSelector } from '../../../lib/hooks'
import { APIStatus } from '../../../constants/configs'
import { resetAuthenticationState } from '../../../features/authentication/authenticationSlice'
import { registerThunkService } from '../../../features/authentication/authenticationThunks'
import UseError from '../../../lib/hooks/useError'
import Popup from '../../../components/organisms/popup/Popup.organism'
import { generateStrongPassword } from '../../../lib/utils/generatePassword'

function SignUp() {
  const [passwordShow, setPasswordShow] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const suggestionRef = useRef<HTMLDivElement | null>(null)
  const [leftPercentage, setLeftPercentage] = useState(50)
  const [copied, setCopied] = useState(false)

  const { setErrorActive, errorActive } = UseError()

  const [formData, setFormData] = useState<RegisterDataType>({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
  })
  const { firstName, lastName, confirmPassword, email, password } = formData

  const [formErrors, setFormError] = useState({} as ValidationTypes)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })

    setFormError({ ...formErrors, [e.target.name]: '' })
  }

  const auth = useAppSelector((state) => state.authentication)

  const handleShowPassword = () => {
    setPasswordShow(!passwordShow)
  }
  const handleShowPassword2 = () => {
    setShowPassword(!showPassword)
  }

  const handleSubmit = async (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()

    const { errors } = ValidateFunction(formData)
    setFormError(errors)

    if (Object.keys(errors).length > 0) {
      return
    }

    const data = {
      email: formData.email,
      password: formData.password,
      firstName: formData.firstName,
      lastName: formData.lastName,
    }

    dispatch(registerThunkService(data))
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])

  useEffect(() => {
    if (auth.errorTrace === true) {
      setErrorActive(true)
    }

    if (auth.status === APIStatus.FULFILLED) {
      dispatch(resetAuthenticationState())

      navigate(`${appPaths.VERIFY_EMAIL}?email=${email}`)
    }
  }, [auth.errorTrace, auth.status])

  const handleMouseMove = (event: React.MouseEvent) => {
    if (!suggestionRef.current) return

    const divWidth = suggestionRef.current.offsetWidth
    const mouseX = event.pageX - suggestionRef.current.offsetLeft
    let percentage = (mouseX / divWidth) * 100 - 5
    percentage = percentage > 70 ? 70 : percentage
    percentage = percentage < 0 ? 0 : percentage
    setLeftPercentage(percentage)
  }

  const suggestedPassword = useMemo(() => {
    return generateStrongPassword()
  }, [])

  const copy = async (text: string) => {
    await navigator.clipboard.writeText(text)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 5000)
  }

  const setSuggestedPassword = () => {
    setFormData({ ...formData, password: suggestedPassword, confirmPassword: suggestedPassword })
    setPasswordShow(true)
    setShowPassword(true)
    copy(suggestedPassword)
  }

  return (
    <div className={styles.parent__div}>
      <Popup
        toggleActive={setCopied}
        active={copied}
        title='Password Copied'
        msg={'Password has been copied to your clipboard.'}
      />
      {errorActive ? (
        <Popup
          toggleActive={setErrorActive}
          active={errorActive}
          title='error'
          msg={auth.message}
        />
      ) : (
        ''
      )}
      {auth.status === APIStatus.FULFILLED ? (
        <Popup
          toggleActive={setErrorActive}
          active={auth.status === APIStatus.FULFILLED}
          title='success'
          msg={'Successfully registered! Please check your email to verify your account.'}
        />
      ) : (
        ''
      )}
      {auth.status === APIStatus.PENDING ? <Loader /> : ''}
      <div className={styles.input__div}>
        <div className={styles.logo}>
          <Link to='/'>
            <IconsRepository.LogoIcon />
          </Link>
        </div>
        <form className={styles.content}>
          <div>
            <h1 className={styles.signup__title}>Sign up</h1>
            <p className={styles.p__styles}>Embark on your transformative journey</p>
            <div>
              <span className={styles.field__title}>
                First Name<sup>*</sup>
              </span>
              <div className={styles.input__wrapper}>
                <CustomInputField
                  name='firstName'
                  value={firstName}
                  onChange={onChange}
                  placeholder='First Name'
                />
                <p className={styles.validate__error}>{formErrors.firstName}</p>
              </div>
            </div>
            <div>
              <span className={styles.field__title}>
                Last Name<sup>*</sup>
              </span>
              <div className={styles.input__wrapper}>
                <CustomInputField
                  name='lastName'
                  value={lastName}
                  onChange={onChange}
                  placeholder='Last Name'
                />
                <p className={styles.validate__error}>{formErrors.lastName}</p>
              </div>
            </div>
            <div>
              <span className={styles.field__title}>
                Email<sup>*</sup>
              </span>
              <div className={styles.input__wrapper}>
                <CustomInputField
                  name='email'
                  value={email}
                  onChange={onChange}
                  placeholder='Enter your Email'
                />
                <p className={styles.validate__error}>{formErrors.email}</p>
              </div>
            </div>

            <div>
              <span className={styles.field__title}>
                Password<sup>*</sup>
              </span>
              <div
                className={styles.input__wrapper}
                ref={suggestionRef}
                onMouseMove={handleMouseMove}
              >
                <div
                  className={`${styles.suggestion} ${password ? styles.hidesuggestion : ''}`}
                  style={{ left: `${leftPercentage}%` }}
                  onClick={setSuggestedPassword}
                >
                  <span>Suggested Password</span>
                  <p>{suggestedPassword}</p>
                </div>
                <CustomInputField
                  name='password'
                  type={passwordShow ? 'text' : 'password'}
                  value={password}
                  onChange={onChange}
                  left={true}
                  placeholder='Enter your Password'
                  rightIcon={
                    passwordShow ? (
                      <span style={{ cursor: 'pointer' }}>
                        <IconsRepository.EyeIcon
                          size={20}
                          color='#0F172A'
                          onClick={handleShowPassword}
                        />
                      </span>
                    ) : (
                      <span style={{ cursor: 'pointer' }}>
                        <IconsRepository.EyeCrossedIcon
                          size={20}
                          color='#0F172A'
                          onClick={handleShowPassword}
                        />
                      </span>
                    )
                  }
                  icon={<IconsRepository.LockIcon />}
                />
                <p className={styles.validate__error}>{formErrors.confirmPassword}</p>
                <ul className={styles.list}>
                  {!specialCharacterRegex.test(password) ? (
                    <li>One special character (!@#$%^&*#+-=)</li>
                  ) : (
                    ''
                  )}
                  {!min8DigitRegex.test(password) ? <li>Min 8 characters</li> : ''}
                  {!oneNumericCharacterRegex.test(password) ? (
                    <li>One number (2 are recommended)</li>
                  ) : (
                    ''
                  )}
                  {!upperCaseRegex.test(password) ? <li>One capital letter</li> : ''}
                </ul>
              </div>
            </div>
            <div>
              <span className={styles.field__title}>
                Confirm Password<sup>*</sup>
              </span>
              <div style={{ marginBottom: '2.4rem' }}>
                <CustomInputField
                  name='confirmPassword'
                  type={showPassword ? 'text' : 'password'}
                  value={confirmPassword}
                  onChange={onChange}
                  left={true}
                  placeholder='Confirm your Password'
                  icon={<IconsRepository.LockIcon />}
                  rightIcon={
                    showPassword ? (
                      <span style={{ cursor: 'pointer' }}>
                        <IconsRepository.EyeIcon
                          color='#0F172A'
                          size={20}
                          onClick={handleShowPassword2}
                        />
                      </span>
                    ) : (
                      <span style={{ cursor: 'pointer' }}>
                        <IconsRepository.EyeCrossedIcon
                          color='#0F172A'
                          size={20}
                          onClick={handleShowPassword2}
                        />
                      </span>
                    )
                  }
                />
                <p className={styles.validate__error}>{formErrors.confirmPassword}</p>
              </div>
            </div>
            {/* {message.length !== 0 ? <p className={styles.validate__error}>{message}</p> : ''} */}
            <div
              onClick={(e) => {
                handleSubmit(e)
              }}
              className={styles.submit__btn}
            >
              <Button
                type={ButtonTypes.PRIMARY}
                fontSize='16px'
                text={'Get Started'}
                width='100%'
                height='48px'
                className={styles.btn}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                // submit={(e: any) => {
                //   handleSubmit(e)
                // }}
              />
            </div>
          </div>
          <div className={styles.form__control}>
            <h4>
              Don&apos;t yet have an account?{' '}
              <span className={styles.link}>
                <Link to={appPaths.LOGIN}>Login</Link>
              </span>
            </h4>
          </div>
        </form>
      </div>
      <div className={styles.img__container}>
        <ImageContainer
          image={
            <img
              src={loginImage}
              alt='image'
              width={466}
              height={409}
              style={
                {
                  // objectFit: 'cover',
                }
              }
            />
          }
        />
      </div>
    </div>
  )
}

export default SignUp
