import React from 'react'
import { IconProps } from '../types'

const DoneIcon = ({ size = 24, color = 'currentColor', stroke = 2, ...props }: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      stroke={`${stroke}`}
      viewBox='0 0 16 16'
      fill='none'
      {...props}
    >
      <path
        d='M5.86712 10.6L3.06712 7.79999L2.13379 8.73332L5.86712 12.4667L13.8671 4.46666L12.9338 3.53333L5.86712 10.6Z'
        fill={color}
      />
    </svg>
  )
}

export default DoneIcon
