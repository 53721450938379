import React from 'react'
import { IconProps } from '../types'

const ChevronLeftIcon = ({
  size = 24,
  color = 'currentColor',
  stroke = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      stroke={`${stroke}`}
      {...props}
      viewBox='0 0 8 12'
      fill='none'
    >
      <path
        d='M7.70492 1.41L6.29492 0L0.294922 6L6.29492 12L7.70492 10.59L3.12492 6L7.70492 1.41Z'
        fill={color}
      />
    </svg>
  )
}

export default ChevronLeftIcon
