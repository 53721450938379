import React from 'react'
import { IconProps } from '../types'

const SortIcon = ({ size = 24, color = 'currentColor', stroke = 2, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      stroke={`${stroke}`}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M3 18H9V16H3V18ZM3 6V8H21V6H3ZM3 13H15V11H3V13Z' fill={color} />
    </svg>
  )
}

export default SortIcon
