export function generateStrongPassword(length = 10) {
  const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz'
  const numberChars = '0123456789'
  const symbolChars = '!@#$%^&*_-+='

  const allChars = uppercaseChars + lowercaseChars + numberChars + symbolChars
  let password = ''

  // Generate at least one character from each character set
  password += getRandomChar(uppercaseChars)
  password += getRandomChar(lowercaseChars)
  password += getRandomChar(numberChars)
  password += getRandomChar(symbolChars)

  // Generate remaining characters randomly
  for (let i = 0; i < length - 4; i++) {
    password += getRandomChar(allChars)
  }

  // Shuffle the password characters randomly
  password = shuffleString(password)

  return password
}

function getRandomChar(characters: string) {
  const randomIndex = Math.floor(Math.random() * characters.length)
  return characters[randomIndex]
}

function shuffleString(string: string) {
  let shuffledString = ''
  const charArray = string.split('')

  while (charArray.length > 0) {
    const randomIndex = Math.floor(Math.random() * charArray.length)
    shuffledString += charArray.splice(randomIndex, 1)
  }

  return shuffledString
}
