import React, { useEffect, useMemo, useState } from 'react'

import { useAppDispatch, useAppSelector } from '../../lib/hooks'
import { singleCourseThunk, courseThunk } from '../../features/course/thunks/course.thunk'
import Loader from '../../components/atoms/loader/Loader'
import Navbar from '../../components/organisms/navbar/Navbar.organism'
import Footer from '../../components/organisms/footer/Footer.organism'
import CourseIntro from '../../components/templates/course-details/course-intro/CourseIntro.template'
import FeaturedCourses from '../../components/templates/course-details/featured-courses/FeaturedCourses.template'
import FrequentlyAskedQuestions from '../../components/templates/course-details/frequently-asked-questions/FrequentlyAskedQuestions.template'
import DesignGridLayout from '../../components/layouts/Grid.layout'
import NavbarLoggedin from '../../components/organisms/navbar/NavbarLoggedin.organism'
import { defaultImage, uibootcamp } from '../../repository/assets'
import MeetInstructor from '../../components/templates/course-details/meet-instructor/MeetInstructor.template'
import Review from '../../components/templates/landing/reviews/Reviews.template'
import BootcampAbout from '../../components/templates/bootcamps/about/BootcampAbout.template'
import BootcampPeek from '../../components/templates/bootcamps/peek/BootcampPeek.template'

const BootcampUI = () => {
  const id = 1

  const dispatch = useAppDispatch()

  const { singleCourse, courses } = useAppSelector((state) => state.course)
  const authState = useAppSelector((state) => state.authentication)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!singleCourse || singleCourse.id !== id) {
      setLoading(true)
      dispatch(singleCourseThunk(String(id))).then(() => setLoading(false))
    }
    if (!courses || courses.length === 0) {
      dispatch(courseThunk())
    }
    if (id) {
      window.scrollTo(0, 0)
    }
  }, [id])

  const bootcampCourses = useMemo(() => {
    return courses.filter((course) => course.name?.startsWith('UX/UI'))
  }, [courses])

  return (
    <>
      {loading ? (
        <Loader activity='Loading bootcamp data...' />
      ) : (
        <main>
          {authState.user && authState.user.verified ? (
            <NavbarLoggedin padding='32px' userImage={defaultImage} />
          ) : (
            <Navbar />
          )}
          <CourseIntro
            id={Number(id)}
            name={'UI/UX Bootcamp from Wire-Framing to Delivering'}
            language={singleCourse?.language}
            lessons={new Array(5).fill({})}
            author={singleCourse.author}
            cover={{ url: uibootcamp }}
            categories={singleCourse.categories}
            price={singleCourse.price || 0}
            btnText='Enroll into Course 1'
          />
          <DesignGridLayout>
            {singleCourse.lessons ? <BootcampAbout courses={bootcampCourses} /> : null}
          </DesignGridLayout>
          <BootcampPeek
            categories={singleCourse.categories}
            lessons={new Array(5).fill({})}
            language={singleCourse.language}
            description='Our UI/UX Bootcamp is a comprehensive and hands-on program designed to equip you with the skills and knowledge needed to thrive in the exciting field of user interface (UI) and user experience (UX) design. In this intensive course, you will dive deep into the principles of designing intuitive and user-friendly digital experiences.'
            price={100_000}
          />
          <br />
          <br />
          <MeetInstructor {...singleCourse.author} />
          <Review />
          <DesignGridLayout>
            <FeaturedCourses />
          </DesignGridLayout>
          <FrequentlyAskedQuestions />
          <Footer />
        </main>
      )}
    </>
  )
}

export default BootcampUI
