import React, { useState, useEffect } from 'react'

// stylesheet
import styles from './filter.module.css'

// general imports
import Accordion from '../accordion/Accordion.organism'
import { FilterValueType } from '../../../types'
import { IconsRepository } from '../../../repository/icons/icon.repository'

interface Props<T> {
  optionsData: T

  setSelections: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string[]
    }>
  >

  setSubSelections: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string[]
    }>
  >

  active?: boolean
}

const FilterComponent = <T extends object>({
  optionsData,
  setSelections,
  setSubSelections,
  active = false,
}: Props<T>) => {
  const [currentModuleExpanded, setCurrentModuleExpanded] = useState<number[]>([0, 1, 2])
  const [detector, setDetector] = useState<boolean>(false)

  const [selectionState, setSelectionState] = useState<{ [key: string]: string[] }>({})
  const [subSelectionState, setSubSelectionState] = useState<{ [key: string]: string[] }>({})

  const btnOnClick = (moduleIndex: number) => {
    const target = currentModuleExpanded

    if (target.includes(moduleIndex)) {
      target.splice(target.indexOf(moduleIndex), 1)
    } else {
      target.push(moduleIndex)
    }

    setCurrentModuleExpanded(target)
    setDetector(!detector)
  }

  const addFilterSelectionToState = (val: string, selectionField: string) => {
    const dataTarget = selectionState[selectionField as keyof typeof selectionState]

    if (dataTarget.includes(val)) {
      dataTarget.splice(dataTarget.indexOf(val), 1)
    } else {
      dataTarget.push(val)
    }

    setSelectionState({ ...selectionState, [selectionField]: dataTarget })
  }

  useEffect(() => {
    // eslint-disable-next-line
    const data: any = {}
    const dataKeys = Object.keys(optionsData)

    for (let i = 0; i < dataKeys.length; i++) {
      const selector: string = dataKeys[i]
      // eslint-disable-next-line
      data[selector as keyof any] = []
    }

    setSelectionState(data)
    setSubSelectionState(data)
  }, [optionsData])

  useEffect(() => {
    setSelections(selectionState)
    setSubSelections(subSelectionState)
  }, [selectionState, subSelectionState])

  return (
    <div className={`${styles.filter} ${active ? styles.show : styles.hide}`}>
      {optionsData !== undefined &&
        Object.entries(optionsData).map(
          ([key, value]: [key: string, value: FilterValueType[]], index: number) => {
            return (
              <div key={key} className={styles.filter__block}>
                <Accordion
                  title={
                    <>
                      <div className={styles.head}>
                        <h2>{key.charAt(0).toUpperCase() + key.slice(1)}</h2>
                      </div>
                    </>
                  }
                  icon={<IconsRepository.DropDownIcon />}
                  iconClose={
                    <span style={{ transform: 'rotate(-180deg)' }}>
                      <IconsRepository.DropDownIcon />
                    </span>
                  }
                  isOpen={active && currentModuleExpanded.includes(index)}
                  // isOpen={currentModuleExpanded.includes(index)}
                  btnOnClick={() => btnOnClick(index)}
                  content={
                    <>
                      <div
                        style={{
                          paddingBottom: '4rem',
                        }}
                        className={styles.content}
                      >
                        {value.map((dur, index) => {
                          return (
                            <div
                              key={index + dur.key}
                              className={`${styles.option} ${
                                index !== value.length - 1 ? styles.space : ''
                              }`}
                            >
                              <h4 className={styles.value}>
                                <span
                                  onClick={() => {
                                    addFilterSelectionToState(dur.value, key)
                                  }}
                                >
                                  {selectionState[key] !== undefined &&
                                  selectionState[key].includes(dur.value) ? (
                                    <IconsRepository.CheckboxCheckedIcon />
                                  ) : (
                                    <IconsRepository.CheckboxIcon />
                                  )}
                                  {dur.value}
                                </span>
                              </h4>
                            </div>
                          )
                        })}
                      </div>
                    </>
                  }
                />
              </div>
            )
          },
        )}
    </div>
  )
}

export default FilterComponent
