import React, { useRef, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import styles from './hero.module.css'
import {
  Selma,
  Frida,
  Precious,
  Leonard,
  Sonia,
  Darelle,
  Nicole,
} from '../../../../repository/assets/videos'

import Button from '../../../molecules/button/button.molecule'
import { ButtonTypes, IconDirection } from '../../../atoms/enums'
import ArrowRight from '../../../../icons/ArrowRight.icon'
import RoundText from '../../../molecules/round-text/RoundText.molecule'

const mobileCardData = [
  { video: Selma },
  { video: Frida },
  { video: Precious },
  { video: Leonard },
  { video: Sonia },
  { video: Darelle },
  { video: Nicole },
]

const mod = (n: number, m: number) => {
  const result = n % m
  return result >= 0 ? result : result + m
}

const dragThreshold = 100

const Hero: React.FC = () => {
  const contRef = useRef<HTMLDivElement | null>(null)
  const [startX, setStartX] = useState<number | null>(null)
  const [activeIndex, setActiveIndex] = useState(3)
  const [indexLeft, setIndexLeft] = useState(mod(activeIndex - 1, mobileCardData.length))
  const [indexRight, setIndexRight] = useState(mod(activeIndex + 1, mobileCardData.length))
  const [nextLeft, setNextLeft] = useState(mod(indexLeft - 1, mobileCardData.length))
  const [nextRight, setNextRight] = useState(mod(indexRight + 1, mobileCardData.length))

  useEffect(() => {
    const _indexLeft = mod(activeIndex - 1, mobileCardData.length)
    const _indexRight = mod(activeIndex + 1, mobileCardData.length)
    const _nextLeft = mod(_indexLeft - 1, mobileCardData.length)
    const _nextRight = mod(_indexRight + 1, mobileCardData.length)

    setIndexLeft(_indexLeft)
    setIndexRight(_indexRight)
    setNextLeft(_nextLeft)
    setNextRight(_nextRight)
  }, [activeIndex])

  const clickLeft = () => {
    setActiveIndex(mod(activeIndex - 1, mobileCardData.length))
  }

  const clickRight = () => {
    setActiveIndex(mod(activeIndex + 1, mobileCardData.length))
  }

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setStartX(e.clientX)
  }

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setStartX(e.touches[0].clientX)
  }

  const handleTouchEnd = () => {
    setStartX(null)
  }

  const handleTouchDrag = (e: React.TouchEvent<HTMLDivElement>) => {
    if (!startX || !contRef.current) {
      return
    }

    const touch = e.touches[0].clientX
    const dragDistance = touch - startX

    if (dragDistance > dragThreshold) {
      clickLeft()
      setStartX(null)
    } else if (dragDistance < 0 - dragThreshold) {
      clickRight()
      setStartX(null)
    }
  }

  const handleMouseUp = () => {
    setStartX(null)
  }

  const handleDrag = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!startX || !contRef.current) {
      return
    }

    const dragDistance = e.clientX - startX

    if (dragDistance > dragThreshold) {
      clickLeft()
      setStartX(null)
    } else if (dragDistance < 0 - dragThreshold) {
      clickRight()
      setStartX(null)
    }
  }

  return (
    <section className={styles.body}>
      <div className={styles.title}>
        <h1> Fostering the next generation of Female Tech Talents</h1>
        <p>Acquire industry standard skills for free and land your dream job.</p>
        <div className={styles.buttons}>
          <Link to='/auth/login'>
            <Button
              type={ButtonTypes.PRIMARY}
              text={'Sign up Now'}
              className={styles.enrollBtn}
              fontSize='16px'
            />
          </Link>
          <Link to='/all-courses'>
            <Button
              type={ButtonTypes.SECONDARY}
              text={'Browse all courses'}
              icon={<ArrowRight />}
              iconDir={IconDirection.RIGHT}
              className={styles.customBtn}
              fontSize='16px'
            />
          </Link>
        </div>
      </div>
      <div className={styles.container}>
        <RoundText />
        <div className={styles.videoContainer}>
          <VideoBox source={Darelle} />
          <DoubleVideoBox vid1={Frida} vid2={Leonard} />
          <FullVideoBox vid={Selma} />
          <DoubleVideoBox vid1={Precious} vid2={Sonia} />
          <VideoBox source={Nicole} />
        </div>
      </div>
      <div
        className={styles.mobileVideoContainer}
        ref={contRef}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
        onMouseUp={handleMouseUp}
        onTouchEnd={handleTouchEnd}
        onMouseMove={handleDrag}
        onTouchMove={handleTouchDrag}
      >
        {mobileCardData.map((item, index) => {
          const getClass = () => {
            let _class = ''
            switch (index) {
              case indexLeft:
                _class = styles.videoLeft
                break
              case indexRight:
                _class = styles.videoRight
                break
              case nextLeft:
                _class = styles.nextLeft
                break
              case nextRight:
                _class = styles.nextRight
                break
              case activeIndex:
                _class = styles.videoActive
                break
              default:
                _class = styles.mobileVideo
            }

            return _class
          }

          return (
            <VideoBox
              source={item.video}
              key={item.video}
              onClick={index === indexLeft ? clickLeft : index === indexRight ? clickRight : null}
              isActive={index === activeIndex}
              extraClass={getClass()}
            />
          )
        })}
      </div>
    </section>
  )
}

export default Hero

const VideoBox: React.FC<{
  source: string
  onClick?: (() => void) | null
  extraClass?: string
  isActive?: boolean
}> = ({ source, onClick, extraClass, isActive = false }) => {
  const vidRef = useRef<HTMLVideoElement | null>(null)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (vidRef.current && source) {
      vidRef.current?.load()
      setLoaded(true)
    }
  }, [source])

  useEffect(() => {
    if (isActive && vidRef.current && loaded) {
      vidRef.current?.play()
      return
    }

    vidRef.current?.pause()
  }, [isActive])

  const pause = () => {
    vidRef.current?.pause()
  }

  const play = () => {
    vidRef.current?.play()
  }

  return (
    <div
      className={`${styles.video} ${extraClass}`}
      onMouseEnter={play}
      onMouseLeave={pause}
      onClick={onClick || undefined}
    >
      <video loop ref={vidRef} preload='none'>
        <source src={source} type='video/mp4' />
      </video>
    </div>
  )
}

const DoubleVideoBox: React.FC<{ vid1: string; vid2: string }> = ({ vid1, vid2 }) => {
  return (
    <div className={styles.double}>
      {/* <Link to='/course-details/:2'> */}
      <VideoBox source={vid1} />
      {/* </Link> */}
      {/* <Link to='/course-details/:3'> */}
      <VideoBox source={vid2} />
      {/* </Link> */}
    </div>
  )
}

const FullVideoBox: React.FC<{ vid: string }> = ({ vid }) => {
  return (
    <div className={styles.full}>
      <VideoBox source={vid} />
    </div>
  )
}
