import React from 'react'

import styles from './roundtext.module.css'
import { Group } from '../../../repository/assets/svg'

const RoundText = () => {
  return (
    <div className={styles.image}>
      <img src={Group} height='100%' width='100%' />
    </div>
  )
}

export default RoundText
