import React from 'react'
import { IconProps } from '../types'

const IndicatorIcon = ({ size = 16, color = 'currentColor' }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8 16.3096C12.4183 16.3096 16 12.7278 16 8.30957C16 3.89129 12.4183 0.30957 8 0.30957C3.58172 0.30957 0 3.89129 0 8.30957C0 12.7278 3.58172 16.3096 8 16.3096Z'
        fill={color}
      />
    </svg>
  )
}

export default IndicatorIcon
