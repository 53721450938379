import React, { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import PinInput from 'react-pin-input'

import Button from '../../molecules/button/button.molecule'

import styles from './blocks.module.css'
import { ButtonTypes } from '../../atoms/enums'
import { BlockClickProps } from './Block1.template'
import { useAppDispatch, useAppSelector } from '../../../lib/hooks'
import {
  resendOTPThunkService,
  verifyOPTThunkService,
} from '../../../features/authentication/authenticationThunks'
import { RootState } from '../../../app/store'
import { APIStatus } from '../../../constants/configs'
import Loader from '../../atoms/loader/Loader'
import { appPaths } from '../../../constants/app-paths'
import UseError from '../../../lib/hooks/useError'
import Popup from '../../organisms/popup/Popup.organism'

const Block2: React.FC<BlockClickProps> = () => {
  const [pin, setPin] = useState<string>('')
  const [search] = useSearchParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { setErrorActive, errorActive } = UseError()

  const verification = useAppSelector((state: RootState) => state.verification)

  const handleVerification = async (pin: string) => {
    if (search.get('email')) {
      dispatch(
        // eslint-disable-next-line
        verifyOPTThunkService({ verification_code: pin, email: search.get('email') as string }),
      )
    } else {
      alert('No email found. Please manually enter your email.')
    }
  }

  const resendOtphandler = async () => {
    if (search.get('email')) {
      dispatch(resendOTPThunkService({ email: search.get('email') as string }))
    } else {
      alert('No email found. Please manually enter your email.')
    }
  }

  useEffect(() => {
    const fields = document.getElementsByClassName('pincode-input-text')

    if (pin.length === 5) {
      const field = fields[fields.length] as HTMLElement
      if (field) field.style.borderColor = 'var(--platform-secondary-700)'
    }

    for (let i = 0; i < fields.length; i++) {
      if (fields[i] !== undefined && (fields[i]?.ariaLabel as string)?.length > 0) {
        ;(fields[i] as HTMLElement).style.borderColor = 'var(--platform-secondary-700)'
      }
    }
  }, [pin])

  useEffect(() => {
    if (verification.errorTrace === true) {
      setErrorActive(true)
    }

    if (verification.status === APIStatus.FULFILLED) {
      setTimeout(() => {
        navigate(appPaths.LOGIN)
      }, 2500)
    }
  }, [verification.errorTrace, verification.status])

  useEffect(() => {
    const queryPin = search.get('otp')
    if (queryPin) {
      handleVerification(queryPin)
    }
  }, [])

  return (
    <div className={styles.container}>
      {errorActive ? (
        <Popup
          toggleActive={setErrorActive}
          active={errorActive}
          title='error'
          msg={verification.message}
        />
      ) : (
        ''
      )}
      {verification.status === APIStatus.FULFILLED ||
      verification.codeSent === APIStatus.FULFILLED ? (
        <Popup
          toggleActive={setErrorActive}
          active={
            verification.status === APIStatus.FULFILLED ||
            verification.codeSent === APIStatus.FULFILLED
          }
          title='success'
          msg={
            verification.status === APIStatus.FULFILLED
              ? 'Account successfully verified!'
              : 'Code successfully sent'
          }
        />
      ) : (
        ''
      )}
      <h3>Check your email</h3>
      <p className={styles.email}>Enter the code sent to the email {search.get('email')}</p>
      {verification.status === APIStatus.PENDING || verification.codeSent === APIStatus.PENDING ? (
        <Loader />
      ) : (
        ''
      )}
      <div>
        <PinInput
          length={5}
          initialValue={search.get('otp') || ''}
          secret={false}
          onChange={(value: string) => {
            setPin(value)
          }}
          type='numeric'
          inputMode='number'
          style={{
            padding: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // flexWrap: 'wrap',
          }}
          inputStyle={{
            border: '2px solid #717171',
            color: 'var(--platform-secondary-700)',
            margin: '0.4rem',
            fontWeight: 800,
            fontSize: '40px',
            borderRadius: '16px',
            width: '65px',
            height: '65px',
          }}
          inputFocusStyle={{
            border: '2px solid var(--platform-secondary-700)',
            boxShadow: '0px 0px 8px var(--platform-secondary-700)',
          }}
          onComplete={(value: string) => {
            setPin(value)
          }}
          autoSelect={true}
          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
        />
      </div>
      <Button
        type={ButtonTypes.PRIMARY}
        fontSize='16px'
        text={'Verify Email'}
        width='100%'
        height='48px'
        onClick={() => {
          handleVerification(pin)
          // setBlock(2)
        }}
      />
      <p className={styles.resend}>
        Didn&apos;t receive the email?{' '}
        <h4
          onClick={() => {
            resendOtphandler()
          }}
        >
          Click Resend
        </h4>
      </p>
    </div>
  )
}

export default Block2
