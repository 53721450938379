import React, { useEffect, useState } from 'react'
import { BlockClickProps } from '../email-verification/Block1.template'
import styles from './blocks.module.css'
import { PassWordStructure } from '../../../types/auth.types'
import { useAppDispatch, useAppSelector } from '../../../lib/hooks'
import { resetPasswordThunkService } from '../../../features/authentication/authenticationThunks'
import UseError from '../../../lib/hooks/useError'
import { APIStatus } from '../../../constants/configs'
import { resetForgotPasswordSlice } from '../../../features/authentication/forgotPasswordSlice'
import Popup from '../../organisms/popup/Popup.organism'
import CustomInputField from '../../molecules/input/CustomInput.molecule'
import { IconsRepository } from '../../../repository/icons/icon.repository'
import {
  min8DigitRegex,
  oneNumericCharacterRegex,
  specialCharacterRegex,
  upperCaseRegex,
} from '../../../lib/utils/validate-function'
import { ButtonTypes } from '../../atoms/enums'
import Button from '../../molecules/button/button.molecule'
import Loader from '../../atoms/loader/Loader'

interface Block5Props extends BlockClickProps {
  pin: string
}

const Block5: React.FC<Block5Props> = ({ setBlock, email, pin }) => {
  const dispatch = useAppDispatch()
  const forgotPasswordState = useAppSelector((state) => state.forgotPassword)
  const { setErrorActive, errorActive } = UseError()
  const [formErrors, setFormError] = useState<{ password?: boolean }>({})

  const [formData, setFormData] = useState<PassWordStructure>({
    password: '',
    confirmPassword: '',
  })
  const [loading, setLoading] = useState(false)
  const [passwordShow, setPasswordShow] = useState<boolean>(false)

  const handleShowPassword = () => {
    setPasswordShow(!passwordShow)
  }

  const handleSubmit = () => {
    /**
     * @todo Validate fields for submission
     *
     */
    if (confirmPassword !== password) {
      setFormError({ password: true })
      return
    }

    setLoading(true)
    dispatch(
      resetPasswordThunkService({
        email: email as string,
        password: formData.password,
        // eslint-disable-next-line
        verification_code: pin,
      }),
    )
      .then(() => setLoading(false))
      .catch(() => setLoading(false))
  }
  const { confirmPassword, password } = formData

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    if (forgotPasswordState.errorTrace === true) {
      setErrorActive(true)
    }

    if (forgotPasswordState.status === APIStatus.FULFILLED) {
      setBlock(2)
    }

    return () => {
      dispatch(resetForgotPasswordSlice())
    }
  }, [forgotPasswordState.errorTrace, forgotPasswordState.status])

  return (
    <div className={styles.container}>
      {loading && <Loader />}
      {errorActive ? (
        <Popup
          toggleActive={setErrorActive}
          active={errorActive}
          title='error'
          msg={forgotPasswordState.message}
        />
      ) : (
        ''
      )}
      {forgotPasswordState.status === APIStatus.FULFILLED ? (
        <Popup
          toggleActive={setErrorActive}
          active={forgotPasswordState.status === APIStatus.FULFILLED}
          title='success'
          msg={'Password successfully resetted!'}
        />
      ) : (
        ''
      )}
      {forgotPasswordState.codeSent === APIStatus.PENDING ? <Loader /> : ''}
      <h3 className='new'>Set a New Password for your account</h3>
      <p className={styles.email}>
        Your new password must be different from the one you previously used.
      </p>
      <div>
        <div>
          <span className={styles.field__title}>
            Password<sup>*</sup>
          </span>
          <div style={{ marginBottom: '2.4rem', marginTop: '0.8rem' }}>
            <CustomInputField
              name='password'
              type={passwordShow ? 'text' : 'password'}
              value={password}
              onChange={onChange}
              left={true}
              placeholder='Enter your Password'
              right
              rightIcon={
                passwordShow ? (
                  <span style={{ cursor: 'pointer' }}>
                    <IconsRepository.EyeIcon
                      size={20}
                      color='#0F172A'
                      onClick={handleShowPassword}
                    />
                  </span>
                ) : (
                  <span style={{ cursor: 'pointer' }}>
                    <IconsRepository.EyeCrossedIcon
                      size={20}
                      color='#0F172A'
                      onClick={handleShowPassword}
                    />
                  </span>
                )
              }
              icon={<IconsRepository.LockIcon />}
            />
            <p className={styles.validate__error}>
              {formErrors.password ? 'Passwords Do Not Match' : null}
            </p>
          </div>
        </div>
        {password.length > 0 ? (
          <ul className={styles.list}>
            {!specialCharacterRegex.test(password) ? <li>One special character</li> : ''}
            {!min8DigitRegex.test(password) ? <li>Min 8 characters</li> : ''}
            {!oneNumericCharacterRegex.test(password) ? (
              <li>One number (2 are recommended)</li>
            ) : (
              ''
            )}
            {!upperCaseRegex.test(password) ? <li>One capital letter</li> : ''}
          </ul>
        ) : (
          ''
        )}
        <div>
          <span className={styles.field__title}>
            Confirm Password<sup>*</sup>
          </span>
          <div style={{ marginBottom: '2.4rem', marginTop: '0.8rem' }}>
            <CustomInputField
              name='confirmPassword'
              type={passwordShow ? 'text' : 'password'}
              value={confirmPassword}
              onChange={onChange}
              left={true}
              placeholder='Confirm your Password'
              rightIcon={
                passwordShow ? (
                  <span style={{ cursor: 'pointer' }}>
                    <IconsRepository.EyeIcon
                      size={20}
                      color='#0F172A'
                      onClick={handleShowPassword}
                    />
                  </span>
                ) : (
                  <span style={{ cursor: 'pointer' }}>
                    <IconsRepository.EyeCrossedIcon
                      size={20}
                      color='#0F172A'
                      onClick={handleShowPassword}
                    />
                  </span>
                )
              }
              icon={<IconsRepository.LockIcon />}
            />
            <p className={styles.validate__error}>
              {formErrors.password ? 'Passwords Do Not Match' : null}
            </p>
          </div>
        </div>
      </div>
      <Button
        type={ButtonTypes.PRIMARY}
        text={
          forgotPasswordState.status === APIStatus.PENDING ? 'Please wait...' : 'Reset Password'
        }
        width='100%'
        fontSize='16px'
        height='48px'
        disabled={forgotPasswordState.status === APIStatus.PENDING}
        onClick={() => {
          handleSubmit()
        }}
      />
    </div>
  )
}

export default Block5
