import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import styles from './homecourse.module.css'
import { IdName } from '../../../../types'
import { useAppSelector } from '../../../../lib/hooks'
import { courseCover } from '../../../../repository/assets'

type HomeCourseCardTypes = {
  img: string
  title: string
  students: string[]
  id: string
  stdCounts: string
  desc?: string
  categories?: IdName[]
}

const HomeCourseCard: React.FC<HomeCourseCardTypes> = ({
  img,
  desc,
  title,
  students,
  id,
  stdCounts,
  categories,
}) => {
  const { user } = useAppSelector((state) => state.authentication)
  const [cover, setCover] = useState(img || courseCover)
  const navigate = useNavigate()

  return (
    <div className={styles.parent__div}>
      <div
        className={styles.img__container}
        onClick={() => {
          if (user) {
            navigate(`/course-preview/${id}`)
          } else {
            navigate(`/course-details/${id}`)
          }
        }}
      >
        <img src={cover} alt={title} onError={() => setCover(courseCover)} />

        {/* <div className={styles.tag}>
          <h4>New</h4>
        </div> */}

        <div className={styles.overlay}>
          <h2>{categories && categories[0]?.name}</h2>
          <p>
            {desc !== undefined && desc.length > 0
              ? desc
              : 'The UI/UX Design Specialization brings a design-centric approach to user interface and user experience design, and offers practical, skill-based instruction centered around a visual communications perspective, rather than on one focused on marketing or programming alone.'}
          </p>
        </div>
      </div>
      <div className={styles.data__content}>
        <h2>{title.length > 35 ? title.substring(0, 35) + '...' : title}</h2>
        <div className={styles.students__btn}>
          <div className={styles.students}>
            <div className={styles.images}>
              {students.map((img, index) => (
                <img key={index} alt='pic' src={img}></img>
              ))}
            </div>
            <h3>{stdCounts}+ Students</h3>
          </div>
          <div className={styles.btn}>
            <Link to={user ? `/course-preview/${id}` : `/course-details/${id}`}>View Details</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeCourseCard
