import { fullstack1, fullstack2 } from '../assets'

const bootcamps = [
  {
    image: fullstack1,
    title: 'Full Stack Web Development',
    descr1:
      'This practical hands-on front-end web design and web development training in Cameroon gives you full training on front end website design and development',
    descr2:
      'Learn to use HTML5, CSS3, JavaScript, React , Mongo DB, NodeJS and Express together in building modern beautiful, fully functional amazing websites.',
    duration: '6 weeks',
    students: '25 Students: 20 girls, 5 boys',
    price: 300_000,
    link: '/fullstack-bootcamp',
  },
  {
    image: fullstack2,
    title: 'Complete UI/UX  design Bootcamp',
    descr1:
      'This practical hands-on front-end web design and web development training in Cameroon gives you full training on front end website design and development',
    descr2:
      'Learn to use HTML5, CSS3, JavaScript, React , Mongo DB, NodeJS and Express together in building modern beautiful, fully functional amazing websites.',
    duration: '6 weeks',
    students: '25 Students: 20 girls, 5 boys',
    price: 100_000,
    link: '/ui-bootcamp',
  },
]

export default bootcamps
