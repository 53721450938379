import React from 'react'
import { IconProps } from '../types'

const NumbersIcon = ({ size = 18, color = 'currentColor', stroke = 2, ...props }: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      stroke={`${stroke}`}
      viewBox='0 0 18 17'
      fill='none'
      {...props}
    >
      <path
        d='M15.0208 7.08337L15.375 5.66671H12.5417L13.25 2.83337H11.8333L11.125 5.66671H8.29167L9 2.83337H7.58333L6.875 5.66671H4.04167L3.6875 7.08337H6.52083L5.8125 9.91671H2.97917L2.625 11.3334H5.45833L4.75 14.1667H6.16667L6.875 11.3334H9.70833L9 14.1667H10.4167L11.125 11.3334H13.9583L14.3125 9.91671H11.4792L12.1875 7.08337H15.0208ZM10.0625 9.91671H7.22917L7.9375 7.08337H10.7708L10.0625 9.91671Z'
        fill={color}
      />
    </svg>
  )
}

export default NumbersIcon
