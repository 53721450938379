import React from 'react'
import { IconProps } from '../types'

const BlueLeftIcon = ({ size = 22, color = 'currentColor' }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.0547 8.98287H5.16133L11.668 2.4762C12.188 1.9562 12.188 1.10287 11.668 0.582871C11.148 0.0628711 10.308 0.0628711 9.78799 0.582871L1.00133 9.36954C0.481328 9.88954 0.481328 10.7295 1.00133 11.2495L9.78799 20.0362C10.308 20.5562 11.148 20.5562 11.668 20.0362C12.188 19.5162 12.188 18.6762 11.668 18.1562L5.16133 11.6495H20.0547C20.788 11.6495 21.388 11.0495 21.388 10.3162C21.388 9.58287 20.788 8.98287 20.0547 8.98287Z'
        fill={color}
      />
    </svg>
  )
}

export default BlueLeftIcon
