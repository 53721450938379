import React from 'react'
import { IconProps } from '../types'

const DropDownIcon = ({
  size = 16,
  height = 10,
  color = 'currentColor',
  stroke = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      width={size}
      height={height}
      stroke={`${stroke}`}
      viewBox='0 0 16 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.93934 0.93934C1.52513 0.353553 2.47487 0.353553 3.06066 0.93934L8 5.87868L12.9393 0.93934C13.5251 0.353553 14.4749 0.353553 15.0607 0.93934C15.6464 1.52513 15.6464 2.47487 15.0607 3.06066L9.06066 9.06066C8.47487 9.64645 7.52513 9.64645 6.93934 9.06066L0.93934 3.06066C0.353553 2.47487 0.353553 1.52513 0.93934 0.93934Z'
        fill={color}
      />
    </svg>
  )
}

export default DropDownIcon
