import React, { useState } from 'react'

import styles from './radio.module.css'

interface IRadioProps {
  name?: string
  label?: string
  action?: (state: boolean) => void
  checked: boolean
}

/**
 * The component still has a lot to be done
 * for now, it was just created for a generalisation
 * but later on features and updates will be added as required
 *
 */

const Radio: React.FC<IRadioProps> = ({ label, name, action, checked }) => {
  const [isChecked, setIsChecked] = useState(checked)

  return (
    <label
      className={styles.label}
      onClick={(e) => {
        e.stopPropagation()
        setIsChecked(!isChecked)
        if (action) {
          action(!isChecked)
        }
      }}
    >
      <span className={styles.circle}>
        <span className={`${styles.dot} ${isChecked ? styles.show : styles.hide}`}></span>
      </span>
      <input type={'radio'} onClick={(e) => e.stopPropagation()} name={name} />
      <span>{label}</span>
    </label>
  )
}

export default Radio
