import React from 'react'

// general imports
import Swiper from '../../organisms/swiper/Swiper.organism'

interface ITabProps {
  labels: Array<string>
  changeTab: (i: number) => void
  containerClassName?: string
  tabClassName?: string
  activeClassName?: string
  activeTab: number
  slideWidth?: string
}

const Tabs: React.FC<ITabProps> = ({
  labels,
  changeTab,
  containerClassName,
  tabClassName,
  activeClassName,
  activeTab,
  slideWidth,
}) => {
  return (
    <div className={containerClassName}>
      <Swiper
        singleSlideWidth={slideWidth ? slideWidth : 'fit-content'}
        gap='0'
        p
        padding='0 1.5rem'
      >
        {labels.map((label, index) => {
          return (
            <div
              role={'tab'}
              tabIndex={0}
              key={label}
              onClick={() => changeTab(index)}
              className={`${tabClassName} ${activeTab === index ? activeClassName : ''}`}
            >
              {label}
            </div>
          )
        })}
      </Swiper>
    </div>
  )
}

export default Tabs
