import React from 'react'
import { IconProps } from '../types'

const StarIcon = ({ size = 35, color = 'currentColor', stroke = 2, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      stroke={`${stroke}`}
      viewBox='0 0 38 35'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M19 0L14.7342 13.1287H0.929924L12.0978 21.2426L7.83208 34.3713L19 26.2574L30.1679 34.3713L25.9022 21.2426L37.0701 13.1287H23.2658L19 0Z'
        fill={color}
      />
    </svg>
  )
}

export default StarIcon
