import React from 'react'
import { IconProps } from '../types'

const DevicesOthersIcon = ({
  size = 24,
  color = 'currentColor',
  stroke = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      stroke={`${stroke}`}
      viewBox='0 0 16 16'
      fill='none'
      {...props}
    >
      <path
        d='M2.00033 4.00001H14.0003V2.66667H2.00033C1.26699 2.66667 0.666992 3.26667 0.666992 4.00001V12C0.666992 12.7333 1.26699 13.3333 2.00033 13.3333H4.66699V12H2.00033V4.00001ZM8.66699 8H6.00033V9.18667C5.59366 9.55334 5.33366 10.0733 5.33366 10.6667C5.33366 11.26 5.59366 11.78 6.00033 12.1467V13.3333H8.66699V12.1467C9.07366 11.78 9.33366 11.2533 9.33366 10.6667C9.33366 10.08 9.07366 9.55334 8.66699 9.18667V8ZM7.33366 11.6667C6.78032 11.6667 6.33366 11.22 6.33366 10.6667C6.33366 10.1133 6.78032 9.66667 7.33366 9.66667C7.88699 9.66667 8.33366 10.1133 8.33366 10.6667C8.33366 11.22 7.88699 11.6667 7.33366 11.6667ZM14.667 5.33334H10.667C10.3337 5.33334 10.0003 5.66667 10.0003 6V12.6667C10.0003 13 10.3337 13.3333 10.667 13.3333H14.667C15.0003 13.3333 15.3337 13 15.3337 12.6667V6C15.3337 5.66667 15.0003 5.33334 14.667 5.33334ZM14.0003 12H11.3337V6.66667H14.0003V12Z'
        fill={color}
      />
    </svg>
  )
}

export default DevicesOthersIcon
