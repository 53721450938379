import React, { useEffect } from 'react'
import { ButtonTypes } from '../../atoms/enums'
import Button from '../../molecules/button/button.molecule'

import styles from './blocks.module.css'
import Loader from '../../atoms/loader/Loader'
import { resendOTPThunkService } from '../../../features/authentication/authenticationThunks'
import { useAppDispatch, useAppSelector } from '../../../lib/hooks'
import { RootState } from '../../../app/store'
import { useSearchParams } from 'react-router-dom'
import { APIStatus } from '../../../constants/configs'
import UseError from '../../../lib/hooks/useError'
import Popup from '../../organisms/popup/Popup.organism'
import { resetVerificationState } from '../../../features/authentication/verificationSlice'

export type BlockClickProps = {
  setBlock: React.Dispatch<React.SetStateAction<number>>
  setEmail?: React.Dispatch<React.SetStateAction<string>>
  email?: string
}

const Block1: React.FC<BlockClickProps> = ({ setBlock }) => {
  const dispatch = useAppDispatch()
  const [search] = useSearchParams()

  const { setErrorActive, errorActive } = UseError()

  const verification = useAppSelector((state: RootState) => state.verification)

  const resendOtphandler = async () => {
    if (search.get('email')) {
      dispatch(resendOTPThunkService({ email: search.get('email') as string }))
    } else {
      alert('No email found. Please manually enter your email.')
    }
  }

  useEffect(() => {
    if (verification.errorTrace === true) {
      setErrorActive(true)
    }

    setTimeout(() => {
      dispatch(resetVerificationState())
    }, 5000)
  }, [verification.errorTrace, verification.status])

  return (
    <div className={styles.container}>
      {errorActive ? (
        <Popup
          toggleActive={setErrorActive}
          active={errorActive}
          title='error'
          msg={verification.message}
        />
      ) : (
        ''
      )}
      {verification.status === APIStatus.FULFILLED ||
      verification.codeSent === APIStatus.FULFILLED ? (
        <Popup
          toggleActive={setErrorActive}
          active={verification.codeSent === APIStatus.FULFILLED}
          title='success'
          msg='Code sent successfully!'
        />
      ) : (
        ''
      )}
      {verification.codeSent === APIStatus.PENDING ? <Loader /> : ''}
      <h3>Check your email</h3>
      <p className={styles.email}>{`A verification link has been sent to ${search.get(
        'email',
      )}`}</p>
      <Button
        type={ButtonTypes.PRIMARY}
        fontSize='16px'
        text={'Verify Email'}
        width='100%'
        height='48px'
        onClick={() => {
          setBlock(1)
        }}
      />
      <p
        style={{
          fontSize: ' 14px',
        }}
        className={styles.resend}
      >
        Didn&apos;t receive the email?{' '}
        <button
          onClick={(e) => {
            e.preventDefault()
            resendOtphandler()
          }}
          style={{
            background: 'transparent',
            border: 'none',
            outline: 'none',
            fontWeight: 600,
            fontSize: ' 14px',
            color: 'var(--platform-secondary-700)',
            marginLeft: '0.2rem',
          }}
        >
          Click Resend
        </button>
      </p>
    </div>
  )
}

export default Block1
