import React from 'react'

// stylesheet
import styles from './intro.module.css'

type IntroTypes = {
  heading: string
  description: string
  vid: string
  cover?: string
}
const Intro: React.FC<IntroTypes> = ({ heading, description, cover }) => {
  // const videoRef = useRef<HTMLVideoElement | null>(null)
  // const [isPlaying, setPlaying] = useState(false)

  // const playPause = () => {
  //   if (isPlaying) {
  //     videoRef.current?.pause()
  //     setPlaying(false)
  //   } else {
  //     videoRef.current?.play()
  //     setPlaying(true)
  //   }
  // }

  return (
    <div className={styles.intro}>
      <div>
        <div className={styles.heading}>
          <h2>{heading}</h2>
        </div>
        <p className={`${styles.description} ${styles.p__tag}`}>{description}</p>
        {cover !== undefined ? (
          <div className={styles.video}>
            {cover !== undefined ? <img src={cover} alt={heading} /> : <div>.</div>}
            {cover !== undefined
              ? ''
              : // <span className={isPlaying ? styles.hide : ''} onClick={playPause}>
                //   {isPlaying ? (
                //     <IconsRepository.PlayCircleFilledIcon size={65} />
                //   ) : (
                //     <IconsRepository.PlayCircleFilledIcon size={65} />
                //   )}
                // </span>
                ''}
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default Intro
