import React from 'react'

// general imports
import AppRouter from './router/constructor/Router.constructor'

const App = () => {
  return (
    <div className='app'>
      <AppRouter />
    </div>
  )
}

export default App
