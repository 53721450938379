import React from 'react'
import { IconProps } from '../types'

const PauseCircleFilled: React.FC<IconProps> = ({
  size = 24,
  color = 'currentColor',
  stroke,
  ...props
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      stroke={stroke?.toString() || ''}
      viewBox='0 0 24 24'
      fill='none'
      {...props}
    >
      <path
        d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM11 16H9V8H11V16ZM15 16H13V8H15V16Z'
        fill={color}
      />
    </svg>
  )
}

export default PauseCircleFilled
