import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LocalStorage } from '../../../services/storage/Local.storage'
import Student from '../../../types/models'

const user = LocalStorage.getCurrentUser()
const status = LocalStorage.getVerificationStatus()

export interface AuthState {
  user: Student | null
  isError: boolean
  isSuccess: boolean
  message: string
  isLoading: boolean
  verified: boolean
}

const initialState: AuthState = {
  user: user !== null ? user : null,
  isError: false,
  isSuccess: user !== null ? true : false,
  isLoading: false,
  message: '',
  verified: status === 'true' ? true : false,
}

const authSlice = createSlice({
  name: 'authSlice',
  initialState: initialState,
  reducers: {
    resetAuthState: (state) => {
      state.user = null
      state.isError = false
      state.isSuccess = false
      state.isLoading = false
    },
    setUser: (state, action: PayloadAction<Student>) => {
      state.user = action.payload
      state.isSuccess = true

      LocalStorage.storeCurrentUser(action.payload)
    },
    setUserAccountStatus: (state, action: PayloadAction<boolean>) => {
      state.verified = action.payload

      LocalStorage.storeVerificationStatus(`${action.payload}`)
    },
  },
  extraReducers: (builder) => {
    builder
  },
})

export const { resetAuthState, setUserAccountStatus, setUser } = authSlice.actions
export default authSlice.reducer
