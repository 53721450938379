import React, { useEffect, useRef, useState, ReactNode } from 'react'

// stylesheet
import styles from './accordion.module.css'

interface Props {
  title: ReactNode
  icon: JSX.Element
  iconClose: JSX.Element
  content: JSX.Element
  isOpen: boolean
  btnOnClick: () => void
  acctype?: string
  count?: string
  extraClass?: string
}

const Accordion = ({
  title,
  icon,
  iconClose,
  content,
  isOpen,
  btnOnClick,
  extraClass,
}: Props): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    if (isOpen) {
      const contentEl = containerRef.current as HTMLDivElement
      setHeight(contentEl.scrollHeight)
    } else {
      setHeight(0)
    }
  }, [isOpen, containerRef, content])

  return (
    <li
      className={`${styles.accordion__item} ${isOpen ? styles.active : ''}
       ${extraClass} }`}
    >
      <div onClick={btnOnClick} className={styles.accordion__item__title}>
        {title}
        {isOpen ? iconClose : icon}
      </div>
      <div style={{ height: `${height}px` }} className={`${styles.accordion__item__container}`}>
        <div
          ref={containerRef}
          className={`${styles.accordion__item__content} ${isOpen ? styles.animate : null}`}
        >
          {content}
        </div>
      </div>
    </li>
  )
}

export default Accordion
