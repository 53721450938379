import React, { Suspense, Fragment } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

// application routes
import { routes } from '../routes.router'

// guards controller
import GuardController from '../controller/Guard.controller'

// error page
import NotFound from '../../pages/error/404'

const AppRouter: React.FC = () => {
  return (
    <div className='app'>
      <BrowserRouter>
        <Suspense
          fallback={
            <>
              <h2>Loading...</h2>
            </>
          }
        >
          <Routes>
            {routes.map((route) =>
              route.nestedComponents ? (
                <Route
                  key={route.path}
                  path={route.path}
                  element={<GuardController route={route} />}
                >
                  {route.nestedComponents.map((singleNest) => (
                    <Fragment key={singleNest.path}>
                      {singleNest.path === '/' ? (
                        <Route index element={<singleNest.component />} />
                      ) : singleNest.nestedComponents ? (
                        <Route path={singleNest.path} element={<singleNest.component />}>
                          {singleNest.nestedComponents.map((target) => (
                            <Fragment key={target.path}>
                              {target.path === '/' ? (
                                <Route index element={<target.component />} />
                              ) : (
                                <Route path={target.path} element={<target.component />}></Route>
                              )}
                            </Fragment>
                          ))}
                        </Route>
                      ) : (
                        <Route path={singleNest.path} element={<singleNest.component />}></Route>
                      )}
                    </Fragment>
                  ))}
                </Route>
              ) : (
                <Route
                  key={route.path}
                  path={route.path}
                  element={<GuardController route={route} />}
                />
              ),
            )}

            {/** 404 - PAGE NOT FOUND ROUTE */}
            <Route path='*' element={<NotFound />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  )
}

export default AppRouter
