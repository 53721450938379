import { GuardPropType } from '../../types/'
import { appPaths } from '../../constants/app-paths'

export const guestGuard = (props: GuardPropType) => {
  console.log('Guard Props: ', props)
  return '/'
}

const verifyAuthRouteAccess = (props: GuardPropType) => {
  const { state, location } = props
  if (state?.authentication.user !== null && state.authentication.token !== null) {
    if (localStorage.getItem('course')) {
      const course = localStorage.getItem('course')
      localStorage.removeItem('course')
      return `/course-details/${course}`
    }
    return appPaths.DASHBOARD
  }

  if (
    state?.authentication.user &&
    (state.authentication.user.verified === undefined ||
      state.authentication.user.verified === false)
  ) {
    return `${appPaths.VERIFY_EMAIL}?email=${state.authentication.user.email}`
  }

  return location.pathname
}

const verificationRouteAccess = (props: GuardPropType) => {
  const { state } = props

  if (
    state?.authentication.user &&
    (state.authentication.user.verified === undefined ||
      state.authentication.user.verified === false)
  ) {
    return `${appPaths.VERIFY_EMAIL}?email=${state.authentication.user.email}`
  }

  if (
    state?.authentication.user &&
    state.authentication.token &&
    state.authentication.user.verified === true
  ) {
    if (localStorage.getItem('course')) {
      const course = localStorage.getItem('course')
      localStorage.removeItem('course')
      return `/course-details/${course}`
    }
    return appPaths.DASHBOARD
  }

  return appPaths.REGISTER
}

const verifyUserAuthenticity = (props: GuardPropType) => {
  const { state, location } = props

  if (
    state?.authentication.user &&
    state.authentication.token &&
    (state.authentication.user.verified === undefined ||
      state.authentication.user.verified === false)
  ) {
    return `${appPaths.VERIFY_EMAIL}?email=${state.authentication.user.email}`
  }

  if (state?.authentication.user && state?.authentication.token) {
    return location.pathname
  }

  return appPaths.LOGIN
}

// eslint-disable-next-line
export default {
  verifyAuthRouteAccess,
  verificationRouteAccess,
  guestGuard,
  verifyUserAuthenticity,
}
