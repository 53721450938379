import React from 'react'

import styles from './details.module.css'
import { courseCover } from '../../../repository/assets'

type courseDetailTypes = {
  img: string
  title: string
  coursename: string
}

const Details: React.FC<courseDetailTypes> = ({ img, title, coursename }) => {
  const [cover, setCover] = React.useState(img || courseCover)

  return (
    <div className={styles.container}>
      <div className={styles.img}>
        <img src={cover} alt={coursename} onError={() => setCover(courseCover)} />
      </div>
      <div className={styles.course}>
        <p className={`${styles.title} details_title`}>{title}</p>
        {coursename && <p className={styles.course__name}>{coursename}</p>}
      </div>
    </div>
  )
}

export default Details
