import React from 'react'

import { IconProps } from '../types'

const SignalCellularAltIcon = ({
  size = 24,
  color = 'currentColor',
  stroke = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      stroke={`${stroke}`}
      viewBox='0 0 16 16'
      fill='none'
      {...props}
    >
      <path
        d='M11 2.66666H13V13.3333H11V2.66666ZM3 9.33333H5V13.3333H3V9.33333ZM7 6H9V13.3333H7V6Z'
        fill={color}
      />
    </svg>
  )
}

export default SignalCellularAltIcon
