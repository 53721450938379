import React from 'react'
import { IconProps } from '../types'

const FailedIcon = ({ size = 25, stroke = 2, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      stroke={`${stroke}`}
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M18.8002 6.21C18.4102 5.82 17.7802 5.82 17.3902 6.21L12.5002 11.09L7.61022 6.2C7.22022 5.81 6.59021 5.81 6.20021 6.2C5.81021 6.59 5.81021 7.22 6.20021 7.61L11.0902 12.5L6.20021 17.39C5.81021 17.78 5.81021 18.41 6.20021 18.8C6.59021 19.19 7.22022 19.19 7.61022 18.8L12.5002 13.91L17.3902 18.8C17.7802 19.19 18.4102 19.19 18.8002 18.8C19.1902 18.41 19.1902 17.78 18.8002 17.39L13.9102 12.5L18.8002 7.61C19.1802 7.23 19.1802 6.59 18.8002 6.21Z'
        fill='white'
      />
    </svg>
  )
}

export default FailedIcon
