import React from 'react'
import { Link } from 'react-router-dom'
import { appPaths } from '../../../constants/app-paths'
import { ButtonTypes } from '../../atoms/enums'
import Button from '../../molecules/button/button.molecule'

import styles from './blocks.module.css'

const Block3 = () => {
  return (
    <div className={styles.container}>
      <h3>Email verified</h3>
      <p className={styles.email}>
        You have successfully verified your email you will be redirected to the dashboard
      </p>
      <Link to={appPaths.DASHBOARD}>
        <Button
          type={ButtonTypes.PRIMARY}
          fontSize='16px'
          text={'Continue'}
          width='100%'
          height='48px'
        />
      </Link>
    </div>
  )
}

export default Block3
