/* eslint-disable */

import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { APIStatus } from '../../constants/configs'
import { resendOTPThunkService, verifyOPTThunkService } from './authenticationThunks'
import { UserType } from '../../types/models'

export const initialState: {
  status: APIStatus
  errorTrace: boolean
  message: string
  codeSent: APIStatus
} = {
  status: APIStatus.IDLE,
  errorTrace: false,
  message: '',
  codeSent: APIStatus.IDLE,
}

const verificationSlice = createSlice({
  name: 'verificationSlice',
  initialState: initialState,
  reducers: {
    resetVerificationState: (state) => {
      state.errorTrace = false
      state.status = APIStatus.IDLE
      state.message = ''
      state.codeSent = APIStatus.IDLE
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyOPTThunkService.pending, (state) => {
        state.status = APIStatus.PENDING
      })
      .addCase(verifyOPTThunkService.fulfilled, (state) => {
        state.status = APIStatus.FULFILLED
      })
      .addCase(verifyOPTThunkService.rejected, (state, action) => {
        state.status = APIStatus.REJECTED

        state.status = APIStatus.REJECTED
        state.errorTrace = true
        state.message = (action.payload as { error: string }).error
      })
      .addCase(resendOTPThunkService.pending, (state) => {
        state.codeSent = APIStatus.PENDING
      })
      .addCase(resendOTPThunkService.fulfilled, (state) => {
        state.codeSent = APIStatus.FULFILLED
      })
      .addCase(resendOTPThunkService.rejected, (state, action) => {
        state.status = APIStatus.REJECTED
        state.errorTrace = true
        state.message = (action.payload as { error: string }).error
      })
  },
})

export const { resetVerificationState } = verificationSlice.actions
export default verificationSlice.reducer
