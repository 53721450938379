import React from 'react'

// stylesheet
import styles from './gridlayout.module.css'

type DesignGridLayoutProps = {
  children: React.ReactNode
}

const DesignGridLayout: React.FC<DesignGridLayoutProps> = ({ children }) => {
  return <div className={styles.grid__system}>{children}</div>
}

export default DesignGridLayout
