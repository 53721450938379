import React, { useState, useEffect, useMemo } from 'react'

import styles from '../../../pages/auth/login/login.module.css'
import authData from '../../../repository/data/auth-data'

type Props = {
  image?: string | JSX.Element
}

const ImageContainer: React.FC<Props> = ({ image }) => {
  const [activedDot, setActiveDot] = useState<number>(0)

  const activityState = useMemo(() => {
    return activedDot < authData.length - 1 ? activedDot + 1 : 0
  }, [activedDot])

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      setActiveDot(activityState)
    }, 4000)

    return () => {
      clearTimeout(timeoutID)
    }
  }, [activedDot])

  return (
    <>
      <div className={styles.img_styles}>
        {typeof image === 'string' ? (
          <img
            src={image as string}
            alt='image'
            width={466}
            height={456}
            style={{
              width: '100%',
            }}
          />
        ) : (
          image
        )}
      </div>
      <div className={styles.box}>
        <h3 className={styles.heading}>{authData[activedDot].title}</h3>
        <p className={styles.paragraph}>{authData[activedDot].content}</p>
      </div>
      <div className={styles.dots}>
        {Array(authData.length)
          .fill(0)
          .map((_i, index) => {
            return (
              <div
                onClick={() => {
                  setActiveDot(index)
                }}
                key={index}
                className={`${styles.dot} ${activedDot === index ? styles.active : ''}`}
              ></div>
            )
          })}
      </div>
    </>
  )
}

export default ImageContainer
