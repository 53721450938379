import React, { useState } from 'react'

import styles from './card2.module.css'
import { defaultImage } from '../../../../repository/assets'
import useWindowSize from '../../../../lib/hooks/useWindowSize'

type Props = {
  image: string
  title: string
  author: string
  authorImage: string
}

const Card2: React.FC<Props> = ({ image, author, authorImage, title }) => {
  const [cover, setCover] = useState(image || defaultImage)
  const width = useWindowSize().width

  return (
    <div className={styles.body}>
      <img
        src={cover}
        alt={title}
        className={styles.image}
        onError={() => setCover(defaultImage)}
      />
      <div className={styles.info}>
        <h4 className={`${styles.title} ${width <= 600 ? styles.limit__lines : ''}`}>{title}</h4>
        <div className={styles.authorInfo}>
          <img src={authorImage} alt={author} className={styles.imageThumbnail} /> by{' '}
          <span>{width <= 700 ? author.split(' ')[0] : author}</span>
        </div>
      </div>
    </div>
  )
}

export default Card2
