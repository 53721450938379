import React, { useEffect, useState } from 'react'

// stylesheet
import styles from '../modules.module.css'

// general imports
import Toggle from '../../../../../components/molecules/toggle/Toggle.molecule'

const enum MediumType {
  Email = 0,
  SMS = 1,
  Push = 2,
}

const Notifications = () => {
  const [enrollmentMedium, setEnrollmentMedium] = useState<Array<number>>([])
  const [learningMedium, setLearningMedium] = useState<Array<number>>([])
  const [assignmentMedium, setAssignmentMedium] = useState<Array<number>>([])
  const [LoginMedium, setLoginMedium] = useState<boolean | number>(0)

  useEffect(() => {
    console.log('====================================')
    console.log('MEDIUMS: ', MediumType.Email)
    console.log('MEDIUMS: ', MediumType.Push)
    console.log('MEDIUMS: ', MediumType.SMS)
    console.log('====================================')

    console.log(enrollmentMedium)
    console.log(learningMedium)
    console.log(assignmentMedium)
    console.log(LoginMedium)
  }, [enrollmentMedium, assignmentMedium, learningMedium, LoginMedium])

  const updateEnrollment = (i: number | boolean) => {
    const index = Number(i)
    if (enrollmentMedium.includes(index)) {
      setEnrollmentMedium(enrollmentMedium.filter((m) => m !== index))
    } else {
      setEnrollmentMedium([...enrollmentMedium, index])
    }
  }

  const updateLearning = (i: number | boolean) => {
    const index = Number(i)
    if (learningMedium.includes(index)) {
      setLearningMedium(learningMedium.filter((m) => m !== index))
    } else {
      setLearningMedium([...learningMedium, index])
    }
  }

  const updateAssignment = (i: number | boolean) => {
    const index = Number(i)
    if (assignmentMedium.includes(index)) {
      setAssignmentMedium(assignmentMedium.filter((m) => m !== index))
    } else {
      setAssignmentMedium([...assignmentMedium, index])
    }
  }

  return (
    <div className={styles.module}>
      <div className={styles.head}>
        <h2>Notifications</h2>
        <h3>Choose how you receive notifications</h3>
      </div>

      <div className={styles.notifications}>
        <div className={styles.lead}>
          <h2>Activity</h2>
        </div>
        <div className={styles.actions}>
          <div>
            <h2>Email</h2>
          </div>
          <div>
            <h2>SMS</h2>
          </div>
          <div>
            <h2>Push</h2>
          </div>
        </div>
      </div>

      <div className={styles.notifications}>
        <div className={styles.lead}>
          <h2>Enrollment</h2>
          <h3>Notify when you enroll into a new course</h3>
        </div>
        <div className={styles.actions}>
          <div>
            <Toggle
              toggled={enrollmentMedium.includes(0)}
              index={0}
              setToggleState={(index) => updateEnrollment(index)}
            />
          </div>
          <div>
            <Toggle
              toggled={enrollmentMedium.includes(1)}
              index={1}
              setToggleState={updateEnrollment}
            />
          </div>
          <div>
            <Toggle
              toggled={enrollmentMedium.includes(2)}
              index={2}
              setToggleState={updateEnrollment}
            />
          </div>
        </div>
      </div>

      <div className={styles.notifications}>
        <div className={styles.lead}>
          <h2>Learning Times</h2>
          <h3>Notify me when it&apos;s already time to learn</h3>
        </div>
        <div className={styles.actions}>
          <div>
            <Toggle
              toggled={learningMedium.includes(0)}
              index={0}
              setToggleState={updateLearning}
            />
          </div>
          <div>
            <Toggle
              toggled={learningMedium.includes(1)}
              index={1}
              setToggleState={updateLearning}
            />
          </div>
          <div>
            <Toggle
              toggled={learningMedium.includes(2)}
              index={2}
              setToggleState={updateLearning}
            />
          </div>
        </div>
      </div>

      <div className={styles.notifications}>
        <div className={styles.lead}>
          <h2>Assignments</h2>
          <h3>Notify when I have assignments that are due</h3>
        </div>
        <div className={styles.actions}>
          <div>
            <Toggle
              toggled={assignmentMedium.includes(0)}
              index={0}
              setToggleState={updateAssignment}
            />
          </div>
          <div>
            <Toggle
              toggled={assignmentMedium.includes(1)}
              index={1}
              setToggleState={updateAssignment}
            />
          </div>
          <div>
            <Toggle
              toggled={assignmentMedium.includes(2)}
              index={2}
              setToggleState={updateAssignment}
            />
          </div>
        </div>
      </div>

      <div className={styles.notifications}>
        <div className={styles.lead}>
          <h2>Login from new device</h2>
        </div>
        <div className={styles.actions}>
          <div>
            <Toggle toggled={LoginMedium === 0} index={0} setToggleState={setLoginMedium} />
          </div>
          <div>
            <Toggle toggled={LoginMedium === 1} index={1} setToggleState={setLoginMedium} />
          </div>
          <div>
            <Toggle toggled={LoginMedium === 2} index={2} setToggleState={setLoginMedium} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Notifications
