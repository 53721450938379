import React from 'react'
import { IconProps } from '../types'

// eslint-disable-next-line
const OpenLockIcon = ({ size = 24, stroke = 2, onClick, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      stroke={`${stroke}`}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
      onClick={onClick}
    >
      <path
        d='M8 11V7C8 5.66667 8.8 3 12 3C13.9103 3 14.9653 3.95032 15.5054 5M8 11H5V21H19V11H8Z'
        stroke='#000000'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default OpenLockIcon
