import React, { ReactNode } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { IconsRepository } from '../../../../repository/icons/icon.repository'
import styles from './profileCard.module.css'
import { useAppDispatch, useAppSelector } from '../../../../lib/hooks'
import { RootState } from '../../../../app/store'
import { resetAuthenticationState } from '../../../../features/authentication/authenticationSlice'
import { LocalStorage } from '../../../../services/storage/Local.storage'
import { appPaths } from '../../../../constants/app-paths'

type Props = {
  userImage: string
  userName?: string
}

const data = [
  // {
  //   link: `/dashboard/profile/${appPaths.D_NOTIFICATIONS}`,
  //   icon: <IconsRepository.BellIcon width={12} height={14} color='#380E4A' />,
  //   value: 'Notifications',
  // },
  {
    link: `/dashboard/profile/${appPaths.D_ACCOUNT}`,
    icon: <IconsRepository.Task width={12} height={14} color='var(--platform-secondary-600)' />,
    value: 'Account',
  },
  {
    link: `/dashboard/${appPaths.D_PROFILE}`,
    icon: <IconsRepository.Person width={12} height={12} color='var(--platform-secondary-600)' />,
    value: 'Profile',
  },
  {
    link: `/dashboard/${appPaths.D_PROFILE}`,
    icon: (
      <IconsRepository.SettingsIcon width={14} height={14} color='var(--platform-secondary-600)' />
    ),
    value: 'Settings',
  },
  {
    link: `/dashboard/${appPaths.MY_COURSES}`,
    icon: (
      <IconsRepository.MenuBookIcon width={16} height={12} color='var(--platform-secondary-600)' />
    ),
    value: 'My courses',
  },
]

export const ProfileCard: React.FC<Props> = ({ userImage }) => {
  const authState = useAppSelector((state: RootState) => state.authentication)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  return (
    <div className={styles.card}>
      <div className={styles.imageDiv}>
        <img className={styles.image} src={userImage} alt='User Image' />
        <p className={styles.welcome}>Welcome, {authState.user?.first_name}</p>
      </div>
      <hr className={styles.line} />
      <div className={styles.linkDiv}>
        {data.map((item, idx) => {
          if (item.value === 'Profile') {
            if (window.location.pathname.includes('dashboard')) {
              return <></>
            }
          }
          return <Detail link={item.link} key={idx} icon={item.icon} value={item.value} />
        })}
      </div>
      <hr className={styles.line} />
      <div className={styles.buttonDiv}>
        <button
          onClick={() => {
            if (window.confirm('Are you sure you want to log out?')) {
              dispatch(resetAuthenticationState())
              LocalStorage.removeCurrentUser()
              localStorage.removeItem('email_tracker')

              navigate(appPaths.HOME_PAGE)
              window.location.reload()
            }
          }}
          className={styles.logoutButton}
        >
          <IconsRepository.LogoutIcon /> Logout
        </button>
      </div>
    </div>
  )
}

const Detail: React.FC<{ icon: ReactNode; value: string; link?: string }> = ({
  icon,
  value,
  link,
}) => {
  return (
    <Link to={link as string}>
      <div className={styles.detail}>
        <span className={styles.icon}>{icon}</span>
        <span className={styles.text}>{value}</span>
      </div>
    </Link>
  )
}
