import React from 'react'
import { IconProps } from '../types'

const MenuIcon = ({ size = 24, color = 'currentColor', stroke = 2, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      stroke={`${stroke}`}
      viewBox='0 0 49 65'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M18.875 44.5H42.875V40.5H18.875V44.5ZM6.875 20.5V24.5H42.875V20.5H6.875ZM18.875 34.5H42.875V30.5H18.875V34.5Z'
        fill={color}
      />
    </svg>
  )
}

export default MenuIcon
