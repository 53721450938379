import React from 'react'
import { IconProps } from '../types'

const TimeLapseIcon = ({
  size = 24,
  height = 25,
  color = 'currentColor',
  stroke = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={height}
      stroke={`${stroke}`}
      viewBox='0 0 24 25'
      fill='none'
      {...props}
    >
      <path
        d='M16.24 8.65404C15.07 7.48404 13.54 6.89404 12 6.89404V12.894L7.76 17.134C10.1 19.474 13.9 19.474 16.25 17.134C18.59 14.794 18.59 10.994 16.24 8.65404ZM12 2.89404C6.48 2.89404 2 7.37404 2 12.894C2 18.414 6.48 22.894 12 22.894C17.52 22.894 22 18.414 22 12.894C22 7.37404 17.52 2.89404 12 2.89404ZM12 20.894C7.58 20.894 4 17.314 4 12.894C4 8.47404 7.58 4.89404 12 4.89404C16.42 4.89404 20 8.47404 20 12.894C20 17.314 16.42 20.894 12 20.894Z'
        fill={color}
      />
    </svg>
  )
}

export default TimeLapseIcon
