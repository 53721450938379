import React from 'react'

import { IconProps } from '../types'

const CheckboxCheckedIcon = ({ size = 24, color = 'currentColor' }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.8333 2.5H4.16667C3.25 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V4.16667C17.5 3.25 16.75 2.5 15.8333 2.5ZM15.8333 15.8333H4.16667V4.16667H15.8333V15.8333ZM14.9917 7.5L13.8167 6.31667L8.325 11.8083L6.175 9.66667L4.99167 10.8417L8.325 14.1667L14.9917 7.5Z'
        fill={color}
      />
    </svg>
  )
}

export default CheckboxCheckedIcon
