import React, { useState } from 'react'

import styles from './faq.module.css'
import Accordion from '../../../organisms/accordion/Accordion.organism'
import { IconsRepository } from '../../../../repository/icons/icon.repository'
import Button from '../../../molecules/button/button.molecule'
import { ButtonTypes, IconDirection } from '../../../atoms/enums'

const FrequentlyAskedQuestions: React.FC = () => {
  const [currentModuleExpanded, setCurrentModuleExpanded] = useState(-1)

  const btnOnClick = (moduleIndex: number) => {
    setCurrentModuleExpanded(moduleIndex === currentModuleExpanded ? -1 : moduleIndex)
  }
  return (
    <section className={styles.wrapper}>
      <div className={styles.faq__container}>
        <h3 className={styles.heading__styles}>Frequently Asked Questions</h3>
        <div className={styles.questions}>
          <Accordion
            title={
              <button className={styles.accordion__item__btn}>
                <span className={styles.title__wrapper}>What is Datagirl Technologies?</span>
              </button>
            }
            icon={<IconsRepository.PlusIcon />}
            iconClose={<IconsRepository.MinusIcon />}
            isOpen={currentModuleExpanded === 0}
            btnOnClick={() => btnOnClick(0)}
            extraClass={styles.customAccordion}
            content={
              <>
                <div className={styles.content}>
                  <h4>
                    Datagirl Technologies is a startup aimed at training young people and
                    introducing them to the world of software development. This learning management
                    system aims at helping it&apos;s users with the best courses from industry lead
                    experts
                  </h4>
                </div>
              </>
            }
          />
          <Accordion
            title={
              <button className={styles.accordion__item__btn}>
                <span className={styles.title__wrapper}>
                  {/* <div className={styles.bullet}></div> */}
                  Are the courses free ?
                </span>
              </button>
            }
            icon={<IconsRepository.PlusIcon />}
            iconClose={<IconsRepository.MinusIcon />}
            isOpen={currentModuleExpanded === 2}
            btnOnClick={() => btnOnClick(2)}
            extraClass={styles.customAccordion}
            content={
              <>
                <div className={styles.content}>
                  <h4>
                    The courses offered on our learning platform are not free. We aim at helping
                    talents grow and give them the chance to build the foundation required to thrive
                    in the job market
                  </h4>
                </div>
              </>
            }
          />
          <Accordion
            title={
              <button className={styles.accordion__item__btn}>
                <span className={styles.title__wrapper}>
                  {/* <div className={styles.bullet}></div> */}
                  How can I enroll into a course ?
                </span>
              </button>
            }
            icon={<IconsRepository.PlusIcon />}
            iconClose={<IconsRepository.MinusIcon />}
            isOpen={currentModuleExpanded === 3}
            btnOnClick={() => btnOnClick(3)}
            extraClass={styles.customAccordion}
            content={
              <>
                <div>
                  <h4 className={styles.content}>
                    In order to enroll into a course, you can first of all browse our courses on the
                    all course page, next you can click on the desired course, on the course preview
                    page you will have a glance at the course details click on the enroll CTA to
                    create and account and access the course.
                  </h4>
                </div>
              </>
            }
          />
          <Accordion
            title={
              <button className={styles.accordion__item__btn}>
                <span className={styles.title__wrapper}>
                  {/* <div className={styles.bullet}></div> */}
                  Can I track the progress made on a course ?
                </span>
              </button>
            }
            icon={<IconsRepository.PlusIcon />}
            iconClose={<IconsRepository.MinusIcon />}
            isOpen={currentModuleExpanded === 4}
            btnOnClick={() => btnOnClick(4)}
            extraClass={styles.customAccordion}
            content={
              <>
                <div className={styles.content}>
                  <h4>
                    Absolutely, we ensure each student can track the progress he made on any course
                    and ensure the students can resume where they left off.
                  </h4>
                </div>
              </>
            }
          />
          <Accordion
            title={
              <button className={styles.accordion__item__btn}>
                <span className={styles.title__wrapper}>
                  {/* <div className={styles.bullet}></div> */}
                  Can I review the courses ?
                </span>
              </button>
            }
            icon={<IconsRepository.PlusIcon />}
            iconClose={<IconsRepository.MinusIcon />}
            isOpen={currentModuleExpanded === 5}
            btnOnClick={() => btnOnClick(5)}
            extraClass={styles.customAccordion}
            content={
              <>
                <div className={styles.content}>
                  <h4>
                    We do care about your opinion and are glad to receive any reviews or comments
                    that can help us create more sustainable innovations.
                  </h4>
                </div>
              </>
            }
          />
        </div>
      </div>
      <div className={styles.pattern_div}>
        <form
          className={`${styles.container} validate`}
          action='https://datagirltech.us5.list-manage.com/subscribe/post?u=7e166b48e7483aab3f85badf1&amp;id=8087bace1c&amp;f_id=00d2c8edf0'
          target='__blank'
          method='post'
          id='mc-embedded-subscribe-form'
          name='mc-embedded-subscribe-form'
        >
          <div aria-hidden='true' style={{ position: 'absolute', left: '-5000px' }}>
            <input
              type='text'
              name='b_7e166b48e7483aab3f85badf1_8087bace1c'
              tabIndex={-1}
              value=''
            />
          </div>
          <span className={styles.newsletter_span}>
            <span className={styles.newsletter_sub_span}>
              <p>Subscribe To Our Newsletter</p>
            </span>
            <p className={styles.extra_info}>
              Stay updated, with the latest rewards, workshops, events, challenges and much more
            </p>
          </span>
          <span className={styles.input_container}>
            <input
              type='email'
              name='EMAIL'
              id='mce-EMAIL'
              placeholder='Enter your email address'
              className={styles.input}
            />
            <span className={styles.btn_span}>
              <Button
                text={'Subscribe'}
                type={ButtonTypes.PRIMARY}
                iconDir={IconDirection.RIGHT}
                formType='submit'
                icon={
                  <span
                    style={{
                      transform: 'rotate(-90deg)',
                    }}
                  >
                    <IconsRepository.DropDownIcon size={10} />
                  </span>
                }
                className={styles.input_btn}
              />
            </span>
          </span>
        </form>
        <span className={styles.pattern_span}>
          <IconsRepository.PatternIcon />
        </span>
      </div>
    </section>
  )
}

export default FrequentlyAskedQuestions
