import React, { useState } from 'react'

import styles from './checkbox.module.css'

type Props = {
  label?: string
}
const CheckBox: React.FC<Props> = ({ label }) => {
  const [isChecked, setIsChecked] = useState(false)
  return (
    <div className={styles.checkbox__wrapper}>
      <label>
        <input
          onChange={() => setIsChecked((prev) => !prev)}
          type='checkbox'
          checked={isChecked}
          // style={{ width: '20px', height: '20px' }}
        />
        <span>{label}</span>
      </label>
    </div>
  )
}
export default CheckBox
