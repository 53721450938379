import React from 'react'

// stylesheet
import styles from '../filtersort.module.css'

// general imports
import { IconsRepository } from '../../../../repository/icons/icon.repository'

type Props = {
  event: string
  action: (str: string) => void
}

const FilterController = ({ event, action }: Props) => {
  return (
    <div className={styles.controller}>
      <button
        onClick={() => {
          action('filter')
        }}
      >
        {event}
        <IconsRepository.FilterIcon />
      </button>
    </div>
  )
}

export default FilterController
