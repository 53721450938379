/* eslint-disable */
import React, { useState } from 'react'

// stylesheet
import styles from '../modules.module.css'

// general imports
import CustomInputField from '../../../../../components/molecules/input/CustomInput.molecule'
import Button from '../../../../../components/molecules/button/button.molecule'
import { ButtonTypes } from '../../../../../components/atoms/enums'
import ValidateFunction, {
  PasswordValidationTypes,
  ValidatePasswordFunction,
  min8DigitRegex,
  oneNumericCharacterRegex,
  specialCharacterRegex,
  upperCaseRegex,
} from '../../../../../lib/utils/validate-function'
import { useAppDispatch, useAppSelector } from '../../../../../lib/hooks'
import { changePasswordThunkService } from '../../../../../features/authentication/authenticationThunks'
import Loader from '../../../../../components/atoms/loader/Loader'
import Popup from '../../../../../components/organisms/popup/Popup.organism'
import { resetAuthState } from '../../../../../features/auth/slices/auth.slice'
import { resetAuthenticationState } from '../../../../../features/authentication/authenticationSlice'
import { useNavigate } from 'react-router-dom'
import { appPaths } from '../../../../../constants/app-paths'

const Password = () => {
  const [formData, setFormData] = useState({
    newPassword: '',
    password: '',
    confirmPassword: '',
  })
  const { confirmPassword, password, newPassword } = formData
  const [formErrors, setFormError] = useState({} as PasswordValidationTypes)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { user } = useAppSelector((state) => state.authentication)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })

    setFormError({ ...formErrors, [e.target.name]: '' })
  }

  // const [passwordShow, setPasswordShow] = useState<boolean>(false)
  // const [showNewPassword, setShowNewPassword] = useState<boolean>(false)

  // const handleShowPassword = (target: boolean) => {
  //   if (target === true) {
  //     setPasswordShow(!passwordShow)
  //   } else {
  //     setShowNewPassword(!showNewPassword)
  //   }
  // }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    console.log('Form Data', formData)

    const { errors } = ValidatePasswordFunction(formData)
    setFormError(errors)

    if (Object.keys(errors).length > 0) {
      return
    }

    setLoading(true)
    dispatch(changePasswordThunkService({ ...formData, email: user?.email! }))
      .then(() => {
        setSuccess(true)
        dispatch(resetAuthenticationState())
        navigate(appPaths.LOGIN)
        navigate(0)
      })
      .catch(() => {
        setError(true)
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className={styles.module}>
      {loading ? <Loader /> : <></>}
      {success ? (
        <Popup
          toggleActive={setSuccess}
          active={success}
          title='success'
          msg={'Successfully Updated!'}
        />
      ) : (
        <></>
      )}
      {error ? (
        <Popup
          toggleActive={setError}
          active={error}
          title='error'
          msg={'Something went wrong. Please Try Again'}
        />
      ) : (
        <></>
      )}
      <div className={styles.head}>
        <h2>Change Password</h2>
      </div>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.form__control}>
          <div className={styles.input__wrapper}>
            <div className={styles.label}>
              <label htmlFor='current password'>Current Password</label>
            </div>
            <CustomInputField
              // left={true}
              name='password'
              value={password}
              onChange={onChange}
              width='100%'
              placeholder=''
              type='password'
            />
          </div>
          <p className={styles.validate__error}>{formErrors.password}</p>
        </div>

        <div className={styles.form__control}>
          <div className={styles.input__wrapper}>
            <div className={styles.label}>
              <label htmlFor='new password'>New Password</label>
            </div>
            <CustomInputField
              // left={true}
              name='newPassword'
              value={newPassword}
              onChange={onChange}
              width='100%'
              placeholder=''
              type='password'
            />
          </div>
          <p className={styles.validate__error}>{''}</p>
        </div>

        <div className={styles.form__control}>
          <div className={styles.input__wrapper}>
            <div className={styles.label}>
              <label htmlFor='confirm password'>Confirm Password</label>
            </div>
            <CustomInputField
              // left={true}
              name='confirmPassword'
              value={confirmPassword}
              onChange={onChange}
              width='100%'
              placeholder=''
              type='password'
            />
          </div>
          <p className={styles.validate__error}>{formErrors.confirmPassword}</p>
        </div>

        <h2 className={styles.sub}>Password Requirements</h2>
        <p className={styles.desc}>Please follow this guide for a strong password:</p>

        <div className={styles.bottom}>
          <ul className={styles.list}>
            {!specialCharacterRegex.test(newPassword) ? <li>One special character</li> : ''}
            {!min8DigitRegex.test(newPassword) ? <li>Min 8 characters</li> : ''}
            {!oneNumericCharacterRegex.test(newPassword) ? (
              <li>One number (2 are recommended)</li>
            ) : (
              ''
            )}
            {!upperCaseRegex.test(newPassword) ? <li>One capital letter</li> : ''}
          </ul>
          <div className={styles.btn}>
            <Button
              type={ButtonTypes.PRIMARY}
              text={'Update Password'}
              width='100%'
              height='48px'
              fontSize='1.6rem'
              className={styles.customBtn}
            />
          </div>
        </div>
      </form>
    </div>
  )
}

export default Password
