import React from 'react'
import { IconProps } from '../types'

const ChevronRightIcon: React.FC<IconProps> = ({ color = 'currentColor', size = 12 }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 8 12'
      fill='none'
    >
      <path
        d='M1.70492 0L0.294922 1.41L4.87492 6L0.294922 10.59L1.70492 12L7.70492 6L1.70492 0Z'
        fill={color}
      />
    </svg>
  )
}

export default ChevronRightIcon
