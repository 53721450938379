export type ValidationTypes = {
  firstName?: string
  lastName?: string
  email: string
  password: string
  confirmPassword?: string
}

export type PasswordValidationTypes = {
  password: string
  newPassword?: string
  confirmPassword?: string
}

export const min8DigitRegex = new RegExp('(?=.{8,})')
export const specialCharacterRegex = new RegExp('(?=.*[!@#$%^&*#+-=])')
export const oneNumericCharacterRegex = new RegExp('(?=.*[0-9])')
export const upperCaseRegex = new RegExp('(?=.*[A-Z])')

const ValidateFunction = (values: ValidationTypes) => {
  const errors = {} as ValidationTypes

  /* eslint-disable */
  const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g

  if (!values.email) {
    errors.email = 'Email is required'
  } else if (!regex.test(values.email)) {
    errors.email = 'This is not a valid email address'
  }

  if (!values.password) {
    errors.password = 'Password is required'
  }

  if (!values.confirmPassword && values.confirmPassword !== undefined) {
    errors.confirmPassword = 'This field is required'
  } else if (values.password !== values.confirmPassword && values.confirmPassword !== undefined) {
    errors.confirmPassword = 'Passwords do not match'
  }

  if (!values.firstName && values.firstName !== undefined) {
    errors.firstName = 'Please enter your first name'
  }

  if (!values.lastName && values.lastName !== undefined) {
    errors.lastName = 'Please enter your last name'
  }

  return { errors }
}

export const ValidatePasswordFunction = (values: PasswordValidationTypes) => {
  const errors = {} as PasswordValidationTypes

  if (!values.password) {
    errors.password = 'Old Password is required'
  }

  if (!values.confirmPassword && values.confirmPassword !== undefined) {
    errors.confirmPassword = 'This field is required'
  } else if (
    values.newPassword !== values.confirmPassword &&
    values.confirmPassword !== undefined
  ) {
    errors.confirmPassword = 'Passwords do not match'
  }

  return { errors }
}

export default ValidateFunction
