import {
  reviewImage1,
  reviewImage2,
  reviewImage3,
  reviewImage4,
  reviewImage5,
  reviewImage6,
} from '../assets'

export interface dataTypes {
  description: string
  image: string
  name: string
  courseName: string
  review: number
}

const ReviewData: dataTypes[] = [
  {
    description:
      'The course was easy to follow and understand, the instructor was knowledgeable and helpful, and the course materials were comprehensive.',
    image: reviewImage1,
    name: 'Student Name',
    courseName: 'Course Name',
    review: 4.5,
  },
  {
    description:
      'The course was easy to follow and understand, the instructor was knowledgeable and helpful, and the course materials were comprehensive. ',
    image: reviewImage2,
    name: 'Student Name',
    courseName: 'Course Name',
    review: 3.8,
  },
  {
    description:
      'The course was easy to follow and understand, the instructor was knowledgeable and helpful. The course was engaging and interactive, giving me a chance to practice what I was learning in real life. Overall, I would highly recommend this course for anyone interested in learning coding.',
    image: reviewImage3,
    name: 'Student Name',
    courseName: 'Course Name',
    review: 5,
  },
  {
    description:
      'The course was easy to follow and understand, the instructor was knowledgeable and helpful, and the course materials were comprehensive. ',
    image: reviewImage4,
    name: 'Student Name',
    courseName: 'Course Name',
    review: 3.8,
  },
  {
    description:
      'The course was easy to follow and understand, the instructor was knowledgeable and helpful, and the course materials were comprehensive. ',
    image: reviewImage5,
    name: 'Student Name',
    courseName: 'Course Name',
    review: 3.8,
  },
  {
    description:
      'The course was easy to follow and understand, the instructor was knowledgeable and helpful, and the course materials were comprehensive. ',
    image: reviewImage6,
    name: 'Ngumih Fien',
    courseName: 'Course Name',
    review: 3.8,
  },
  {
    description:
      'The course was easy to follow and understand, the instructor was knowledgeable and helpful, and the course materials were comprehensive. ',
    image: reviewImage1,
    name: 'Student Name',
    courseName: 'Course Name',
    review: 3.8,
  },
  {
    description:
      'The course was easy to follow and understand, the instructor was knowledgeable and helpful, and the course materials were comprehensive. ',
    image: reviewImage2,
    name: 'Student Name',
    courseName: 'Course Name',
    review: 3.8,
  },
  {
    description:
      'The course was easy to follow and understand, the instructor was knowledgeable and helpful, and the course materials were comprehensive. ',
    image: reviewImage3,
    name: 'Student Name',
    courseName: 'Course Name',
    review: 3.8,
  },
]

export default ReviewData
