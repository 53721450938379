import { IconProps } from '../types'

const CodeIcon = ({
  width = 22,
  height = 18,
  color = 'currentColor',
  stroke = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      stroke={`${stroke}`}
      viewBox='0 0 22 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.7797 0.398453C14.4084 0.608031 14.7482 1.28761 14.5386 1.91635L9.73862 16.3163C9.52904 16.9451 8.84945 17.2849 8.22072 17.0753C7.59199 16.8657 7.2522 16.1861 7.46177 15.5574L12.2618 1.1574C12.4714 0.528668 13.1509 0.188876 13.7797 0.398453ZM5.84872 4.28835C6.31735 4.75697 6.31735 5.51677 5.84872 5.9854L3.09725 8.73687L5.84872 11.4883C6.31735 11.957 6.31735 12.7168 5.84872 13.1854C5.38009 13.654 4.6203 13.654 4.15167 13.1854L0.551667 9.5854C0.083038 9.11677 0.083038 8.35697 0.551667 7.88835L4.15167 4.28835C4.6203 3.81972 5.38009 3.81972 5.84872 4.28835ZM16.1517 4.28835C16.6203 3.81972 17.3801 3.81972 17.8487 4.28835L21.4487 7.88835C21.9174 8.35697 21.9174 9.11677 21.4487 9.5854L17.8487 13.1854C17.3801 13.654 16.6203 13.654 16.1517 13.1854C15.683 12.7168 15.683 11.957 16.1517 11.4883L18.9031 8.73687L16.1517 5.9854C15.683 5.51677 15.683 4.75697 16.1517 4.28835Z'
        fill={color}
      />
    </svg>
  )
}

export default CodeIcon
