import styles from './footer.module.css'
import { logo } from '../../../repository/assets'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.bottom_div}>
        <div className={styles.logo_div}>
          <img src={logo} alt='Camsol Logo' />
          <div>
            <p className={styles.logo_div_text}>
              We are an award-winning digital agency rendering services in web development, digital
              marketing, and branding to small and medium sized enterprises. We offer quality and
              ICT and Computer training services in Cameroon.
            </p>
          </div>
        </div>
        <div className={styles.sub_container}>
          <span className={styles.main}>About Us</span>
          <Link target='__blank' to='https://datagirltech.com/about-us/'>
            About us
          </Link>
          <Link target='__blank' to='https://datagirltech.com/contact-us/'>
            Contact Us
          </Link>
          <Link target='__blank' to='https://datagirltech.com/our-services/'>
            Services
          </Link>
          <Link target='__blank' to='https://datagirltech.com/news/'>
            Portfolio
          </Link>
        </div>
        <div className={styles.sub_container}>
          <span className={styles.main}>Social</span>
          <Link target='__blank' to='https://www.facebook.com/datagirltech'>
            Facebook
          </Link>
          <Link target='__blank' to='https://www.instagram.com/datagirltech/'>
            Instagram
          </Link>
          <Link target='__blank' to='https://www.linkedin.com/company/data-girl-technologies/'>
            LinkedIn
          </Link>
          <Link target='__blank' to='https://datagirltech.com/about-us/'>
            Youtube
          </Link>
          <Link target='__blank' to='https://datagirltech.com/about-us/'>
            Github
          </Link>
        </div>

        <div className={styles.newsletter_div}>
          <p className={styles.main}>Contact</p>
          <p className={styles.text}>Mon-Fri: 8.00am 6pm</p>
          <p className={styles.text}>
            2nd Floor, Sparkland Building, Opposite Eta Palace, Molyko-Buea
          </p>
          <p className={styles.text}>(+237) 677-303-086</p>
          <p className={styles.text}>info@datagirltech.com</p>
        </div>
      </div>
      <p className={styles.copyright}>© 2024 DataGirl Technologies. All rights reserved</p>
    </footer>
  )
}

export default Footer
