import authSlice from '../features/auth/slices/auth.slice'
import courseSlice from '../features/course/slices/course.slice'
import authenticationSlice from '../features/authentication/authenticationSlice'
import verificationSlice from '../features/authentication/verificationSlice'
import courseReviewSlice from '../features/course/slices/courseReview.slice'
import forgotPasswordSlice from '../features/authentication/forgotPasswordSlice'

// eslint-disable-next-line
export default {
  auth: authSlice,
  course: courseSlice,
  authentication: authenticationSlice,
  verification: verificationSlice,
  courseReview: courseReviewSlice,
  forgotPassword: forgotPasswordSlice,
}
