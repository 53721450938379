import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { CourseEntityType } from '../../../types/models'
import { courseThunk, singleCourseThunk } from '../thunks/course.thunk'

export interface CourseState {
  courses: CourseEntityType[]
  singleCourse: CourseEntityType
  suggestedCourses: Array<CourseEntityType>
  isError: boolean
  isSuccess: boolean
  message: string
  isLoading: boolean
  search: string
}

const initialState: CourseState = {
  courses: [] as CourseEntityType[],
  singleCourse: {} as CourseEntityType,
  suggestedCourses: [] as Array<CourseEntityType>,
  isError: false,
  isSuccess: false,
  message: '',
  isLoading: false,
  search: '',
}

const courseSlice = createSlice({
  name: 'courseSlice',
  initialState,
  reducers: {
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload
    },
    setSuggestedCourses: (state, action: PayloadAction<Array<CourseEntityType>>) => {
      state.suggestedCourses = action.payload
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(courseThunk.pending, (state) => {
        state.isLoading = true
        state.isError = false
        state.isSuccess = false
        state.message = ''
      })
      .addCase(courseThunk.fulfilled, (state, { payload }: PayloadAction<CourseEntityType[]>) => {
        state.isLoading = false
        state.isSuccess = true
        state.courses = payload || []
      })
      .addCase(courseThunk.rejected, (state, { payload }) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        console.log(payload)
        // state.message = payload.message
      })
      .addCase(singleCourseThunk.pending, (state) => {
        state.isLoading = true
        state.isError = false
        state.isSuccess = false
        state.message = ''
      })
      .addCase(
        singleCourseThunk.fulfilled,
        (state, { payload }: PayloadAction<CourseEntityType>) => {
          state.isLoading = false
          state.isSuccess = true
          console.log('data: ', payload)

          state.singleCourse = payload
        },
      )
      .addCase(singleCourseThunk.rejected, (state, { payload }) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        console.log(payload)
        // state.message = payload.message
      })
  },
})

export const { setSearch, setSuggestedCourses } = courseSlice.actions
export default courseSlice.reducer
