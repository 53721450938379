import React from 'react'
import { IconProps } from '../types'

const BellIcon = ({
  size = 25,
  height = 26,
  color = 'currentColor',
  stroke = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      width={size}
      height={height}
      stroke={`${stroke}`}
      viewBox='0 0 17 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M8.50033 21.1562C9.64616 21.1562 10.5837 20.2187 10.5837 19.0729H6.41699C6.41699 20.2187 7.35449 21.1562 8.50033 21.1562ZM14.7503 14.9062V9.69792C14.7503 6.5 13.0524 3.82292 10.0628 3.11458V2.40625C10.0628 1.54167 9.36491 0.84375 8.50033 0.84375C7.63574 0.84375 6.93783 1.54167 6.93783 2.40625V3.11458C3.95866 3.82292 2.25033 6.48958 2.25033 9.69792V14.9062L0.166992 16.9896V18.0312H16.8337V16.9896L14.7503 14.9062ZM12.667 15.9479H4.33366V9.69792C4.33366 7.11458 5.90658 5.01042 8.50033 5.01042C11.0941 5.01042 12.667 7.11458 12.667 9.69792V15.9479Z'
        fill={color}
      />
    </svg>
  )
}

export default BellIcon
