import React from 'react'

// styles import
import styles from './instructor.module.css'
import { defaultImage } from '../../../repository/assets'

type instructorProps = {
  img: string
  name: string
  role?: string
}

const Instructor: React.FC<instructorProps> = ({ img, name, role }) => {
  const [cover, setCover] = React.useState(img || defaultImage)

  return (
    <div className={styles.container}>
      <div className={styles.img}>
        <img src={cover} alt={name} onError={() => setCover(defaultImage)} />
      </div>
      <div className={styles.course}>
        <p className={styles.instructor__name}>{name}</p>
        {role !== undefined ? <p className={styles.instructor__role}>{role}</p> : null}
      </div>
    </div>
  )
}

export default Instructor
