import React from 'react'
import { IconProps } from '../types'

const PlayCircleFilledTwo = ({
  size = 114,
  height = 115,
  color = 'currentColor',
  stroke = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={height}
      stroke={`${stroke}`}
      viewBox='0 0 24 25'
      fill='none'
      {...props}
    >
      <path
        d='M12 2.89404C6.48 2.89404 2 7.37404 2 12.894C2 18.414 6.48 22.894 12 22.894C17.52 22.894 22 18.414 22 12.894C22 7.37404 17.52 2.89404 12 2.89404ZM10 17.394V8.39404L16 12.894L10 17.394Z'
        fill={color}
      />
    </svg>
  )
}

export default PlayCircleFilledTwo
