import React, { useState, useEffect } from 'react'
// import ReactPlayer from 'react-player/lazy'
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter'
import { jsx } from 'react-syntax-highlighter/dist/esm/languages/prism'
// import { duotoneDark } from 'react-syntax-highlighter/dist/esm/styles/prism'

// stylesheet
import styles from './content.module.css'
// import { IconsRepository } from '../../../../repository/icons/icon.repository'

SyntaxHighlighter.registerLanguage('javascript', jsx)

export const ContentTypeMapping = {
  h1: 'heading_1',
  h2: 'heading_2',
  h3: 'heading_3',
  audio: 'audio',
  p: 'paragraph',
  img: 'image',
  pdf: 'pdf',
  video: 'video',
  code: 'code',
  bookmark: 'bookmark',
  bulletedListItem: 'bulleted_list_item',
  bulletRef: 'bulletedListItem',
  embed: 'embed',
  equation: 'equation',
  file: 'file',
  numberedListItem: 'numbered_list_item',
  numRef: 'numberedListItem',
  quote: 'quote',
  todo: 'to_do',
}

type ContentProps = {
  // eslint-disable-next-line
  content: any
}

const StringContent: React.FC<ContentProps> = ({ content }) => {
  const [html, setHtml] = useState('')

  const replaceImages = (workingContent: string) => {
    let lastIndex = 0
    if (!workingContent || typeof workingContent !== 'string') return workingContent

    while (lastIndex !== -1) {
      const startIndex = workingContent.indexOf('!<a', lastIndex)
      if (startIndex === -1) break
      lastIndex = startIndex + 1
      const closingIndex = workingContent.indexOf('</a>', lastIndex)
      const body = workingContent.substring(startIndex, closingIndex + 4)
      const url = body.substring(body.indexOf('href="') + 6, body.lastIndexOf('"'))
      workingContent =
        workingContent.substring(0, startIndex) +
        `<img alt="bootcamp" src="${url}" />` +
        workingContent.substring(closingIndex + 4)
    }

    return workingContent
  }

  const replaceIframes = (workingContent: string) => {
    let lastIndex = 0
    if (!workingContent || typeof workingContent !== 'string') return workingContent

    while (lastIndex !== -1) {
      const startIndex = workingContent.indexOf('<p><a', lastIndex)
      if (startIndex === -1) break
      lastIndex = startIndex + 1
      const closingIndex = workingContent.indexOf('</a>', lastIndex)
      const body = workingContent.substring(startIndex, closingIndex + 4)
      const url = body.substring(body.indexOf('href="') + 6, body.lastIndexOf('"'))
      workingContent =
        workingContent.substring(0, startIndex) +
        `<iframe title="bootcamp" src="${url}" ></iframe>` +
        workingContent.substring(closingIndex + 4)
    }

    return workingContent
  }

  const findAndSplitCodeBlocks = (workingContent: string) => {
    const sections = []
    const codeBlocks = []
    let lastIndex = 0
    if (!workingContent || typeof workingContent !== 'string') return
    while (lastIndex !== -1) {
      const startIndex = workingContent.indexOf('<pre><code', lastIndex)
      if (startIndex === -1) {
        sections.push(workingContent)
        break
      }
      lastIndex = startIndex + 1
      const closingIndex = workingContent.indexOf('</code>', lastIndex)
      const body = workingContent.substring(startIndex, closingIndex + 7)
      const codeBlock = body.substring(33, body.lastIndexOf('</'))
      codeBlocks.push(codeBlock)
      sections.push(workingContent.substring(0, startIndex))
      workingContent = workingContent.substring(closingIndex + 7)
    }

    console.log('sections ', sections)
    console.log('codeBlocks', codeBlocks)
  }

  useEffect(() => {
    let workingContent: string = content
    workingContent = replaceImages(workingContent)
    workingContent = replaceIframes(workingContent)
    findAndSplitCodeBlocks(workingContent)
    setHtml(workingContent)
  }, [content])

  return (
    <>
      <div className={styles.content} dangerouslySetInnerHTML={{ __html: html }} />
    </>
  )
}

// const useAudio = (url: string) => {
//   const [audio] = useState(new Audio(url))
//   const [playing, setPlaying] = useState(false)
//   const [duration, setDuration] = useState<number>(0)
//   const [played, setPlayed] = useState<number>(0)

//   const toggle = () => setPlaying((playing) => !playing)

//   let t: NodeJS.Timer

//   useEffect(() => {
//     audio.volume = 1
//     if (playing) {
//       audio.play()

//       t = setInterval(() => {
//         setPlayed(Math.round(audio.currentTime))
//       }, 10)
//     } else {
//       audio.pause()
//       clearInterval(t)
//     }
//   }, [playing])

//   useEffect(() => {
//     audio.addEventListener('ended', () => setPlaying(false))
//     audio.addEventListener('loadeddata', () => {
//       setDuration(Math.ceil(audio.duration))
//       setPlayed(Math.round(audio.currentTime))
//     })
//     return () => {
//       audio.removeEventListener('ended', () => setPlaying(false))
//       audio.removeEventListener('loadeddata', () => console.log())
//     }
//   }, [])

//   return [playing, toggle, duration, played]
// }

// const Player = ({ url }: { url: string }) => {
//   const [playing, toggle, duration, played] = useAudio(url)

//   const format = (seconds: number) => {
//     return new Date(seconds * 1000).toISOString().substring(14, 19)
//   }

//   const getPercentage = (current: number, complete: number) => {
//     return Math.round((current / complete) * 100)
//   }

//   return (
//     <div>
//       <div className={styles.audio__wrapper}>
//         <button
//           onClick={() => {
//             if (typeof toggle === 'function') {
//               toggle()
//             }
//           }}
//         >
//           {playing ? (
//             <IconsRepository.PauseCircleFilled size={40} />
//           ) : (
//             <IconsRepository.PlayCircleFilledIcon size={40} height={40} />
//           )}
//         </button>
//         <p>
//           {format(Number(played))} / {format(Number(duration))}
//         </p>
//         <div className={styles.progress}>
//           <ProgressBar
//             completed={getPercentage(Number(played), Number(duration))}
//             bgcolor='#5C187A'
//           />
//         </div>
//       </div>
//     </div>
//   )
// }

// const CodeBlock = ({ codestring, lang }: { codestring: string; lang: string }) => {
//   const [copied, setCopied] = useState(false)

//   const copy = async () => {
//     await navigator.clipboard.writeText(codestring)
//     setCopied(true)
//     setTimeout(() => {
//       setCopied(false)
//     }, 3000)
//   }

//   return (
//     <div className={styles.codeBlock}>
//       <SyntaxHighlighter
//         language={lang}
//         style={duotoneDark}
//         wrapLines
//         lineProps={{ className: styles.line }}
//       >
//         {codestring}
//       </SyntaxHighlighter>
//       <button
//         className={styles.copy}
//         onClick={copy}
//         title={copied ? 'Copied' : 'Copy To Clipboard'}
//       >
//         {copied ? <IconsRepository.DoneIcon size={16} /> : <IconsRepository.FileCopy size={16} />}
//       </button>
//     </div>
//   )
// }

// const ProgressBar = ({ bgcolor, completed }: { bgcolor: string; completed: number }) => {
//   return (
//     <div className={styles.parent__container}>
//       <div className={styles.container}>
//         <div
//           className={styles.filler__styles}
//           style={{ backgroundColor: bgcolor, width: `${completed}%` }}
//         >
//           <span className={styles.label}>{`${completed}%`}</span>
//         </div>
//       </div>
//     </div>
//   )
// }

export default StringContent
