import React from 'react'
import { Link } from 'react-router-dom'

// styles import
import styles from './videomodule.module.css'

import Button from '../../molecules/button/button.molecule'
import { ButtonTypes } from '../../atoms/enums'
import { IconsRepository } from '../../../repository/icons/icon.repository'

type vidoModuleTypes = {
  link: string
  text: string
  index: number
  isDisabled?: boolean
  isCurrent?: boolean
}

const VideoModule: React.FC<vidoModuleTypes> = ({ link, text, index, isCurrent, isDisabled }) => {
  return (
    <div className={styles.video__module__container}>
      <div className={styles.video__status}>
        <div>
          {!isDisabled ? (
            <IconsRepository.ActiveRadioButtonIcon />
          ) : (
            <IconsRepository.InactiveRadioButtonIcon />
          )}
        </div>
        <div className={styles.vid__icon}>
          <IconsRepository.VideoPlayerIcon />
        </div>
        <p className={styles.video__text}>{String(index) + '. ' + text}</p>
      </div>
      <div className={styles.btn}>
        {isDisabled ? (
          <Button
            type={ButtonTypes.PRIMARY}
            fontSize='12px'
            width='78px'
            height='31px'
            text={'Start'}
            className={styles.custom}
            disabled
          />
        ) : (
          <Link to={`${link}${isCurrent ? '?repeat=true' : ''}`}>
            <Button
              type={ButtonTypes.PRIMARY}
              fontSize='12px'
              width='78px'
              height='31px'
              text={isCurrent ? 'Repeat' : 'Start'}
              className={styles.custom}
            />
          </Link>
        )}
      </div>
    </div>
  )
}

export default VideoModule
