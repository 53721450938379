import React from 'react'
import { IconProps } from '../types'

const FileCopy: React.FC<IconProps> = ({ size = 20, color = 'currentColor', stroke, ...props }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      stroke={stroke?.toString() || ''}
      viewBox='0 0 20 20'
      fill='none'
      {...props}
    >
      <path
        d='M13.7497 0.833313H3.74967C2.83301 0.833313 2.08301 1.58331 2.08301 2.49998V14.1666H3.74967V2.49998H13.7497V0.833313ZM12.9163 4.16665H7.08301C6.16634 4.16665 5.42467 4.91665 5.42467 5.83331L5.41634 17.5C5.41634 18.4166 6.15801 19.1666 7.07467 19.1666H16.2497C17.1663 19.1666 17.9163 18.4166 17.9163 17.5V9.16665L12.9163 4.16665ZM7.08301 17.5V5.83331H12.083V9.99998H16.2497V17.5H7.08301Z'
        fill={color}
      />
    </svg>
  )
}

export default FileCopy
