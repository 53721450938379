/* eslint-disable */

import { createAsyncThunk } from '@reduxjs/toolkit'

import { publicRequest } from '../../constants/configs'
import { getExceptionPayload } from '../../lib/utils/exception-handler'
import { UserType } from '../../types/models'
import { LocalStorage } from '../../services/storage/Local.storage'

export const loginThunkService = createAsyncThunk(
  'auth/login/student',
  async (
    data: {
      email: string
      password: string
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await publicRequest.post<UserType>(
        '/auth/login/student',
        {
          email: data.email,
          password: data.password,
        },
        {
          headers: {
            Authorization: `Bearer ${LocalStorage.getAccessToken()}`,
          },
        },
      )

      // @ts-ignore
      const { user } = response.data

      const courses: Array<any> = user.courses || []

      let currentSubLesson = ''
      if (courses && courses.length > 0) {
        courses.forEach((course) => {
          if (course.lessons === undefined || course.lessons?.length < 1) {
            return
          }
          for (let lesson of course.lessons) {
            if (lesson.subLessons?.length < 1) {
              return
            }
            if (lesson.currentSubLesson) {
              currentSubLesson = lesson.currentSubLesson
            }
          }
        })
      }

      return { ...response.data, currentSubLesson }
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex))
    }
  },
)

export const registerThunkService = createAsyncThunk(
  'auth/register/student',
  async (
    data: {
      email: string
      password: string
      firstName: string
      lastName: string
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await publicRequest.post<UserType>(
        '/auth/register/student',
        {
          email: data.email,
          password: data.password,
          first_name: data.firstName,
          last_name: data.lastName,
          avatarUrl: '',
          phoneNumber: '',
        },
        {
          headers: {
            Authorization: `Bearer ${LocalStorage.getAccessToken()}`,
          },
        },
      )
      return response.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex))
    }
  },
)

export const changePasswordThunkService = createAsyncThunk(
  'auth/update/password',
  async (
    data: {
      password: string
      newPassword: string
      email: string
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await publicRequest.post<UserType>(
        '/auth/update/password',
        {
          email: data.email,
          currentPassword: data.password,
          newPassword: data.newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${LocalStorage.getAccessToken()}`,
          },
        },
      )
      return response.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex))
    }
  },
)

export const updateProfileThunkService = createAsyncThunk(
  'auth/update/profile',
  async (
    data: {
      first_name?: string
      last_name?: string
      phone?: string
      email?: string
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await publicRequest.post<UserType>('/users/me/update', data, {
        headers: {
          Authorization: `Bearer ${LocalStorage.getAccessToken()}`,
        },
      })
      return response.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex))
    }
  },
)

export const verifyOPTThunkService = createAsyncThunk(
  'auth/verify/student',
  async (
    data: {
      email: string
      verification_code: string
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await publicRequest.post<UserType>(
        '/auth/verify/student',
        {
          email: data.email,
          verification_code: data.verification_code,
        },
        {
          headers: {
            Authorization: `Bearer ${LocalStorage.getAccessToken()}`,
          },
        },
      )
      console.log(response)
      return response.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex))
    }
  },
)

export const resendOTPThunkService = createAsyncThunk(
  'auth/resend/otp',
  async (
    data: {
      email: string
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await publicRequest.post<UserType>(
        '/auth/resend/otp',
        {
          email: data.email,
        },
        {
          headers: {
            Authorization: `Bearer ${LocalStorage.getAccessToken()}`,
          },
        },
      )
      return response.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex))
    }
  },
)

export const forgotPasswordThunkService = createAsyncThunk(
  'auth/forgot/password',
  async (
    data: {
      email: string
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await publicRequest.post(
        '/auth/forgot/password',
        {
          email: data.email,
        },
        {
          headers: {
            Authorization: `Bearer ${LocalStorage.getAccessToken()}`,
          },
        },
      )
      return response.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex))
    }
  },
)

export const resetPasswordThunkService = createAsyncThunk(
  'auth/reset/password',
  async (
    data: {
      email: string
      password: string
      verification_code: string
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await publicRequest.post(
        '/auth/reset/password',
        {
          email: data.email,
          password: data.password,
          verification_code: data.verification_code,
        },
        {
          headers: {
            Authorization: `Bearer ${LocalStorage.getAccessToken()}`,
          },
        },
      )
      return response.data
    } catch (ex) {
      return rejectWithValue(getExceptionPayload(ex))
    }
  },
)
