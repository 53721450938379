const timeConvert = (n: number) => {
  const num = n
  const hours = num / 60
  const rhours = Math.floor(num / 60)
  const minutes = (hours - rhours) * 60
  const rminutes = Math.round(minutes)
  if (rhours > 0) {
    return `${rhours}h:${rminutes}min`
  }
  return `${rminutes}mins`
}

export default timeConvert
