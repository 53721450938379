import { IconProps } from '../types'

const Task = ({
  width = 20,
  height = 20,
  color = 'currentColor',
  stroke = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      stroke={`${stroke}`}
      viewBox='0 0 12 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M7.33317 0.333344H1.99984C1.2665 0.333344 0.673171 0.933344 0.673171 1.66668L0.666504 12.3333C0.666504 13.0667 1.25984 13.6667 1.99317 13.6667H9.99984C10.7332 13.6667 11.3332 13.0667 11.3332 12.3333V4.33334L7.33317 0.333344ZM9.99984 12.3333H1.99984V1.66668H6.6665V5.00001H9.99984V12.3333ZM3.87984 7.70001L2.93317 8.64001L5.29317 11L9.0665 7.22668L8.1265 6.28668L5.29984 9.11334L3.87984 7.70001Z'
        fill={color}
      />
    </svg>
  )
}

export default Task
