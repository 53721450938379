/* eslint-disable */
import React, { Fragment } from 'react'
import styles from './bootcamps.module.css'

import { Link } from 'react-router-dom'
import BootcampCard from '../../../organisms/cards/bootcamp/BootcampCard.organism'
import bootcamps from '../../../../repository/data/bootcamp-data'

const Bootcamps: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.text__content}>
        <p className={styles.welcome}>Discover Our Bootcamps</p>
        <p className={styles.title}> Datagirls Bootcamps </p>
        <p className={styles.lorem}>
          Students will apply through an online form, and pre-selection will be done immediately.
          Interviews will be done in the following week of application. Pre-selected students will
          be contacted. The final selection will be done immediately after the interview. The
          selected students will be informed.
        </p>
      </div>
      <div className={styles.bootcamps}>
        {bootcamps.map((bootcamp) => (
          <BootcampCard {...bootcamp} key={bootcamp.title} />
        ))}
      </div>
    </div>
  )
}

export default Bootcamps
