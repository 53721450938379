import React, { ReactNode } from 'react'

// stylesheet
import styles from './button.module.css'

// general imports
import { ButtonTypes, IconDirection } from '../../atoms/enums'

type ButtonPropTypes = {
  text: ReactNode
  fontSize?: string
  height?: string
  padding?: string
  width?: string
  className?: string
  borderRadius?: string
  icon?: JSX.Element
  type?: ButtonTypes
  iconDir?: IconDirection
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void
  disabled?: boolean
  formType?: 'button' | 'submit' | 'reset'
}

const Button: React.FC<ButtonPropTypes> = ({
  text,
  fontSize,
  height,
  padding,
  borderRadius,
  width,
  className,
  icon,
  type,
  iconDir,
  onClick,
  disabled,
  formType,
}) => {
  return (
    <button
      className={`${styles.button} ${
        type === ButtonTypes.PRIMARY ? styles.primary : styles.secondary
      } ${className as string}`}
      style={{
        width,
        height,
        borderRadius,
        padding,
        fontSize,
      }}
      onClick={() => {
        if (onClick !== undefined) {
          onClick()
        }
      }}
      disabled={disabled}
      type={formType}
    >
      {iconDir === IconDirection.LEFT ? icon : null}
      {text}
      {iconDir === IconDirection.RIGHT ? icon : null}
    </button>
  )
}

export default Button
