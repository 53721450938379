/* eslint-disable */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { APIStatus } from '../../constants/configs'
import {
  loginThunkService,
  registerThunkService,
  resetPasswordThunkService,
  updateProfileThunkService,
  verifyOPTThunkService,
} from './authenticationThunks'
import { courseProgressThunk, enrollCourseThunk } from '../course/thunks/course.thunk'
import { LocalStorage } from '../../services/storage/Local.storage'
import { UserEnrollment, UserType } from '../../types/models'

const getCurrentSublesson = (user: UserType | null) => {
  if (!user) {
    return ''
  }

  const courses: Array<any> = user?.courses || []

  let currentSubLesson = ''
  if (courses && courses.length > 0) {
    courses.forEach((course) => {
      if (course.lessons?.length < 1) {
        return
      }
      for (let lesson of course?.lessons || []) {
        if (lesson?.subLessons?.length < 1) {
          continue
        }
        if (lesson?.currentSubLesson) {
          currentSubLesson = lesson.currentSubLesson
        }
      }
    })
  }

  return currentSubLesson
}

export const getCurrentSublessonFromCourse = (user: UserType | null, courseID: number) => {
  if (!user) {
    return ''
  }

  const enrollments: Array<UserEnrollment> = user.enrollments

  const enrollment = enrollments?.find((enrollment) => enrollment.course === Number(courseID))
  const progress: Array<any> = enrollment?.progress || []

  let currentSubLesson = ''
  const lastUpdate = progress.at(-1)
  currentSubLesson = lastUpdate?.subLesson || ''
  console.log('final currentSub', currentSubLesson)

  return currentSubLesson
}

export const getCurrentLessonFromCourse = (user: UserType | null, courseID: number) => {
  if (!user) {
    return undefined
  }

  const enrollments: Array<UserEnrollment> = user.enrollments

  const enrollment = enrollments?.find((enrollment) => enrollment.course === courseID)
  const progess: Array<any> = enrollment?.progress || []

  let currentLesson = undefined
  const lastUpdate = progess.at(-1)
  currentLesson = lastUpdate?.lesson

  return currentLesson
}

const user = LocalStorage.getCurrentUser()
const token = LocalStorage.getAccessToken()
const currentSubLesson = getCurrentSublesson(user)

export const initialState: {
  user: UserType | null
  status: APIStatus
  errorTrace: boolean
  message: string
  token: string | null
  currentSubLesson?: string
  currentCourse?: string
} = {
  user: user !== null ? user : null,
  status: APIStatus.IDLE,
  errorTrace: false,
  message: '',
  token: token !== null ? token : null,
  currentSubLesson: currentSubLesson,
}

const authenticationSlice = createSlice({
  name: 'authenticationSlice',
  initialState: initialState,
  reducers: {
    resetAuthenticationState: (state) => {
      state.user = null
      state.errorTrace = false
      state.status = APIStatus.IDLE
      state.message = ''
      LocalStorage.clearCurrentUser()
    },

    resetErrorState: (state) => {
      state.errorTrace = false
      state.message = ''
      state.status = APIStatus.IDLE
    },
    setCurrentSubLesson: (state, action) => {
      state.currentSubLesson = action.payload
    },
    setCurrentCourse: (state, action) => {
      state.currentCourse = action.payload
      state.currentSubLesson = getCurrentSublessonFromCourse(user, action.payload)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginThunkService.pending, (state) => {
        state.status = APIStatus.PENDING
      })
      .addCase(updateProfileThunkService.fulfilled, (state, action: PayloadAction<any>) => {
        const updatedUser = { ...action.payload }
        const currentUser = { ...state.user }
        const newUser = { ...currentUser, ...updatedUser }
        state.user = newUser
        LocalStorage.storeCurrentUser(newUser)
      })
      .addCase(loginThunkService.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = APIStatus.FULFILLED

        state.user = action.payload.user
        state.token = action.payload.token
        state.currentSubLesson = action.payload.currentSubLesson

        localStorage.setItem('email_tracker', action.payload.user.email)

        LocalStorage.storeAccessToken(action.payload.token)
        LocalStorage.storeCurrentUser(action.payload.user)
      })
      .addCase(resetPasswordThunkService.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = APIStatus.FULFILLED
        state.user = action.payload.user
        state.token = action.payload.token
        localStorage.setItem('email_tracker', action.payload.user.email)

        LocalStorage.storeAccessToken(action.payload.token)
        LocalStorage.storeCurrentUser(action.payload.user)
      })
      .addCase(loginThunkService.rejected, (state, action) => {
        state.status = APIStatus.REJECTED
        state.errorTrace = true
        state.message = (action.payload as { error: string }).error
      })
      .addCase(registerThunkService.pending, (state) => {
        state.status = APIStatus.PENDING
      })
      .addCase(registerThunkService.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = APIStatus.FULFILLED

        LocalStorage.storeCurrentUser(action.payload.user)
      })
      .addCase(registerThunkService.rejected, (state, action) => {
        state.status = APIStatus.REJECTED
        state.errorTrace = true
        state.message = (action.payload as { error: string }).error
      })
      .addCase(verifyOPTThunkService.fulfilled, (state, action: PayloadAction<any>) => {
        console.log(action.payload)
        state.user = action.payload.user
      })
      .addCase(
        enrollCourseThunk.fulfilled,
        (state, { payload }: PayloadAction<any | { error: string }>) => {
          state.user = payload

          LocalStorage.storeCurrentUser(payload.user)
        },
      )
      .addCase(
        courseProgressThunk.fulfilled,
        (state, { payload }: PayloadAction<any | { error: string }>) => {
          const user: any = { ...state.user }
          const enrollments = [...user.enrollments]
          const enrollment = payload.enrollment
          const idx = enrollments.findIndex((enroll: any) => enroll.course === enrollment.course)
          enrollments.splice(idx, 1, enrollment)
          user.enrollments = enrollments
          state.user = user
          state.currentSubLesson = payload.currentSubLesson
          LocalStorage.storeCurrentUser(user)
        },
      )
  },
})

export const { resetAuthenticationState, resetErrorState, setCurrentCourse, setCurrentSubLesson } =
  authenticationSlice.actions
export default authenticationSlice.reducer
