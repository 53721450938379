import React from 'react'
import { IconProps } from '../types'

const ArrowRight = ({ size = 24, color = 'currentColor', stroke = 2, ...props }: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      stroke={`${stroke}`}
      viewBox='0 0 24 24'
      fill='none'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.3515 3.95148C12.8201 3.48285 13.5799 3.48285 14.0485 3.95148L21.2485 11.1515C21.7172 11.6201 21.7172 12.3799 21.2485 12.8485L14.0485 20.0485C13.5799 20.5172 12.8201 20.5172 12.3515 20.0485C11.8828 19.5799 11.8828 18.8201 12.3515 18.3515L17.5029 13.2L3.6 13.2C2.93726 13.2 2.4 12.6627 2.4 12C2.4 11.3373 2.93726 10.8 3.6 10.8H17.5029L12.3515 5.64853C11.8828 5.17991 11.8828 4.42011 12.3515 3.95148Z'
        fill={color}
      />
    </svg>
  )
}

export default ArrowRight
