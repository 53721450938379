import { obi, yaya, randy, fien, selma, godlove, leonard } from '../assets/images'

export interface IInstructor {
  name: string
  image: string
  description: string
  position: { top: number; left: number; width?: number }
}

export const instructors: Array<IInstructor> = [
  {
    name: 'Emmanuel Obi',
    description: 'Python Tutor',
    image: obi,
    position: { top: 2, left: 43 },
  },

  {
    name: 'Ngumih Fien',
    description: 'UI/UX Design Tutor',
    image: fien,
    position: { top: 25, left: 30, width: 5 },
  },
  {
    name: 'Selma Ndi',
    description: 'Full Stack Tutor',
    image: selma,
    position: { top: 25, left: 58, width: 5 },
  },

  {
    name: 'Atem Randy',
    description: 'Full Stack Tutor',
    image: randy,
    position: { top: 40, left: 5 },
  },
  {
    name: 'Mamoudou Yaya',
    description: 'Full Stack Tutor',
    image: yaya,
    position: { top: 40, left: 76.5 },
  },
  {
    name: 'Wandinga Leonard',
    description: 'Full Stack Tutor',
    image: leonard,
    position: { top: 68, left: 27 },
  },
  {
    name: 'Ayeah Godlove',
    description: 'Full Stack Tutor',
    image: godlove,
    position: { top: 80, left: 55 },
  },
]
