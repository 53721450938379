import React, { useRef } from 'react'
import { Link, Outlet, useNavigate } from 'react-router-dom'

// stylesheet
import styles from './profile.module.css'

// general imports
import { defaultImage } from '../../../repository/assets'

import SidePanel from './modules/side-panel/SidePanel.module'
import { useAppSelector } from '../../../lib/hooks'

export const ProfileLinks = [
  {
    label: 'Profile Settings',
    path: '/dashboard/profile',
    index: 0,
  },
  // {
  //   label: 'Notifications',
  //   path: 'notifications',
  //   index: 2,
  // },
  {
    label: 'Password',
    path: 'password',
    index: 2,
  },
  {
    label: 'Account',
    path: 'account',
    index: 3,
  },
]

const Profile = () => {
  const authState = useAppSelector((state) => state.authentication)
  const { user } = authState

  const activeTab = useRef<number>(0)
  const navigate = useNavigate()

  const handleTabChange = (
    e: React.MouseEvent<HTMLAnchorElement>,
    route: string,
    index: number,
  ) => {
    e.preventDefault()

    activeTab.current = index
    navigate(route)
  }

  return (
    <div className={styles.profile}>
      <div className={styles.sidebar}>
        <SidePanel
          image={defaultImage}
          name={authState.user?.first_name as string}
          email={authState.user?.email as string}
          enrolledCourses={user?.enrollments?.length || 0}
          completedCourses={
            user?.enrollments?.reduce((prev, curr) => {
              if ((curr.percentageCompleted || 0) >= 100) {
                return prev + 1
              } else {
                return prev
              }
            }, 0) || 0
          }
          learningTime='12AM'
          dueAssessments={15}
          completedAssessments={9}
        />
      </div>

      <div className={styles.modules__tabs}>
        <h2 className={styles.title}>Settings</h2>
        <div className={styles.switch__tabs}>
          {ProfileLinks.length > 0 &&
            ProfileLinks.map((profileLink, index) => {
              return (
                <div
                  key={index}
                  className={`${styles.tab} ${
                    activeTab.current === profileLink.index ? styles.active : ''
                  }`}
                >
                  <Link
                    onClick={(e) => {
                      handleTabChange(e, profileLink.path, profileLink.index)
                    }}
                    to={profileLink.path}
                  >
                    {profileLink.label}
                  </Link>
                </div>
              )
            })}
        </div>

        {/* <div className={styles.upload}>
          <Upload />
        </div> */}

        <div className={styles.modules}>
          <div className={styles.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile
