import React from 'react'
import { Link } from 'react-router-dom'

// stylesheet
import styles from './crumb.module.css'

type Props = {
  links: {
    label: string
    path: string
  }[]
  activeCrumb: number
}

const BreadCrumb: React.FC<Props> = ({ links, activeCrumb }) => {
  return (
    <div className={styles.crumb}>
      {links.length > 0 &&
        links.map((data, index) => {
          return (
            <Link
              className={`${activeCrumb === index ? styles.active : ''}`}
              key={index}
              to={data.path}
            >
              {data.label + (index !== links.length - 1 ? ' / ' : '')}
            </Link>
          )
        })}
    </div>
  )
}

export default BreadCrumb
