import React from 'react'
import { IconProps } from '../types'

const CheckCircleIcon = ({ size = 25, stroke = 2, color = 'white', ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      stroke={`${stroke}`}
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M12.5 2.5C6.98 2.5 2.5 6.98 2.5 12.5C2.5 18.02 6.98 22.5 12.5 22.5C18.02 22.5 22.5 18.02 22.5 12.5C22.5 6.98 18.02 2.5 12.5 2.5ZM12.5 20.5C8.09 20.5 4.5 16.91 4.5 12.5C4.5 8.09 8.09 4.5 12.5 4.5C16.91 4.5 20.5 8.09 20.5 12.5C20.5 16.91 16.91 20.5 12.5 20.5ZM17.09 8.08L10.5 14.67L7.91 12.09L6.5 13.5L10.5 17.5L18.5 9.5L17.09 8.08Z'
        fill={color}
      />
    </svg>
  )
}

export default CheckCircleIcon
