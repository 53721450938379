import { IconProps } from '../types'

const RocketLaunchIcon = ({
  width = 20,
  height = 21,
  color = 'currentColor',
  stroke = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      stroke={`${stroke}`}
      viewBox='0 0 20 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M4.00049 13.7365C3.17049 13.7365 2.42049 14.0765 1.88049 14.6165C0.700488 15.7965 0.000488281 20.7365 0.000488281 20.7365C0.000488281 20.7365 4.94049 20.0365 6.12049 18.8565C6.66049 18.3165 7.00049 17.5665 7.00049 16.7365C7.00049 15.0765 5.66049 13.7365 4.00049 13.7365ZM4.71049 17.4465C4.43049 17.7265 2.54049 18.2065 2.54049 18.2065C2.54049 18.2065 3.01049 16.3265 3.30049 16.0365C3.47049 15.8465 3.72049 15.7365 4.00049 15.7365C4.55049 15.7365 5.00049 16.1865 5.00049 16.7365C5.00049 17.0165 4.89049 17.2665 4.71049 17.4465ZM15.4205 12.3865C21.7805 6.0265 19.6605 1.0765 19.6605 1.0765C19.6605 1.0765 14.7105 -1.0435 8.35049 5.3165L5.86049 4.8165C5.21049 4.6865 4.53049 4.89651 4.05049 5.36651L0.000488281 9.4265L5.00049 11.5665L9.17049 15.7365L11.3105 20.7365L15.3605 16.6865C15.8305 16.2165 16.0405 15.5365 15.9105 14.8765L15.4205 12.3865ZM5.41049 9.5665L3.50049 8.7465L5.47049 6.7765L6.91049 7.0665C6.34049 7.8965 5.83049 8.7665 5.41049 9.5665ZM11.9905 17.2365L11.1705 15.3265C11.9705 14.9065 12.8405 14.3965 13.6605 13.8265L13.9505 15.2665L11.9905 17.2365ZM14.0005 10.9765C12.6805 12.2965 10.6205 13.3765 9.96049 13.7065L7.03049 10.7765C7.35049 10.1265 8.43049 8.0665 9.76049 6.73651C14.4405 2.05651 17.9905 2.74651 17.9905 2.74651C17.9905 2.74651 18.6805 6.2965 14.0005 10.9765ZM13.0005 9.7365C14.1005 9.7365 15.0005 8.8365 15.0005 7.73651C15.0005 6.63651 14.1005 5.73651 13.0005 5.73651C11.9005 5.73651 11.0005 6.63651 11.0005 7.73651C11.0005 8.8365 11.9005 9.7365 13.0005 9.7365Z'
        fill={color}
      />
    </svg>
  )
}

export default RocketLaunchIcon
