import React, { useState, useRef, useEffect, Fragment } from 'react'
import { motion } from 'framer-motion'

// stylesheet
import styles from './swiper.module.css'

export interface SwiperProps {
  children?: React.ReactNode[]
  singleSlideWidth: string
  gap?: string
  p?: boolean
  padding?: string
}

const Swiper: React.FC<SwiperProps> = ({ singleSlideWidth, gap, children, p, padding }) => {
  const [width, setWidth] = useState(0)
  const carousel = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (carousel.current !== null) {
      setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth)
    }
  }, [children])

  return (
    <div className={styles.container}>
      <motion.div ref={carousel} className={styles.carousel__wrapper}>
        <motion.div
          drag='x'
          dragConstraints={{ right: 0, left: -width, top: 0, bottom: 0 }}
          dragTransition={{ bounceStiffness: 600, bounceDamping: 20 }}
          className={`${styles.inner__carousel} ${p ? styles.p : ''} ${p}`}
          whileTap={{ cursor: 'grabbing' }}
          style={{
            gap: gap ? gap : '0.6rem',
            padding: padding ? padding : null,
          }}
        >
          {children !== undefined &&
            children.map((child: React.ReactNode, i: number) => {
              return (
                <Fragment key={i}>
                  <motion.div
                    style={{ minWidth: `${singleSlideWidth}`, maxWidth: `${singleSlideWidth}` }}
                    className={styles.carousel__item}
                  >
                    {child}
                  </motion.div>
                </Fragment>
              )
            })}
        </motion.div>
      </motion.div>
    </div>
  )
}

export default Swiper
