import React from 'react'
import { IconProps } from '../types'

const SearchIcon = ({ size = 16, color = 'currentColor', stroke = 2, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      stroke={`${stroke}`}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M11.1296 9.87916H10.4712L10.2379 9.65416C11.0546 8.70416 11.5462 7.47083 11.5462 6.12916C11.5462 3.13749 9.12122 0.712494 6.12956 0.712494C3.13789 0.712494 0.712891 3.13749 0.712891 6.12916C0.712891 9.12083 3.13789 11.5458 6.12956 11.5458C7.47122 11.5458 8.70456 11.0542 9.65456 10.2375L9.87956 10.4708V11.1292L14.0462 15.2875L15.2879 14.0458L11.1296 9.87916ZM6.12956 9.87916C4.05456 9.87916 2.37956 8.20416 2.37956 6.12916C2.37956 4.05416 4.05456 2.37916 6.12956 2.37916C8.20456 2.37916 9.87956 4.05416 9.87956 6.12916C9.87956 8.20416 8.20456 9.87916 6.12956 9.87916Z'
        fill={color}
      />
    </svg>
  )
}

export default SearchIcon
