import React from 'react'
import { IconProps } from '../types'

const ActiveRadioButtonIcon = ({ size = 40, stroke = 2, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      stroke={`${stroke}`}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M20 10C14.48 10 10 14.48 10 20C10 25.52 14.48 30 20 30C25.52 30 30 25.52 30 20C30 14.48 25.52 10 20 10ZM20 28C15.58 28 12 24.42 12 20C12 15.58 15.58 12 20 12C24.42 12 28 15.58 28 20C28 24.42 24.42 28 20 28Z'
        fill='var(--platform-secondary-700)'
      />
      <path
        d='M20 25C22.7614 25 25 22.7614 25 20C25 17.2386 22.7614 15 20 15C17.2386 15 15 17.2386 15 20C15 22.7614 17.2386 25 20 25Z'
        fill='var(--platform-secondary-700)'
      />
      <path d='M39 0V40H41V0H39Z' fill='#DBB1ED' mask='url(#path-1-inside-1_1488_4423)' />
    </svg>
  )
}

export default ActiveRadioButtonIcon
