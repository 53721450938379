import React from 'react'

// general imports
import { useAppSelector } from '../../lib/hooks'
import NavbarLoggedin from '../organisms/navbar/NavbarLoggedin.organism'
import { defaultImage } from '../../repository/assets'
import Navbar from '../organisms/navbar/Navbar.organism'
import Footer from '../organisms/footer/Footer.organism'
import useWindowSize from '../../lib/hooks/useWindowSize'

type Props = {
  children: React.ReactNode
  showFooter?: boolean
  padding?: string
  isError?: boolean
}

const DefaultLayout = ({ children, showFooter = true, padding = '15rem', isError }: Props) => {
  const authState = useAppSelector((state) => state.authentication)
  const width = useWindowSize().width

  return (
    <div>
      {authState.user && authState.token && authState.user.verified ? (
        <>
          <NavbarLoggedin padding='32px var(--px)' userImage={defaultImage} />
          <div style={{ height: width > 620 ? '15rem' : '15rem' }}></div>
        </>
      ) : (
        <>
          <Navbar isError={isError} padding='32px var(--px)' />
          <div style={{ height: width > 620 ? '15rem' : '12rem' }} />
        </>
      )}
      <div
        style={{
          paddingTop: padding,
        }}
      >
        {children}
      </div>
      {showFooter ? <Footer /> : <></>}
    </div>
  )
}

export default DefaultLayout
