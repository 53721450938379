import React from 'react'

import styles from './about.module.css'

import LessonAccordion from '../lesson-accordion/LessonAccordion.template'
import Button from '../../../molecules/button/button.molecule'
import { ButtonTypes } from '../../../atoms/enums'
import { IdName } from '../../../../types'
import { appPaths } from '../../../../constants/app-paths'
import { Link } from 'react-router-dom'
import formatNumber from '../../../../lib/utils/formatNumber'

export type CourseLessonType = {
  lessons: IdName[]
  price: number
}

const CourseAbout: React.FC<CourseLessonType> = ({ lessons, price }) => {
  // const videoRef = useRef<HTMLVideoElement | null>(null)
  // const [isPlaying, setPlaying] = useState(false)

  // const playPause = () => {
  //   if (isPlaying) {
  //     videoRef.current?.pause()
  //     setPlaying(false)
  //   } else {
  //     videoRef.current?.play()
  //     setPlaying(true)
  //   }
  // }

  return (
    <section className={styles.body} id='about'>
      <h1 className={styles.title}>About this course</h1>
      <div className={styles.content}>
        {/* <div className={styles.video}>
          <video ref={videoRef} loop>
            <source src={courseBackground} type={'video/webm'} />
          </video>
          <span className={isPlaying ? styles.hide : ''} onClick={playPause}>
            {isPlaying ? (
              <IconsRepository.PauseCircleFilled size={65} height={70} />
            ) : (
              <IconsRepository.PlayCircleFilledIcon size={65} height={70} />
            )}
          </span>
        </div> */}
        <aside className={styles.details}>
          {/* <div role={'button'} tabIndex={0} className={styles.trailer} onClick={playPause}>
            <span>
              <IconsRepository.PlayCircleFilledIcon size={18} />
            </span>
            Class Trailer
          </div> */}
          <h5>Our Lesson Plan</h5>
          <div className={styles.plan}>
            <LessonAccordion lessons={lessons} />
          </div>
        </aside>
      </div>
      <div className={styles.button}>
        <Link to={appPaths.REGISTER}>
          <Button
            type={ButtonTypes.PRIMARY}
            text={
              <span className='price_span'>
                <span>Enroll Now for</span>
                {formatNumber(price)}frs
              </span>
            }
            fontSize='16px'
            // className={styles.custom}
          />
        </Link>
      </div>
    </section>
  )
}

export default CourseAbout
