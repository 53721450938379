export interface ILessonPlan {
  chapter?: string
  topics?: Array<{
    topic: string
    length: number
  }>
}

export const lessonPlan: Array<ILessonPlan> = [
  {
    chapter: 'Dooleys Toffee',
    topics: [
      {
        topic: 'GCC',
        length: 440,
      },
      {
        topic: 'IGMP Snooping',
        length: 151,
      },
      {
        topic: 'PL/SQL',
        length: 365,
      },
      {
        topic: 'SSCP',
        length: 139,
      },
      {
        topic: 'PCP',
        length: 160,
      },
    ],
  },
  {
    chapter: 'Cheese - Gorgonzola',
    topics: [
      {
        topic: 'MFS',
        length: 527,
      },
      {
        topic: 'Guardianship',
        length: 536,
      },
      {
        topic: 'Core Banking',
        length: 494,
      },
    ],
  },
  {
    chapter: 'Plate - Foam, Bread And Butter',
    topics: [
      {
        topic: 'Japanese Business Culture',
        length: 270,
      },
      {
        topic: 'GT Strudl',
        length: 471,
      },
      {
        topic: 'Rational XDE',
        length: 385,
      },
      {
        topic: 'Interviews',
        length: 118,
      },
    ],
  },
  {
    chapter: 'Jerusalem Artichoke',
    topics: [
      {
        topic: 'Characterization',
        length: 345,
      },
      {
        topic: 'Voiceovers',
        length: 327,
      },
      {
        topic: 'Upstream',
        length: 382,
      },
    ],
  },
  {
    chapter: 'Veal - Round, Eye Of',
    topics: [
      {
        topic: 'Commercial Litigation',
        length: 493,
      },
      {
        topic: 'Equities',
        length: 389,
      },
      {
        topic: 'Farms',
        length: 237,
      },
      {
        topic: 'WFA',
        length: 303,
      },
    ],
  },
  {
    chapter: 'Lamb - Leg, Bone In',
    topics: [
      {
        topic: 'Validation',
        length: 358,
      },
      {
        topic: 'NDK',
        length: 310,
      },
      {
        topic: 'BMC Remedy AR System',
        length: 319,
      },
      {
        topic: 'AV Integration',
        length: 351,
      },
      {
        topic: 'Instructional Design',
        length: 269,
      },
    ],
  },
  {
    chapter: 'Yeast Dry - Fleischman',
    topics: [
      {
        topic: 'EGL',
        length: 333,
      },
      {
        topic: 'GUI',
        length: 179,
      },
      {
        topic: 'IBM Certified',
        length: 555,
      },
      {
        topic: 'cXML',
        length: 292,
      },
      {
        topic: 'European Law',
        length: 593,
      },
    ],
  },
  {
    chapter: 'Mangoes',
    topics: [
      {
        topic: 'xPON',
        length: 186,
      },
      {
        topic: 'WSIB',
        length: 428,
      },
      {
        topic: 'EEG',
        length: 248,
      },
    ],
  },
  {
    chapter: 'Juice - Clam, 46 Oz',
    topics: [
      {
        topic: 'RMP',
        length: 154,
      },
      {
        topic: 'HP Storage',
        length: 247,
      },
      {
        topic: 'Pilates',
        length: 144,
      },
    ],
  },
  {
    chapter: 'Cabbage - Green',
    topics: [
      {
        topic: 'JVM',
        length: 457,
      },
      {
        topic: 'LDRA',
        length: 227,
      },
      {
        topic: 'Wholesale Lending',
        length: 367,
      },
      {
        topic: 'DC-9',
        length: 143,
      },
      {
        topic: 'Gmail',
        length: 133,
      },
    ],
  },
  {
    chapter: 'Chestnuts - Whole,canned',
    topics: [
      {
        topic: 'NRP',
        length: 331,
      },
      {
        topic: 'Petroleum Engineering',
        length: 572,
      },
      {
        topic: 'Lunix',
        length: 409,
      },
      {
        topic: 'Brokerage',
        length: 474,
      },
      {
        topic: 'Human Rights',
        length: 441,
      },
    ],
  },
  {
    chapter: 'Coconut - Creamed, Pure',
    topics: [
      {
        topic: 'Inventory Management',
        length: 388,
      },
      {
        topic: 'Jazz',
        length: 146,
      },
      {
        topic: 'Ultrasound Therapy',
        length: 133,
      },
      {
        topic: 'PDH',
        length: 544,
      },
      {
        topic: 'AHU',
        length: 116,
      },
    ],
  },
]
