/* eslint-disable */

import { APIError, InternalError } from '../../constants/configs'

export const getExceptionPayload = (
  ex: unknown,
):
  | APIError
  | {
      error: string
      code: number
    } => {
  console.log({ ex })

  if (typeof ex !== 'object' || !ex) {
    return InternalError
  }

  const typeofException = ex as APIError

  if (
    ex.hasOwnProperty('response') &&
    typeof typeofException.response.data.error === 'string' &&
    ex.hasOwnProperty('code') &&
    typeof typeofException.response.status === 'number'
  ) {
    return {
      error: typeofException.response.data.error,
      code: typeofException.response.status,
    }
  }

  return InternalError
}
