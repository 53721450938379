import React from 'react'
import { IconProps } from '../types'

const BlueRightIcon = ({ size = 22, color = 'currentColor' }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.9997 0.642822L9.11967 2.52282L16.5597 9.97616H0.333008V12.6428H16.5597L9.11967 20.0962L10.9997 21.9762L21.6663 11.3095L10.9997 0.642822Z'
        fill={color}
      />
    </svg>
  )
}

export default BlueRightIcon
