import { ReactElement } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { GuardFunc, AppRouteType } from '../../types'
import { appPaths } from '../../constants/app-paths'
import { useAppSelector } from '../../lib/hooks/index'

function GuardController(props: { route: AppRouteType }) {
  const { route } = props
  // eslint-disable-next-line
  let Component: any = <route.component />

  if (route.guards && route.guards.length > 0) {
    const caughtByGuard = 'guard-caught'
    try {
      route.guards.forEach((guard: GuardFunc) => {
        if (typeof guard === 'function') {
          const g = guard({ state: useAppSelector((state) => state), location: useLocation() })
          if (typeof g === 'string' && window.location.pathname !== g) {
            Component = <Navigate replace to={g} />
          } else if (typeof g === 'string' && window.location.pathname === g) {
            return Component
          } else if (typeof g === 'boolean' && !g) {
            Component = <Navigate replace to={appPaths.LOGIN} />
          }
        }
      })
      // eslint-disable-next-line
    } catch (e: any) {
      if (e?.message !== caughtByGuard) {
        throw e
      }
    }
  }

  // eslint-disable-next-line
  return Component as ReactElement<any, any>
}

export default GuardController
