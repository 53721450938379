import React from 'react'
import { Link } from 'react-router-dom'
import { ButtonTypes } from '../../atoms/enums'
import Button from '../../molecules/button/button.molecule'

import styles from './blocks.module.css'

const Block4: React.FC = () => {
  return (
    <div className={styles.container}>
      <h3>Password Reset</h3>
      <p style={{ marginBottom: '2.7rem' }}>
        You have successfully reset your password. You will be redirected to dashboard.
      </p>

      <div style={{ width: '100%', marginTop: '2.6rem' }}>
        <Link to='/dashboard'>
          <Button
            type={ButtonTypes.PRIMARY}
            fontSize='16px'
            text={'Continue'}
            width='100%'
            height='48px'
          />
        </Link>
      </div>
    </div>
  )
}

export default Block4
