import React from 'react'

import { IconProps } from '../types'

const TextIncreaseIcon = ({
  size = 24,
  color = 'currentColor',
  stroke = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      stroke={`${stroke}`}
      viewBox='0 0 16 16'
      fill='none'
      {...props}
    >
      <path
        d='M0.663086 12.6667H2.27642L3.12309 10.28H6.88975L7.72975 12.6667H9.34309L5.83642 3.33334H4.16975L0.663086 12.6667ZM3.60975 8.92667L4.96309 5.06667H5.04309L6.39642 8.92667H3.60975ZM13.3364 7.33334H15.3364V8.66667H13.3364V10.6667H12.0031V8.66667H10.0031V7.33334H12.0031V5.33334H13.3364V7.33334Z'
        fill={color}
      />
    </svg>
  )
}

export default TextIncreaseIcon
