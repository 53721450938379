import React from 'react'
import { IconProps } from '../types'

const GlobeIcon = ({
  size = 16,
  height = 17,
  color = 'currentColor',
  stroke = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      width={size}
      height={height}
      stroke={`${stroke}`}
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M7.99301 1.56479C4.31301 1.56479 1.33301 4.55146 1.33301 8.23146C1.33301 11.9115 4.31301 14.8981 7.99301 14.8981C11.6797 14.8981 14.6663 11.9115 14.6663 8.23146C14.6663 4.55146 11.6797 1.56479 7.99301 1.56479ZM12.613 5.56479H10.6463C10.433 4.73146 10.1263 3.93146 9.72634 3.19146C10.953 3.61146 11.973 4.46479 12.613 5.56479ZM7.99967 2.92479C8.55301 3.72479 8.98634 4.61146 9.27301 5.56479H6.72634C7.01301 4.61146 7.44634 3.72479 7.99967 2.92479ZM2.83967 9.56479C2.73301 9.13812 2.66634 8.69146 2.66634 8.23146C2.66634 7.77146 2.73301 7.32479 2.83967 6.89812H5.09301C5.03967 7.33812 4.99967 7.77812 4.99967 8.23146C4.99967 8.68479 5.03967 9.12479 5.09301 9.56479H2.83967ZM3.38634 10.8981H5.35301C5.56634 11.7315 5.87301 12.5315 6.27301 13.2715C5.04634 12.8515 4.02634 12.0048 3.38634 10.8981ZM5.35301 5.56479H3.38634C4.02634 4.45812 5.04634 3.61146 6.27301 3.19146C5.87301 3.93146 5.56634 4.73146 5.35301 5.56479ZM7.99967 13.5381C7.44634 12.7381 7.01301 11.8515 6.72634 10.8981H9.27301C8.98634 11.8515 8.55301 12.7381 7.99967 13.5381ZM9.55968 9.56479H6.43967C6.37967 9.12479 6.33301 8.68479 6.33301 8.23146C6.33301 7.77812 6.37967 7.33146 6.43967 6.89812H9.55968C9.61967 7.33146 9.66634 7.77812 9.66634 8.23146C9.66634 8.68479 9.61967 9.12479 9.55968 9.56479ZM9.72634 13.2715C10.1263 12.5315 10.433 11.7315 10.6463 10.8981H12.613C11.973 11.9981 10.953 12.8515 9.72634 13.2715ZM10.9063 9.56479C10.9597 9.12479 10.9997 8.68479 10.9997 8.23146C10.9997 7.77812 10.9597 7.33812 10.9063 6.89812H13.1597C13.2663 7.32479 13.333 7.77146 13.333 8.23146C13.333 8.69146 13.2663 9.13812 13.1597 9.56479H10.9063Z'
        fill={color}
      />
    </svg>
  )
}

export default GlobeIcon
