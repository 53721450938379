/* eslint-disable */

import React, { useState, Fragment, useEffect } from 'react'

// stylesheet
import styles from './allcourses.module.css'

// general imports
import FilterController from '../../components/molecules/filter-sort/filter-controller/FilterController.molecule'
import SortController from '../../components/molecules/filter-sort/sort-controller/SortController.molecule'

import FilterComponent from '../../components/organisms/filter/FilterComponent.organism'
import options from '../../repository/data/all-courses-data'
import CourseCard from '../../components/organisms/cards/course-card/CourseCard.organism'

// import { courseThunk } from '../../features/course/thunks/CourseThunk'
// import { useAppDispatch, useAppSelector } from '../../lib/hooks'
import Navbar from '../../components/organisms/navbar/Navbar.organism'
import { useAppDispatch, useAppSelector } from '../../lib/hooks'
import { courseThunk } from '../../features/course/thunks/course.thunk'
import { defaultImage } from '../../repository/assets'
import NavbarLoggedin from '../../components/organisms/navbar/NavbarLoggedin.organism'
import { CourseEntityType } from '../../types/models'

type sortType = 'newest' | 'popular' | 'rated' | 'name'
const AllCourses = () => {
  const dispatch = useAppDispatch()

  const { courses, isLoading } = useAppSelector((state) => state.course)
  const authState = useAppSelector((state) => state.authentication)
  const [sort, setSort] = useState<sortType>('newest')
  const [showSort, setShowSort] = useState<boolean>(false)
  const [filterActive, setFilterActive] = useState<boolean>(false)

  const [selectionState, setSelectionState] = useState<{ [key: string]: string[] }>({})
  const [subSelectionState, setSubSelectionState] = useState<{ [key: string]: string[] }>({})
  const [filteredCourses, setFilteredCourses] = useState<typeof courses>(courses)
  const [optionsData, setOptionsData] = useState<
    Record<string, Array<{ key: number; value: string }>>
  >({})

  useEffect(() => {
    if (!courses || courses.length === 0) {
      dispatch(courseThunk())
    }
  }, [])

  useEffect(() => {
    const opts: typeof optionsData = { ...options }
    delete opts.category
    if (courses && courses.length > 0) {
      const cats = new Set()
      courses.forEach((course) => {
        course.categories?.forEach((c) => cats.add(c.name))
      })
      const categories = [...Array.from(cats)].map((c, i) => ({
        key: i + 1,
        value: c as string,
      }))
      opts.category = categories
    }
    setOptionsData(opts)
  }, [courses])

  /**
   * Toggle sorting or filtering
   * @param str string
   */
  const toggleFilterSort = (str: string) => {
    if (str === 'filter') {
      setFilterActive(!filterActive)
    } else {
      setShowSort(!showSort)
    }
  }

  useEffect(() => {
    const _filtered = filterCourses(courses)
    const sorted = sortCourses(_filtered)
    setFilteredCourses(sorted)
  }, [courses, selectionState, sort])

  const filterCourses = (courses: Array<CourseEntityType>) => {
    if (Object.values(selectionState).every((arr) => arr.length === 0)) {
      return courses
    }

    return courses.filter((course) => {
      const courseLevels = course.level
      const courseLanguage = course.language || []
      const courseTech = course.categories?.map((cat) => cat.name)

      const levelMatch =
        selectionState.level.length === 0 ||
        selectionState.level.some((lvl) => courseLevels?.includes(lvl))
      const languageMatch =
        selectionState.language.length === 0 ||
        selectionState.language.some((lang) => courseLanguage?.includes(lang))
      const techMatch =
        selectionState.category.length === 0 ||
        selectionState.category.some((cat) => courseTech?.includes(cat))

      return levelMatch && languageMatch && techMatch
    })
  }

  const sortCourses = (courses: Array<CourseEntityType>) => {
    let sortedCourses: Array<CourseEntityType> = [...courses]
    switch (sort) {
      case 'newest':
        sortedCourses.sort((a, b) => {
          return +new Date(b.createdAt!) - +new Date(a.createdAt!)
        })
        break
      case 'popular':
        sortedCourses = courses
        break
      case 'rated':
        sortedCourses.sort((a, b) => {
          return (b.rating || 0) - (a.rating || 0)
        })
        break
      case 'name':
        sortedCourses.sort((a, b) => {
          return a.name?.localeCompare(b.name!) || 0
        })
        break
      default:
        sortedCourses = courses
    }

    return sortedCourses
  }

  const changeSort = (sort: sortType) => {
    setSort(sort)
    setShowSort(false)
  }

  return (
    <>
      {authState.user && authState.user.verified ? (
        <NavbarLoggedin padding='32px' userImage={defaultImage} />
      ) : (
        <Navbar />
      )}
      <div
        style={{
          padding: authState.user ? '32px' : '32px var(--px)',
          paddingTop: '13rem',
        }}
        className={styles.all__courses}
      >
        <div className={styles.head}>
          <h2>{authState.user ? 'Explore our courses' : 'Explore our curated courses'}</h2>
          <h3>Use the filter and sort to find your ideal course.</h3>
        </div>

        <div className={styles.filter__sort}>
          <div className={styles.filter}>
            <FilterController
              event={filterActive ? 'Hide Filter' : 'Filter'}
              action={toggleFilterSort}
            />
          </div>
          <div className={styles.sort}>
            <SortController event='Sort' action={toggleFilterSort} />
            {showSort ? (
              <div className={styles.sort__drop}>
                <button
                  className={sort === 'newest' ? styles.sortActive : ''}
                  onClick={() => changeSort('newest')}
                >
                  Newest
                </button>
                <button
                  className={sort === 'popular' ? styles.sortActive : ''}
                  onClick={() => changeSort('popular')}
                >
                  Most Popular
                </button>
                <button
                  className={sort === 'rated' ? styles.sortActive : ''}
                  onClick={() => changeSort('rated')}
                >
                  Highest Rated
                </button>
                <button
                  className={sort === 'name' ? styles.sortActive : ''}
                  onClick={() => changeSort('name')}
                >
                  Name
                </button>
              </div>
            ) : null}
          </div>
        </div>

        <div className={styles.wrapper__abstract}>
          <div className={styles.courses__filter}>
            <div className={`${styles.filter__options} ${filterActive ? '' : styles.hide}`}>
              <FilterComponent
                active={filterActive}
                setSubSelections={setSubSelectionState}
                optionsData={optionsData}
                setSelections={setSelectionState}
              />
            </div>
            {isLoading ? (
              <div
                className={`${styles.courses__gallery} ${
                  filterActive ? styles.overlay : styles.full
                }`}
              >
                {Array(16)
                  .fill(0)
                  .map((_course, index) => {
                    return (
                      <Fragment key={index}>
                        <CourseCard isLoading={true} cover={undefined} name={undefined} />
                      </Fragment>
                    )
                  })}
              </div>
            ) : null}
            {filteredCourses !== undefined && !isLoading && filteredCourses.length > 0 && (
              <div
                className={`${styles.courses__gallery} ${
                  filterActive ? styles.overlay : styles.full
                }`}
              >
                {filteredCourses.map((course, index) => {
                  return (
                    <Fragment key={course.id}>
                      <CourseCard
                        isLoading={false}
                        cover={course.cover}
                        name={course.name}
                        description={course.description}
                        author={course.author}
                        language={course.language}
                        lessons={course.lessons}
                        duration={440}
                        id={course.id}
                        level={course.level}
                      />
                    </Fragment>
                  )
                })}
              </div>
            )}
          </div>
        </div>

        <div className={styles.epsilon__ele}></div>
      </div>
    </>
  )
}

export default AllCourses
