import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import ImageContainer from '../../../components/molecules/image-container/ImageContainer.module'
import Block3 from '../../../components/templates/email-verification/Block3.template'
import { appPaths } from '../../../constants/app-paths'
import { emailVerificationImage } from '../../../repository/assets'
import { IconsRepository } from '../../../repository/icons/icon.repository'

import styles from '../login/login.module.css'
import emailverificationstyles from './emailverification.module.css'
import { useAppSelector } from '../../../lib/hooks'

const VerificationConfirmation = () => {
  // const [block, setBlock] = useState<number>(0)

  // const [user] = useAuthState(auth)
  const { user } = useAppSelector((state) => state.authentication)
  const navigate = useNavigate()

  useEffect(() => {
    if (user?.verified) {
      navigate(appPaths.DASHBOARD)
    }
  }, [user])

  return (
    <div className={styles.parent__div}>
      <div className={styles.input__div}>
        <div className={emailverificationstyles.logo__styles}>
          <Link to='/'>
            <IconsRepository.LogoIcon />
          </Link>
        </div>
        <div className={styles.content}>
          <Block3 />
          <div className={styles.form__control}>
            <h4>
              <span className={emailverificationstyles.link}>
                <Link to={appPaths.LOGIN} reloadDocument>
                  <IconsRepository.ArrowLeftIcon />
                  <p> Back to login</p>
                </Link>
              </span>
            </h4>
          </div>
        </div>
      </div>
      <div className={styles.img__container}>
        <ImageContainer
          image={
            <img
              src={emailVerificationImage}
              alt='image'
              width={466}
              height={456}
              style={
                {
                  // objectFit: 'cover',
                }
              }
            />
          }
        />
      </div>
    </div>
  )
}

export default VerificationConfirmation
