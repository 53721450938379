/* eslint-disable */
import React, { useMemo } from 'react'
import BreadCrumb from '../bread-crumb/BreadCrumb.molecule'
import { IconsRepository } from '../../../repository/icons/icon.repository'

import styles from './lessonnavigator.module.css'
import { CourseEntityType } from '../../../types/models'
import { IdName } from '../../../types'
import useWindowSize from '../../../lib/hooks/useWindowSize'
import { useNavigate, useParams } from 'react-router-dom'

type Props = {
  course?: CourseEntityType
  full?: boolean
  name?: string
  lessonTracker?: number
  subLessonTracker?: number
  setLessonTracker?: React.Dispatch<React.SetStateAction<number>>
  setSubLessonTracker?: React.Dispatch<React.SetStateAction<number>>
  hasQuiz: boolean
  hasUserQuiz: boolean
  openModal: () => void
}

const LessonNavigator: React.FC<Props> = ({
  full,
  course,
  lessonTracker,
  subLessonTracker,
  name,
  hasQuiz,
  hasUserQuiz,
  openModal,
}) => {
  const navigate = useNavigate()
  const { courseID, lessonID, subLessonID } = useParams()
  const { width } = useWindowSize()

  const previous = () => {
    let lesson = undefined
    let sublesson = undefined
    if (course !== undefined && course.lessons !== undefined) {
      for (let idx in course.lessons) {
        if (lesson) break
        const lsn = course.lessons[idx]
        if (lsn.id === Number(lessonID)) {
          for (let i in lsn.subLessons) {
            const sublsn = lsn.subLessons[Number(i)]
            if (sublsn.id === Number(subLessonID)) {
              if (lsn.subLessons[Number(i) - 1]) {
                lesson = lsn.id!
                sublesson = lsn.subLessons[Number(i) - 1].id!
              } else {
                lesson = course.lessons[Number(idx) - 1].id!
                sublesson = course.lessons[Number(idx) - 1].subLessons?.at(-1)?.id!
              }
              break
            }

            console.log(sublsn.id)
          }
        }
      }

      if (lesson && sublesson) {
        navigate(`/full-course/${courseID}/${lesson}/${sublesson}?repeat=true`)
        navigate(0)
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      } /* else {
        console.log('Not Found')
        console.log({ courseID, lessonID, subLessonID })
      } */
    }
  }

  const isNextDisabled = useMemo(() => {
    let disabled = false

    if (hasQuiz && !hasUserQuiz) {
      disabled = true
    } else if (
      course?.lessons?.length &&
      course?.lessons[lessonTracker as number].subLessons?.length
    ) {
      disabled =
        lessonTracker! + 1 === course.lessons.length &&
        subLessonTracker! + 1 === course?.lessons[lessonTracker as number]?.subLessons?.length
    }

    return disabled
  }, [hasQuiz, hasUserQuiz, lessonTracker, course, subLessonTracker])

  const next = () => {
    if (isNextDisabled) {
      if (hasQuiz && !hasUserQuiz) {
        openModal()
      }
      return
    }
    let lesson = undefined
    let sublesson = undefined
    if (course !== undefined && course.lessons !== undefined) {
      for (let idx in course.lessons) {
        if (lesson) break
        const lsn = course.lessons[idx]
        if (lsn.id === Number(lessonID)) {
          for (let i in lsn.subLessons) {
            // @ts-ignore
            const sublsn = lsn.subLessons[i]
            if (sublsn.id === Number(subLessonID)) {
              if (lsn.subLessons[Number(i) + 1]) {
                lesson = lsn.id!
                sublesson = lsn.subLessons[Number(i) + 1].id!
              } else {
                lesson = course.lessons[Number(idx) + 1].id!
                sublesson = course.lessons[Number(idx) + 1].subLessons?.at(0)?.id!
              }
              break
            }
          }
        }
      }

      if (lesson && sublesson) {
        navigate(`/full-course/${courseID}/${lesson}/${sublesson}`)
        navigate(0)
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      }
    }
  }

  return (
    <div className={styles.top__section}>
      <BreadCrumb
        links={[
          {
            label: 'My Courses',
            path: '/dashboard/my-courses',
          },
          {
            label: name || '',
            path: `/course-preview/${courseID}`,
          },
        ]}
        activeCrumb={1}
      />
      <div
        // style={{
        //   columnGap: full ? '4rem' : '7rem',
        // }}
        className={styles.navigator}
      >
        <div
          onClick={previous}
          className={`${styles.previous} ${
            lessonTracker === 0 && subLessonTracker === 0 ? styles.disabled : ''
          }`}
        >
          <span className={`${styles.icon} ${styles.chevron__left}`}>
            <IconsRepository.SmallChevronIcon
              size={width < 500 ? 16 : 24}
              color='var(--platform-secondary-700)'
            />
          </span>
          <p className={styles.previous__p}>Previous</p>
        </div>
        <div
          onClick={next}
          className={`${styles.next} ${isNextDisabled ? styles.disabled : ''}
          `}
        >
          <p className={styles.next__p}>Next</p>
          <span className={`${styles.icon} ${styles.right__chevron}`}>
            <IconsRepository.SmallChevronIcon
              size={width < 500 ? 16 : 24}
              color='var(--platform-secondary-700)'
            />
          </span>
        </div>
      </div>
    </div>
  )
}

export default LessonNavigator
