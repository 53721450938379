import React, { useState } from 'react'

import styles from './carouselcard.module.css'

// import { video1 } from '../../../../repository/assets/videos'
import { IconsRepository } from '../../../../repository/icons/icon.repository'
import { IdName } from '../../../../types'
import useWindowSize from '../../../../lib/hooks/useWindowSize'
import { courseCover } from '../../../../repository/assets'

type Props = {
  title: string
  descr: string
  time: string
  lessons: IdName[]
  image: string
  categories: IdName[]
}

const CarouselCard: React.FC<Props> = ({ title, descr, lessons, image, categories }) => {
  const width = useWindowSize().width
  const [cover, setCover] = useState(image || courseCover)

  return (
    <section className={styles.body}>
      <div className={styles.content}>
        <div>
          <h2 className={styles.heading}>{title}</h2>
          {descr ? <p className={styles.descr}>{descr}</p> : <></>}
          <span className={styles.icon__wrapper}>
            <span className={styles.cat}>
              <IconsRepository.Category size={width < 500 ? 15 : 20} />
              {categories
                ? categories
                    .map((c) => c.name)
                    .reduce((p, cat) => `${p} ${cat},`, '')
                    ?.slice(0, -1)
                : ''}
            </span>
            <span className={styles.icon}>
              <IconsRepository.PlayCircleFilledIcon
                height={width < 500 ? 16 : 32}
                width={width < 500 ? 16 : 32}
                color='white'
              />{' '}
              <p className={styles.text}> {lessons.length} Lessons </p>
            </span>
          </span>
        </div>
      </div>
      <div className={styles.videoContainer}>
        {/* <VideoBox source={video1} poster={image} /> */}
        <img style={{}} src={cover} alt={title} onError={() => setCover(courseCover)} />
      </div>
    </section>
  )
}

// const VideoBox: React.FC<{ source: string; poster: string }> = ({ source, poster }) => {
//   const vidRef = useRef<HTMLVideoElement | null>(null)

//   useEffect(() => {
//     vidRef.current?.pause()
//     vidRef.current?.poster
//   }, [])

//   const pause = () => {
//     vidRef.current?.pause()
//   }

//   const play = () => {
//     vidRef.current?.play()
//   }

//   return (
//     <div className={styles.video} onMouseEnter={play} onMouseLeave={pause}>
//       <video loop muted ref={vidRef} poster={poster}>
//         <source src={source} type='video/mp4' />
//       </video>
//     </div>
//   )
// }

export default CarouselCard
