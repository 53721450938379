import React from 'react'
import { IconProps } from '../types'

const ChevronRightIcon = ({
  size = 24,
  color = 'currentColor',
  stroke = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      stroke={`${stroke}`}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M2.63164 0.5L0.869141 2.2625L6.59414 8L0.869141 13.7375L2.63164 15.5L10.1316 8L2.63164 0.5Z'
        fill={color}
      />
    </svg>
  )
}

export default ChevronRightIcon
