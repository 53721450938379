/* eslint-disable */

import { createAsyncThunk } from '@reduxjs/toolkit'
import { CourseService, ReviewType } from '../../../services/api/course/Course.service'
import { getExceptionPayload } from '../../../lib/utils/exception-handler'

const courseService = new CourseService()

export const courseThunk = createAsyncThunk('/api/courses', async (_, thunkAPI) => {
  try {
    const response = await courseService.getAllCourses()
    const courses = response
    return courses
  } catch (error: any) {
    console.log('REQUEST_ERROR: ', error)
    return []
  }
})

export const singleCourseThunk = createAsyncThunk(
  '/api/courses/:id',
  async (id: string, thunkAPI) => {
    try {
      return await courseService.getSingleCourse(id)
    } catch (error: any) {
      console.log('REQUEST_ERROR: ', error)
      return {}
    }
  },
)

export const enrollCourseThunk = createAsyncThunk(
  '/api/courses/:id/enroll',
  async (data: { id: string; email: string }, { rejectWithValue }) => {
    try {
      return await courseService.enrollCourse(data)
    } catch (error: any) {
      console.log('REQUEST_ERROR: ', error)
      return rejectWithValue(getExceptionPayload(error))
    }
  },
)

export const courseReviewThunk = createAsyncThunk(
  '/courses/${courseId}/review',
  async (data: { courseId: string; body: ReviewType }, { rejectWithValue }) => {
    try {
      return await courseService.reviewCourse(data.courseId, data.body)
    } catch (error: any) {
      console.log('REQUEST_ERROR: ', error)
      return rejectWithValue(getExceptionPayload(error))
    }
  },
)

export const courseProgressThunk = createAsyncThunk(
  '/api/courses/progress',
  async (
    data: { email: string; courseID: string; lessonID: string; sublessonID: string },
    { rejectWithValue },
  ) => {
    try {
      const response = await courseService.courseProgress(data)
      const enrollment = response.enrollment
      const progess: Array<any> = enrollment?.progress || []

      let currentSubLesson = ''
      const lastUpdate = progess.at(-1)
      currentSubLesson = lastUpdate.subLesson

      return { currentSubLesson, enrollment }
    } catch (error: any) {
      console.log('REQUEST_ERROR: ', error)
      return rejectWithValue(getExceptionPayload(error))
    }
  },
)
