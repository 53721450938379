import styles from './error.module.css'

import DefaultLayout from '../../components/layouts/Default.layout'
import error from '../../repository/assets/videos/error.webm'
// import MaintainanceWheelIcon from '../../icons/MaintainanceWheel.icon'

const NotFound = () => {
  return (
    <DefaultLayout showFooter={false} padding={'0rem'} isError={true}>
      <main className={styles.body}>
        <h1>Page Not Found</h1>
        <p>We couldn&apos;t find the resource you are looking for.</p>
        <video src={error} muted loop autoPlay controls={false} />
        <div className={styles.footer}>
          <p>© 2023 Camsol Technologies. All rights reserved</p>
        </div>
      </main>
    </DefaultLayout>
  )
}

export default NotFound
