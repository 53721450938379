import React from 'react'

import styles from './student.module.css'

import image8 from '../../../../repository/assets/images/students.png'
import Button from '../../../molecules/button/button.molecule'
import { ButtonTypes, IconDirection } from '../../../atoms/enums'
import ArrowRight from '../../../../icons/ArrowRight.icon'
import { Link } from 'react-router-dom'

const Students: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.students}>
        <img src={image8} className={styles.image} alt='students' />
        <div className={styles.texts}>
          <h2 className={styles.sub__text}>Learn and grow together</h2>
          <h3 className={styles.headline}> Learn with other students </h3>
          <h3 className={styles.desc}>
            Acquire industry standard skills for free and land you dream job. Acquire industry
            standard skills for free.
          </h3>
          <Link to={'/auth/register'} className={styles.btn}>
            <Button
              icon={<ArrowRight />}
              iconDir={IconDirection.RIGHT}
              text='Enroll Now'
              type={ButtonTypes.PRIMARY}
              fontSize='16px'
              className={styles.bouton}
            />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Students
