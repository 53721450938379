const authData = [
  {
    title: 'Discover new skills and advance your career ',
    content:
      'Growing you knowledge has never been easier. Acquire the most in demand skills form experts with our free courses. Join now and discover the wide range of courses we have for you. ',
  },
  {
    title: 'Grow your knowledge with courses from experts ',
    content:
      'Growing you knowledge has never been easier. Acquire the most in demand skills form experts with our free courses. Join now and discover the wide range of courses we have for you. ',
  },
  {
    title: 'Start your journey to success with our online course',
    content:
      'Growing you knowledge has never been easier. Acquire the most in demand skills form experts with our free courses. Join now and discover the wide range of courses we have for you. ',
  },
  {
    title: 'Learn new skills and get ahead with our online courses',
    content:
      'Growing you knowledge has never been easier. Acquire the most in demand skills form experts with our free courses. Join now and discover the wide range of courses we have for you. ',
  },
]

export default authData
