import React, { useMemo } from 'react'

import styles from './about.module.css'

import Button from '../../../molecules/button/button.molecule'
import { ButtonTypes } from '../../../atoms/enums'
import { appPaths } from '../../../../constants/app-paths'
import { Link } from 'react-router-dom'
import LessonAccordion from '../../course-details/lesson-accordion/LessonAccordion.template'
import { CourseEntityType } from '../../../../types/models'

export type CourseLessonType = {
  courses: CourseEntityType[]
}

const BootcampAbout: React.FC<CourseLessonType> = ({ courses }) => {
  // const videoRef = useRef<HTMLVideoElement | null>(null)
  // const [isPlaying, setPlaying] = useState(false)

  // const playPause = () => {
  //   if (isPlaying) {
  //     videoRef.current?.pause()
  //     setPlaying(false)
  //   } else {
  //     videoRef.current?.play()
  //     setPlaying(true)
  //   }
  // }

  const lessons = useMemo(() => {
    return courses.map((course) => {
      return { id: course.id, name: course.name, subLessons: course.lessons }
    })
  }, [courses])

  return (
    <section className={styles.body} id='about'>
      <h1 className={styles.title}>Bootcamp Courses</h1>
      <p className={styles.subtitle}>
        This Bootcamp is made of the following courses and Students have to finish each of them in
        order to receive the certificate of completion at the end of the Bootcamp.{' '}
      </p>
      <div className={styles.content}>
        <aside className={styles.details}>
          <div className={styles.plan}>
            <LessonAccordion lessons={lessons} />
          </div>
        </aside>
      </div>
      <div className={styles.button}>
        <Link to={appPaths.REGISTER}>
          <Button
            type={ButtonTypes.PRIMARY}
            text={'Enroll into Course 1'}
            fontSize='16px'
            // className={styles.custom}
          />
        </Link>
      </div>
    </section>
  )
}

export default BootcampAbout
