import React, { useEffect } from 'react'
import styles from './modals.module.css'
import { spinner } from '../../../../repository/assets'

const Pendingmodal: React.FC<{ open: boolean }> = ({ open }) => {
  useEffect(() => {
    if (open) {
      window.addEventListener('beforeunload', (e) => {
        e.preventDefault()
        return 'Please do not close the page while the transaction is processing'
      })
    }

    return () => {
      window.removeEventListener('beforeunload', () => console)
    }
  }, [open])

  return (
    <div className={styles.body} onClick={(e) => e.stopPropagation()}>
      <div className={styles.content}>
        <div className={styles.spinner}>
          <img src={spinner} alt='Loading' />
        </div>
        <div className={styles.text}>
          <h2>Pending Approval</h2>
          <p>
            Dail *126# and approve the checkout. We are currently processing the payment. Please
            don’t refresh or close the page.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Pendingmodal
