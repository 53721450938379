import React from 'react'
import { IconProps } from '../types'

const ArrowLeftIcon = ({ size = 24, color = 'currentColor', stroke = 2, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      stroke={`${stroke}`}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z' fill={color} />
    </svg>
  )
}

export default ArrowLeftIcon
