/* eslint-disable */

import React from 'react'
import { IconProps } from '../types'

const GoogleIcon = ({ size = 20, color = 'currentColor', stroke = 2, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_452_4674)'>
        <path
          d='M19.7616 10.1871C19.7616 9.36771 19.6951 8.76977 19.5512 8.1497H10.1946V11.848H15.6867C15.576 12.7671 14.9781 14.1512 13.6493 15.0813L13.6307 15.2051L16.5891 17.4969L16.794 17.5174C18.6764 15.7789 19.7616 13.2211 19.7616 10.1871Z'
          fill='#4285F4'
        />
        <path
          d='M10.1947 19.9313C12.8854 19.9313 15.1442 19.0454 16.7941 17.5174L13.6494 15.0813C12.8079 15.6682 11.6784 16.0779 10.1947 16.0779C7.55932 16.0779 5.3226 14.3395 4.52527 11.9366L4.4084 11.9466L1.33222 14.3272L1.29199 14.4391C2.93077 17.6945 6.29695 19.9313 10.1947 19.9313Z'
          fill='#34A853'
        />
        <path
          d='M4.52526 11.9366C4.31488 11.3166 4.19313 10.6521 4.19313 9.96565C4.19313 9.27908 4.31488 8.61473 4.51419 7.99466L4.50862 7.8626L1.39389 5.44366L1.29198 5.49214C0.616561 6.84305 0.229004 8.36008 0.229004 9.96565C0.229004 11.5712 0.616561 13.0882 1.29198 14.4391L4.52526 11.9366Z'
          fill='#FBBC05'
        />
        <path
          d='M10.1947 3.85336C12.066 3.85336 13.3283 4.66168 14.048 5.33718L16.8605 2.59107C15.1332 0.985496 12.8854 0 10.1947 0C6.29695 0 2.93077 2.23672 1.29199 5.49214L4.51421 7.99466C5.3226 5.59183 7.55932 3.85336 10.1947 3.85336Z'
          fill='#EB4335'
        />
      </g>
      <defs>
        <clipPath id='clip0_452_4674'>
          <rect width='19.542' height='20' fill='white' transform='translate(0.229004)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default GoogleIcon
