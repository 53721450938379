import React, { useMemo, useState } from 'react'
import { Quiz } from '../../../../types'
import radiostyles from './radio.module.css'
import checkstyles from './checkbox.module.css'
import styles from './quiz.module.css'
import Button from '../../../molecules/button/button.molecule'
import { ButtonTypes } from '../../../atoms/enums'
import { LocalStorage } from '../../../../services/storage/Local.storage'
import ProgressBar from '../../../organisms/progressbar/ProgressBar.organism'
import { publicRequest } from '../../../../constants/configs'

interface QuizProps {
  quizzes: Array<Quiz>
  refetch: () => void
  startLoad: () => void
}

const CourseQuiz: React.FC<QuizProps> = ({ quizzes, refetch, startLoad }) => {
  type answersType = Record<number, Record<number, Set<number>>>
  const [answers, setAnswers] = useState<answersType>({})

  const [isSubmitDisabled, quizProgress] = useMemo(() => {
    const totalQuestions = quizzes.reduce((prev, curr) => {
      return prev + curr.questions.length
    }, 0)
    const totalAnswers = Object.values(answers).reduce((prev, curr) => {
      return prev + Object.keys(curr).length
    }, 0)

    const prog = (totalAnswers / totalQuestions) * 100

    return [totalQuestions !== totalAnswers, prog]
  }, [answers, quizzes])

  const answerQuiz = ({
    quizID,
    questionID,
    answerID,
    mode,
  }: {
    quizID: number
    questionID: number
    answerID: number
    mode: 'single' | 'multiple'
  }) => {
    const _answers = { ...answers }
    const quiz = _answers[quizID] || {}
    let questionAnswers = quiz[questionID] || new Set()
    if (mode === 'single') {
      questionAnswers = new Set()
      questionAnswers.add(answerID)
    } else {
      if (questionAnswers.has(answerID)) {
        questionAnswers.delete(answerID)
      } else {
        questionAnswers.add(answerID)
      }
    }
    quiz[questionID] = questionAnswers
    _answers[quizID] = quiz
    setAnswers(_answers)
  }

  const isChecked = ({
    quizID,
    questionID,
    answerID,
  }: {
    quizID: number
    questionID: number
    answerID: number
  }) => {
    const quiz = answers[quizID]
    if (!quiz) return false
    const questionAnswers = quiz[questionID]
    if (!questionAnswers) return false

    return questionAnswers.has(answerID)
  }

  const submitQuiz = async () => {
    startLoad()
    try {
      // eslint-disable-next-line
      const requests: any[] = []
      Object.entries(answers).forEach(([quizID, questions]) => {
        Object.entries(questions).forEach(([questionID, answerIDs]) => {
          const data = {
            quizId: Number(quizID),
            questionId: Number(questionID),
            userAnswerIds: Array.from(answerIDs),
          }
          requests.push(
            publicRequest.post('/quiz/submit', data, {
              headers: {
                Authorization: `Bearer ${LocalStorage.getAccessToken()}`,
              },
            }),
          )
        })
      })
      const response = await Promise.all(requests)
      console.log(response)
      refetch()
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <section className={styles.body}>
      <LessonProgress progress={quizProgress} />
      {quizzes.map((quiz) => {
        return (
          <React.Fragment key={`quiz_${quiz.id}`}>
            {quiz.questions.map((question, index) => {
              if (question.type === 'single') {
                return (
                  <div key={`questions_${index}`} className={styles.question}>
                    <div className={styles.text}>
                      <span>Question {index + 1}:</span>
                      <h6>{question.text}</h6>
                    </div>
                    <hr className={styles.divider} />
                    {question.answers.map((answer) => {
                      return (
                        <Radio
                          key={`answer_${answer.id}`}
                          name={question.text}
                          label={answer.value}
                          checked={isChecked({
                            quizID: quiz.id,
                            questionID: question.id,
                            answerID: answer.id,
                          })}
                          action={() =>
                            answerQuiz({
                              quizID: quiz.id,
                              questionID: question.id,
                              answerID: answer.id,
                              mode: 'single',
                            })
                          }
                        />
                      )
                    })}
                  </div>
                )
              } else {
                return (
                  <div key={`questions_${index}`} className={styles.question}>
                    <div className={styles.text}>
                      <span>Question {index + 1}:</span>
                      <h6>{question.text}</h6>
                    </div>
                    {question.answers.map((answer) => {
                      return (
                        <CheckBox
                          key={`answer_${answer.id}`}
                          label={answer.value}
                          checked={isChecked({
                            quizID: quiz.id,
                            questionID: question.id,
                            answerID: answer.id,
                          })}
                          action={() =>
                            answerQuiz({
                              quizID: quiz.id,
                              questionID: question.id,
                              answerID: answer.id,
                              mode: 'multiple',
                            })
                          }
                        />
                      )
                    })}
                  </div>
                )
              }
            })}
          </React.Fragment>
        )
      })}
      <div className={styles.buttons}>
        <Button
          type={ButtonTypes.SECONDARY}
          text='Discard Changes'
          className={`${styles.discard} ${styles.btn}`}
          onClick={() => setAnswers({})}
        />
        <Button
          type={ButtonTypes.PRIMARY}
          text='Submit Results'
          className={styles.btn}
          disabled={isSubmitDisabled}
          onClick={submitQuiz}
        />
      </div>
    </section>
  )
}

export default CourseQuiz

interface IRadioProps {
  name?: string
  label?: string
  action?: () => void
  checked: boolean
}

/**
 * The component still has a lot to be done
 * for now, it was just created for a generalisation
 * but later on features and updates will be added as required
 *
 */

const Radio: React.FC<IRadioProps> = ({ label, name, action, checked }) => {
  return (
    <label
      className={radiostyles.label}
      onClick={(e) => {
        e.stopPropagation()
        if (action) {
          action()
        }
      }}
    >
      <input type={'radio'} onClick={(e) => e.stopPropagation()} name={name} />
      <span>{label}</span>
      <span className={radiostyles.circle}>
        <span
          className={`${radiostyles.dot} ${checked ? radiostyles.show : radiostyles.hide}`}
        ></span>
      </span>
    </label>
  )
}

interface CheckboxProps {
  label?: string
  action: () => void
  checked: boolean
}
const CheckBox: React.FC<CheckboxProps> = ({ label, checked, action }) => {
  return (
    <label className={checkstyles.label}>
      <span>{label}</span>

      <input
        onChange={() => action()}
        type='checkbox'
        checked={checked}
        // style={{ width: '20px', height: '20px' }}
      />
    </label>
  )
}

const LessonProgress: React.FC<{ progress: number }> = ({ progress }) => {
  return (
    <div className={styles.progressBody}>
      <h3>Lesson Quiz Progress</h3>
      <ProgressBar bgcolor='var(--platform-secondary-700)' completed={progress} noHeading />
    </div>
  )
}
