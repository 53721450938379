import React from 'react'

import styles from './maintenance.module.css'

import { IconsRepository } from '../../repository/icons/icon.repository'

const MaintenancePage = () => {
  return (
    <div className={styles.wrapper} style={{ padding: '32px var(--px)' }}>
      <div className={styles.logo}>
        <span>
          <IconsRepository.LogoIcon />
        </span>
      </div>
      <main className={styles.body}>
        <div className={styles.head}>
          <h1>Under Maintainance</h1>
          <p>The website is currently under maintainance.</p>
        </div>
        <span>
          <IconsRepository.MaintainanceWheelIcon />
        </span>
        <div className={styles.footer}>
          <p>© 2023 Camsol Technologies. All rights reserved</p>
        </div>
      </main>
    </div>
  )
}

export default MaintenancePage
