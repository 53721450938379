import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { ProfileCard } from '../cards/profile-card/ProfileCard.organism'

// stylesheet
import styles from './navbarloggedin.module.css'

// general imports
import CardRef from '../cards/details-card/DetailsCard.organism'
import { defaultImage, trainingLogo } from '../../../repository/assets'
import { IconsRepository } from '../../../repository/icons/icon.repository'
import { appPaths } from '../../../constants/app-paths'
import { useAppDispatch, useAppSelector } from '../../../lib/hooks'
import Loader from '../../atoms/loader/Loader'
import { setSearch } from '../../../features/course/slices/course.slice'
import { CourseEntityType } from '../../../types/models'
import { UseScrollPosition } from '../../../lib/hooks/useScrollPosition'
import { LocalStorage } from '../../../services/storage/Local.storage'
import { resetAuthenticationState } from '../../../features/authentication/authenticationSlice'

type Props = {
  userImage: string
  userName?: string
  padding?: string
}

const NavbarLoggedin: React.FC<Props> = ({ userImage, padding }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { courses, isLoading } = useAppSelector((state) => state.course)
  const [searchClicked, setSearchClickeed] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const [typing, setTyping] = useState(false)
  const [profileActive, setProfileActive] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const position = UseScrollPosition()
  const location = useLocation()
  const { user } = useAppSelector((state) => state.authentication)

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [location])

  const toggleMenuState = () => {
    setMenuOpen((menuOpen) => !menuOpen)
    setSearchClickeed(false)
  }

  const toggleSearchState = () => {
    setSearchClickeed((searchClicked) => !searchClicked)
    setMenuOpen(false)
  }

  const searchData = (_data: Array<CourseEntityType>) => {
    const data = [..._data]
    try {
      const searchResult = data.filter((course) => {
        const regex = new RegExp(
          `${['+', '-'].includes(searchTerm) ? `\\${searchTerm}` : searchTerm}`,
          'ig',
        )
        if (regex.test(course.name || '')) return true
        if (regex.test(course.author?.name || '')) return true
        return false
      })

      return searchResult
    } catch (e) {
      // console.error(e);
      return data
    }
  }

  const filteredCourses = searchData(courses)

  return (
    <nav
      style={{
        padding: padding !== undefined ? padding : '32px var(--px)',
      }}
      className={`${styles.navbarLoggedin} ${position > 5 ? styles.shadow : ''} ${styles.mobile} ${
        menuOpen ? styles.navbarLoggedMenu : ''
      }`}
    >
      <div className={styles.mobileNav}>
        <span className={styles.btnContainer} style={{ width: '32px', height: '32px' }}>
          <button
            className={`${styles.navButton} ${
              !menuOpen ? styles.activeButton : styles.inActiveButton
            }`}
            onClick={toggleMenuState}
          >
            <IconsRepository.NavMenuIcon />
          </button>
          <button
            className={`${styles.navButton} ${
              menuOpen ? styles.activeButton : styles.inActiveButton
            }`}
            onClick={() => {
              toggleMenuState()
              setTyping(false)
            }}
          >
            <IconsRepository.CloseIcon size={20} height={19} />
          </button>
        </span>
        <span>
          <Link to={'/dashboard'}>
            <span style={{ display: `${searchClicked ? 'none' : 'block'}` }}>
              <IconsRepository.LogoAndDepartmentIcon />
            </span>
          </Link>
          <div
            className={styles.inputDivMobile}
            style={{ display: `${searchClicked ? 'block' : 'none'}` }}
          >
            <input
              onChange={(event) => {
                setTyping(true)
                setSearchTerm(event.target.value)
              }}
              onKeyDown={(event: React.KeyboardEvent) => {
                if (event.which === 13) {
                  dispatch(setSearch(searchTerm))
                  navigate('/search-results')
                }
              }}
              type='text'
              placeholder='Search for a course'
            />
            <button className={`${styles.navButton} ${styles.inputSearch}`}>
              <IconsRepository.SearchIcon size={16} height={16} />
            </button>
            <div
              className={`${styles.suggestionCardMobile} ${
                typing ? styles.suggestionCardMobileActive : ''
              }`}
            >
              <div className={styles.suggestedDiv}>
                <p className={styles.suggested}>Suggested courses</p>
                <p className={styles.suggestedText}>
                  Press enter or click on view all courses link to view the complete list of
                  results.
                </p>
              </div>
              {isLoading ? (
                <Loader />
              ) : (
                <div className={styles.cardsDiv}>
                  {filteredCourses.length > 0 ? (
                    filteredCourses.slice(0, 2).map((course) => (
                      <Link
                        to={`/course-preview/${course.id}`}
                        key={course.id}
                        className={styles.card}
                      >
                        <CardRef
                          categories={course.categories || []}
                          image={course.cover?.url || defaultImage}
                          title={course.name || ''}
                          description={course.description || ''}
                          lessons={course.lessons?.length || 0}
                          length={course.duration || 0}
                          hasDescription={false}
                        />
                      </Link>
                    ))
                  ) : (
                    <h2>No courses found</h2>
                  )}
                </div>
              )}
              <div className={styles.allSuggestionsDiv}>
                <p>
                  <Link
                    to='/search-results'
                    onClick={() => {
                      dispatch(setSearch(searchTerm))
                    }}
                  >
                    All suggestions
                  </Link>
                </p>
                <IconsRepository.ArrowRight size={18} height={16} />
              </div>
            </div>
          </div>
        </span>
        <span className={styles.btnContainer} style={{ width: '32px', height: '32px' }}>
          <button
            className={`${styles.navButton} ${
              !searchClicked ? styles.activeButton : styles.inActiveButton
            }`}
            onClick={toggleSearchState}
          >
            <IconsRepository.SearchIcon size={24} height={25} />
          </button>
          <button
            className={`${styles.navButton} ${
              searchClicked ? styles.activeButton : styles.inActiveButton
            }`}
            onClick={() => {
              toggleSearchState()
              setTyping(false)
            }}
          >
            <IconsRepository.CloseIcon size={20} height={20} />
          </button>
        </span>
      </div>
      <div className={`${styles.mobileLinks} ${menuOpen ? styles.mobileLinksActive : ''}`}>
        <Link className={styles.navLink} to={appPaths.COURSES_PAGE} onClick={toggleMenuState}>
          All courses
        </Link>
        <Link className={styles.navLink} to={'/course-category'} onClick={toggleMenuState}>
          Categories
        </Link>
        <Link className={styles.navLink} to={'/dashboard/my-courses'} onClick={toggleMenuState}>
          My courses
        </Link>
        <div className={styles.imgDivMobile}>
          <img
            src={defaultImage}
            style={{
              objectFit: 'cover',
              width: '32px',
              height: '32px',
              borderRadius: '100px',
            }}
          />{' '}
          Welcome, {user?.first_name}
        </div>
        {/* <Link className={styles.navLink} to={'/dashboard/profile/notifications'}>
          Notifications
        </Link> */}
        <Link
          className={styles.navLink}
          to={'/dashboard/profile/account'}
          onClick={toggleMenuState}
        >
          Account
        </Link>
        <Link className={styles.navLink} to={'/dashboard/profile'} onClick={toggleMenuState}>
          Settings
        </Link>
        <Link className={styles.navLink} to={'/dashboard/profile'} onClick={toggleMenuState}>
          Profile
        </Link>
        <button
          className={styles.logoutButton}
          onClick={() => {
            if (window.confirm('Are you sure you want to log out?')) {
              dispatch(resetAuthenticationState())
              LocalStorage.removeCurrentUser()
              localStorage.removeItem('email_tracker')

              navigate(appPaths.HOME_PAGE)
            }
          }}
        >
          <IconsRepository.LogoutIcon /> Logout
        </button>
      </div>

      <div className={styles.leftDiv}>
        <Link
          style={{
            width: '147px',
          }}
          to={'/dashboard'}
        >
          <img
            style={{
              width: '147px',
            }}
            src={trainingLogo}
            alt='camsol logo'
          />
        </Link>
        <div
          className={styles.navLinks}
          style={{ transform: `${searchClicked ? 'scaleX(0)' : 'scaleX(1)'}` }}
        >
          <Link className={styles.navLink} to={'/all-courses'}>
            Courses
          </Link>
          <Link className={styles.navLink} to={'/dashboard/my-courses'}>
            My Courses
          </Link>
          <Link className={styles.navLink} to={'/dashboard/profile'}>
            Settings
          </Link>
        </div>
      </div>

      <div className={styles.rightDiv}>
        <div
          className={`${styles.inputFieldDiv} ${searchClicked ? styles.inputFieldDivActive : ''}`}
        >
          <div className={`${styles.iconButton} ${styles.searchButton}`}>
            <IconsRepository.SearchIcon />
          </div>
          <button
            className={`${styles.iconButton} ${styles.closeButton}`}
            style={{ display: `${searchClicked ? 'block' : 'none'}` }}
            onClick={() => {
              setSearchClickeed(false)
              setTyping(false)
            }}
          >
            <IconsRepository.CloseIcon />
          </button>
          <input
            className={styles.inputField}
            onClick={() => {
              setSearchClickeed(true)
            }}
            onKeyDown={(event: React.KeyboardEvent) => {
              if (event.which === 13) {
                dispatch(setSearch(searchTerm))
                navigate('/search-results')
              }
            }}
            onChange={(event) => {
              setTyping(true)
              setSearchClickeed(true)
              setSearchTerm(event.target.value)
            }}
            type='text'
            placeholder={searchClicked ? '' : 'Search for a course'}
          />
          <div className={`${styles.suggestionCard} ${typing ? styles.suggestionCardActive : ''}`}>
            <div className={styles.suggestedDiv}>
              <p className={styles.suggested}>Suggested courses</p>
              <p className={styles.suggestedText}>
                Press enter or click on view all courses link to view the complete list of results.
              </p>
            </div>
            {isLoading ? (
              <Loader />
            ) : (
              <div className={styles.cardsDiv}>
                {filteredCourses.length > 0 ? (
                  filteredCourses.slice(0, 2).map((course) => (
                    <Link
                      to={`/course-preview/${course.id}`}
                      key={course.id}
                      className={styles.card}
                    >
                      <CardRef
                        categories={course.categories || []}
                        image={course.cover?.url || defaultImage}
                        title={course.name || ''}
                        description={course.description || ''}
                        lessons={course.lessons?.length || 0}
                        length={course.duration || 0}
                        hasDescription={false}
                      />
                    </Link>
                  ))
                ) : (
                  <h2>No courses found</h2>
                )}
              </div>
            )}
            <div className={styles.allSuggestionsDiv}>
              <p>
                <Link
                  to='/search-results'
                  onClick={() => {
                    dispatch(setSearch(searchTerm))
                  }}
                >
                  All suggestions
                </Link>
              </p>
              <IconsRepository.ArrowRight size={16} height={14} />
            </div>
          </div>
        </div>
        <div className={styles.iconDiv}>
          {/* <IconsRepository.BellIcon /> */}
          <Link to={'/dashboard/profile'}>
            <span style={{ color: 'var(--platform-secondary-700)' }}>
              <IconsRepository.SettingsIcon />
            </span>
          </Link>
          <div
            className={styles.profileDiv}
            onClick={() => {
              setProfileActive((profileActive) => !profileActive)
            }}
          >
            <img
              className={styles.profileImage}
              style={{
                objectFit: 'cover',
                cursor: 'pointer',
              }}
              src={userImage}
              alt=''
            />
            <div
              className={styles.cardDiv}
              style={{ display: `${profileActive ? 'block' : 'none'}` }}
            >
              <ProfileCard userImage={userImage ? userImage : defaultImage} />
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default NavbarLoggedin
