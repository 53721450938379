import { IconProps } from '../types'

const Person = ({
  width = 20,
  height = 20,
  color = 'currentColor',
  stroke = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      stroke={`${stroke}`}
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M5.99984 2.00001C6.73317 2.00001 7.33317 2.6 7.33317 3.33334C7.33317 4.06667 6.73317 4.66667 5.99984 4.66667C5.2665 4.66667 4.6665 4.06667 4.6665 3.33334C4.6665 2.6 5.2665 2.00001 5.99984 2.00001ZM5.99984 8.66667C7.79984 8.66667 9.8665 9.52667 9.99984 10H1.99984C2.15317 9.52 4.2065 8.66667 5.99984 8.66667ZM5.99984 0.666672C4.5265 0.666672 3.33317 1.86 3.33317 3.33334C3.33317 4.80667 4.5265 6 5.99984 6C7.47317 6 8.6665 4.80667 8.6665 3.33334C8.6665 1.86 7.47317 0.666672 5.99984 0.666672ZM5.99984 7.33334C4.21984 7.33334 0.666504 8.22667 0.666504 10V11.3333H11.3332V10C11.3332 8.22667 7.77984 7.33334 5.99984 7.33334Z'
        fill={color}
      />
    </svg>
  )
}

export default Person
