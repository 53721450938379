import React from 'react'

const VideoPlayerIcon = ({ ...props }) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M7.49967 5.83333V12.5L13.333 9.16667L7.49967 5.83333ZM17.4997 2.5H2.49967C1.58301 2.5 0.833008 3.25 0.833008 4.16667V14.1667C0.833008 15.0833 1.58301 15.8333 2.49967 15.8333H6.66634V17.5H13.333V15.8333H17.4997C18.4163 15.8333 19.1663 15.0833 19.1663 14.1667V4.16667C19.1663 3.25 18.4163 2.5 17.4997 2.5ZM17.4997 14.1667H2.49967V4.16667H17.4997V14.1667Z'
        fill='black'
      />
    </svg>
  )
}

export default VideoPlayerIcon
