// const options = {
//   duration: [
//     { key: 1, value: '0-1 hour' },
//     { key: 2, value: '1-3 hours' },
//     { key: 2, value: '4-5 hours' },
//   ],
//   rating: [
//     { key: 1, value: '4.5 & more' },
//     { key: 2, value: '4 & more' },
//     { key: 3, value: '3.5 & more' },
//     { key: 4, value: '3 & more' },
//     { key: 5, value: '2 & more' },
//   ],
//   level: [
//     { key: 1, value: 'Advanced' },
//     { key: 2, value: 'Intermediate' },
//     { key: 3, value: 'Beginner' },
//   ],
//   language: [
//     { key: 1, value: 'English' },
//     { key: 2, value: 'German' },
//     { key: 3, value: 'French' },
//   ],
//   technology: [
//     {
//       key: 1,
//       value: 'Web Development',
//       sub: [
//         { key: 1, value: 'Html' },
//         { key: 2, value: 'Css' },
//         { key: 3, value: 'JavaScript' },
//         { key: 4, value: 'JQuery' },
//       ],
//     },
//     { key: 2, value: 'Management' },
//     { key: 3, value: 'Marketing' },
//     { key: 4, value: 'Design' },
//     { key: 5, value: 'Business' },
//   ],
// }

const currentOptions = {
  level: [
    { key: 1, value: 'Advanced' },
    { key: 2, value: 'Intermediate' },
    { key: 3, value: 'Beginner' },
  ],
  language: [
    { key: 1, value: 'English' },
    { key: 2, value: 'German' },
    { key: 3, value: 'French' },
  ],
  category: [
    {
      key: 1,
      value: 'Web Development',
      sub: [
        { key: 1, value: 'Html' },
        { key: 2, value: 'Css' },
        { key: 3, value: 'JavaScript' },
        { key: 4, value: 'JQuery' },
      ],
    },
    { key: 2, value: 'Management' },
    { key: 3, value: 'Marketing' },
    { key: 4, value: 'Design' },
    { key: 5, value: 'Business' },
    { key: 6, value: 'No code' },
  ],
}

export default currentOptions
