/* eslint-disable */
import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

// stylesheet
import styles from './main.module.css'

import { defaultImage } from '../../../../repository/assets'
import Button from '../../../molecules/button/button.molecule'
import Tabs from '../../../molecules/tabs/Tabs.molecule'
import MyCourse from './my-courses/MyCourses.template'
import DashboardCourseCard, { ICardProps } from './card/DCourseCard.template'
import { ButtonTypes } from '../../../atoms/enums'
import { useAppDispatch, useAppSelector } from '../../../../lib/hooks'
import { courseThunk } from '../../../../features/course/thunks/course.thunk'
import { CourseEntityType } from '../../../../types/models'
import { IdName } from '../../../../types'
import Swiper from '../../../organisms/swiper/Swiper.organism'
import CourseCard from '../../../organisms/cards/course-card/CourseCard.organism'
import useWindowSize from '../../../../lib/hooks/useWindowSize'
import { setSuggestedCourses } from '../../../../features/course/slices/course.slice'

const MainContent: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0)
  const { width } = useWindowSize()

  const data: ICardProps = {
    image: defaultImage,
    title: 'The complete UI/UX Bootcamp',
    tutor: 'Leonardo Bornhäußer',
    tutorImage: defaultImage,
    studentCount: 50,
    length: 600,
    lessons: 7,
    languages: ['En', 'Ger', 'Fr'],
    categories: [],
    studentImages: [defaultImage, defaultImage, defaultImage],
  }

  const dispatch = useAppDispatch()

  const { courses, isLoading, suggestedCourses } = useAppSelector((state) => state.course)
  const { user } = useAppSelector((state) => state.authentication)
  const [filteredCourses, setFilteredCourses] = useState<CourseEntityType[]>([])
  const [newCourses, setNewCourses] = useState<Array<CourseEntityType>>([])

  useEffect(() => {
    if (!user) {
      return
    }
    if (courses.length > 0) {
      const _courses = user?.enrollments?.map((c) => c.course) || []
      const fCourses = courses.filter((c) => _courses.includes(c.id!))
      setFilteredCourses(fCourses)
      // console.log(
      //   'newCourses',
      //   [...courses].sort((a, b) => +new Date(a.createdAt || '') - +new Date(b.createdAt || '')),
      // )
      setNewCourses(
        [...courses].sort((a, b) => +new Date(a.createdAt || '') - +new Date(b.createdAt || '')),
      )
      if (suggestedCourses.length === 0) {
        const suggested = getSuggestedCourses(fCourses, courses)
        dispatch(setSuggestedCourses(suggested))
      }
    }
  }, [courses])

  const getSuggestedCourses = (
    userCourses: Array<CourseEntityType>,
    allCourses: Array<CourseEntityType>,
  ) => {
    const suggestions = allCourses.map((course) => {
      const _course = { ...course, rating: 0 }

      for (const ucourse of userCourses) {
        if (_course.id === ucourse.id) {
          break
        }

        if (_course.author?.id === ucourse.author?.id) {
          _course.rating++
        }

        _course.categories?.forEach((cat) => {
          if (ucourse.categories?.some((c) => c.id === cat.id)) {
            _course.rating++
          }
        })
      }

      return _course
    })

    const sortedSuggestions = suggestions.sort((a, b) => b.rating - a.rating)
    const suggestedCourseIds = sortedSuggestions.slice(0, 3).map((c) => c.id)

    return allCourses.filter((course) => suggestedCourseIds.includes(course.id))
  }

  useEffect(() => {
    if (!courses || courses.length === 0) {
      dispatch(courseThunk())
    }
  }, [])

  const displayCourse = [courses, filteredCourses, suggestedCourses, newCourses]

  const removeDuplicates = (
    filtered: Array<CourseEntityType>,
    courses: Array<CourseEntityType>,
  ) => {
    const ids = filtered.map((c) => c.id)
    return courses.filter((c) => !ids.includes(c.id))
  }

  return (
    <section className={styles.body}>
      <h2 className={styles.title}>Grow your skills with the best courses</h2>
      <Tabs
        // labels={categoriesData}
        labels={['Most Rated', 'Enrolled', 'Suggested', 'New']}
        changeTab={setActiveTab}
        activeTab={activeTab}
        containerClassName={styles.tabContainer}
        tabClassName={styles.tab}
        activeClassName={styles.tabActive}
      />
      <div className={styles.courses}>
        {isLoading ? (
          <Swiper singleSlideWidth={`${350}px`} gap='1.6rem' p>
            {Array(16)
              .fill(0)
              .map((_course, index) => {
                return (
                  <Fragment key={index}>
                    <CourseCard isLoading={true} cover={undefined} name={undefined} />
                  </Fragment>
                )
              })}
          </Swiper>
        ) : (
          ''
        )}
        {!isLoading && displayCourse[activeTab]?.length > 0 && (
          <Swiper singleSlideWidth={`${width > 768 ? 438 : 200}px`} gap='2rem' padding='0 0 2rem 0'>
            {displayCourse[activeTab].map((course) => {
              return (
                <DashboardCourseCard
                  key={`${course.id}`}
                  id={course?.id as number}
                  categories={course.categories?.slice(0, 2) as IdName[]}
                  title={course?.name as string}
                  tutor={course?.author?.name as string}
                  studentCount={data.studentCount}
                  studentImages={data.studentImages}
                  tutorImage={course?.author?.avatarUrl as string}
                  image={course?.cover?.url as string}
                  languages={course?.language as IdName[]}
                  length={data.length}
                  lessons={course?.lessons?.length as number}
                />
              )
            })}
          </Swiper>
        )}
      </div>
      <div className={styles.learningHeader}>
        <h2 className={styles.title}>Continue Learning</h2>
        <Link to='/dashboard/my-courses'>
          <Button type={ButtonTypes.PRIMARY} text={'See All'} className={styles.customBtn} />
        </Link>
      </div>
      <div className={styles.learning}>
        {isLoading
          ? Array(4)
              .fill(0)
              .map((_v, index) => <div key={index} className={styles.loading__state}></div>)
          : null}
        {!isLoading &&
          courses.length > 0 &&
          [
            ...filteredCourses,
            ...removeDuplicates(filteredCourses, courses).slice(
              0,
              7 - (filteredCourses?.length || 0),
            ),
          ].map((course: CourseEntityType, index: number) => {
            return (
              <MyCourse
                key={`dashboard_course_${course.id}`}
                title={course.name as string}
                // description={course.description as string}
                author={course.author}
                cover={course.cover}
                lessons={course.lessons}
                courseID={course?.id}
              />
            )
          })}
      </div>
    </section>
  )
}

export default MainContent
