/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit'

import { APIStatus } from '../../constants/configs'
import { forgotPasswordThunkService, resetPasswordThunkService } from './authenticationThunks'

export const initialState: {
  status: APIStatus
  errorTrace: boolean
  message: string
  codeSent: APIStatus
} = {
  status: APIStatus.IDLE,
  errorTrace: false,
  message: '',
  codeSent: APIStatus.IDLE,
}

const forgotPasswordSlice = createSlice({
  name: 'forgotPasswordSlice',
  initialState: initialState,
  reducers: {
    resetForgotPasswordSlice: (state) => {
      state.errorTrace = false
      state.status = APIStatus.IDLE
      state.message = ''
      state.codeSent = APIStatus.IDLE
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(forgotPasswordThunkService.pending, (state) => {
        state.status = APIStatus.PENDING
      })
      .addCase(forgotPasswordThunkService.fulfilled, (state) => {
        state.status = APIStatus.FULFILLED
      })
      .addCase(forgotPasswordThunkService.rejected, (state, action) => {
        state.status = APIStatus.REJECTED

        state.errorTrace = true
        state.message = (action.payload as { error: string }).error
      })
      .addCase(resetPasswordThunkService.pending, (state) => {
        state.status = APIStatus.PENDING
      })
      .addCase(resetPasswordThunkService.fulfilled, (state) => {
        state.status = APIStatus.FULFILLED
      })
      .addCase(resetPasswordThunkService.rejected, (state, action) => {
        state.status = APIStatus.REJECTED

        state.errorTrace = true
        state.message = (action.payload as { error: string }).error
      })
  },
})

export const { resetForgotPasswordSlice } = forgotPasswordSlice.actions
export default forgotPasswordSlice.reducer
