import React, { useState } from 'react'

// stylesheet
import styles from './bestcourses.module.css'

// general imports
import Card2 from '../../../organisms/cards/card-2/card2.organism'
import { IconsRepository } from '../../../../repository/icons/icon.repository'
import { useAppSelector } from '../../../../lib/hooks'
import { Link } from 'react-router-dom'

const BestCourses: React.FC = () => {
  const [level, setLevel] = useState(0)
  const { courses } = useAppSelector((state) => state.course)

  const next = () => {
    if (courses.length <= 0) {
      return
    }
    let newLevel = level + 3
    if (newLevel > courses.length - 3) {
      newLevel = 0
    }
    setLevel(newLevel)
  }

  const prev = () => {
    if (courses.length <= 0) {
      return
    }
    let newLevel = level - 3
    if (newLevel < 0) {
      newLevel = courses.length - 3
    }
    setLevel(newLevel)
  }

  return (
    <section className={styles.section}>
      <div className={styles.info}>
        <h2>Discover our Best courses</h2>
        <p>
          Our mission is to empower you on your journey towards acquiring your desired skillset,
          providing the resources and support you need to succeed.
        </p>
        <div className={styles.chevron_div}>
          <button
            style={{
              transform: 'rotate(90deg)',
            }}
            onClick={prev}
          >
            <IconsRepository.DropDownIcon size={11} height={16} />
          </button>
          <button
            style={{
              transform: 'rotate(-90deg)',
            }}
            onClick={next}
          >
            <IconsRepository.DropDownIcon size={11} height={16} />
          </button>
        </div>
      </div>
      <div className={styles.courses}>
        {courses?.slice(level, level + 3).map((course, idx) => (
          <Link to={`/course-preview/${course.id}`} key={course.id}>
            <Card2
              key={`${course.name}-${idx}`}
              image={course.cover?.url || ''}
              title={course.name || ''}
              author={course.author?.name || 'Nchia Emmanuela Ekeichukwu'}
              authorImage={course.author?.avatarUrl || ''}
            />
          </Link>
        ))}
      </div>
    </section>
  )
}

export default BestCourses
