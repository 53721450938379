import { appConstants } from '../../constants/constants'
import Student from '../../types/models'

export const isBrowser = typeof window !== 'undefined'

export function set(key: string, value: string) {
  if (isBrowser) {
    try {
      window.localStorage.setItem(key, value)
    } catch {
      console.warn('Error reading from local storage')
    }
  }
}

export function get(key: string) {
  if (!isBrowser) {
    return null
  }

  try {
    const item = window.localStorage.getItem(key)
    return item
  } catch {
    return null
  }
}

export function clear(key: string) {
  if (!isBrowser) {
    return null
  }

  try {
    window.localStorage.removeItem(key)
  } catch {
    return null
  }
}

export const LocalStorage = {
  storeCurrentUser: (user: Student) => {
    if (user) {
      set(appConstants.STORAGE_KEY.CURRENT_USER, JSON.stringify(user))
    }
  },

  getCurrentUser: () => {
    const _user = get(appConstants.STORAGE_KEY.CURRENT_USER)
    try {
      const user = JSON.parse(_user as string)
      return user
    } catch {
      return null
    }
  },

  clearCurrentUser: () => {
    clear(appConstants.STORAGE_KEY.CURRENT_USER)
    clear(appConstants.STORAGE_KEY.ACCESS_TOKEN)
  },

  storeAccessToken: (token: string) => {
    set(appConstants.STORAGE_KEY.ACCESS_TOKEN, token)
  },

  getAccessToken: () => {
    const token = get(appConstants.STORAGE_KEY.ACCESS_TOKEN)

    return token !== null ? token : null
  },

  removeCurrentUser: () => {
    localStorage.removeItem(appConstants.STORAGE_KEY.CURRENT_USER)
    localStorage.removeItem(appConstants.STORAGE_KEY.USER_VERIFIED)
    localStorage.removeItem(appConstants.STORAGE_KEY.ACCESS_TOKEN)
  },

  storeVerificationStatus: (v: string) => {
    set(appConstants.STORAGE_KEY.USER_VERIFIED, v)
  },

  getVerificationStatus: () => {
    const status = get(appConstants.STORAGE_KEY.USER_VERIFIED)

    return status !== null ? status : null
  },
}
