import React from 'react'

// stylesheet
import styles from './detailscard.module.css'

// general imports
import { IconsRepository } from '../../../../repository/icons/icon.repository'
import { IdName } from '../../../../types'
import useWindowSize from '../../../../lib/hooks/useWindowSize'
import { defaultImage } from '../../../../repository/assets'

type Prop = {
  image: string
  title: string
  description?: string
  lessons: number
  length: number
  hasDescription?: boolean
  categories?: IdName[]
}

const CardRef: React.FC<Prop> = ({ image, title, description, lessons, categories }) => {
  const width = useWindowSize().width
  const [cover, setCover] = React.useState(image || defaultImage)

  return (
    <article className={styles.card}>
      <img className={styles.image} src={cover} alt='text' onError={() => setCover(defaultImage)} />
      <div className={styles.content}>
        <h6 className={styles.title}>{title}</h6>
        {description ? <p className={styles.desc}>{description}</p> : <></>}
        <span className={styles.timespan}>
          <span className={styles.cat}>
            <IconsRepository.Category size={width < 500 ? 15 : 20} />
            {categories
              ? categories
                  .map((c) => c.name)
                  .reduce((p, cat) => `${p} ${cat},`, '')
                  ?.slice(0, -1)
              : ''}
          </span>
          <span
            style={{
              color: '#fff',
            }}
          >
            <IconsRepository.PlayCircleFilledIcon size={20} height={21} /> {lessons} lessons{' '}
          </span>
        </span>
      </div>
    </article>
  )
}

export default CardRef
