import React from 'react'
import { Outlet } from 'react-router-dom'

// stylesheet
import styles from '../dashboard.module.css'
import NavbarLoggedin from '../../../components/organisms/navbar/NavbarLoggedin.organism'
import { defaultImage } from '../../../repository/assets'
import DashboardFooter from '../../../components/organisms/footer/DashboardFooter.organism'

// general imports

const DashboardEntry = () => {
  const excludedPaths = ['/dashboard/all-courses']

  return (
    <>
      <NavbarLoggedin userImage={defaultImage} padding='32px 3.2rem' />
      <div className={styles.entry__level}>
        <Outlet />
      </div>
      {excludedPaths.includes(location.pathname) ? null : (
        <div>
          <DashboardFooter />
        </div>
      )}
    </>
  )
}

export default DashboardEntry
