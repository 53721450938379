import React from 'react'

import styles from './featuredCourses.module.css'

// import { courses as _courses } from '../../../../repository/data'
import { defaultImage } from '../../../../repository/assets'
import CardRef from '../../../organisms/cards/details-card/DetailsCard.organism'
import { useAppSelector } from '../../../../lib/hooks'
import { CourseEntityType } from '../../../../types/models'
import { Link } from 'react-router-dom'

const FeaturedCourses: React.FC = () => {
  const { courses } = useAppSelector((state) => state.course)

  return (
    <section className={styles.body}>
      <div className={styles.title}>
        <h1>Our Featured Courses</h1>
        <p>Acquire industry standard skills for free and land you dream job.</p>
      </div>
      <div className={styles.courses}>
        {courses.slice(0, 8).map((course: CourseEntityType) => {
          return (
            <div className={styles.cardDiv} key={course.name}>
              <Link to={`/course-details/${course.id}`}>
                <CardRef
                  categories={course.categories || []}
                  length={course.duration || 0}
                  lessons={course.lessons?.length || 0}
                  title={course.name || 'Course Title'}
                  description={course.description}
                  image={course.cover?.url || defaultImage}
                  hasDescription
                />
              </Link>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default FeaturedCourses
