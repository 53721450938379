import React, { useState } from 'react'
import { ILessonPlan } from '../../../../repository/data/lesson-plan-data'
import { IconsRepository } from '../../../../repository/icons/icon.repository'
import { IdName } from '../../../../types'
import Accordion from '../../../organisms/accordion/Accordion.organism'

import styles from './accordion.module.css'
import timeConvert from '../../../../lib/utils/time-convert'

interface IAccordionProps {
  lessons: IdName[]
}

const LessonAccordion: React.FC<IAccordionProps> = ({ lessons }) => {
  const [active, setActive] = useState(-1)

  const changeActive = (index: number) => {
    setActive((active) => (active === index ? -1 : index))
  }

  return (
    <div className={styles.body}>
      {lessons.map((lesson, i) => {
        return (
          <Accordion
            title={<Header index={i} chapter={lesson.name} key={lesson.id} />}
            content={
              <div className={styles.topics}>
                {lesson.subLessons?.map((topic, i) => {
                  return (
                    <Topic
                      index={i}
                      topic={topic.name || 'No name'}
                      length={Math.random() * 100}
                      key={i}
                    />
                  )
                })}
              </div>
            }
            btnOnClick={() => changeActive(i)}
            icon={<IconsRepository.PlusIcon size={24} />}
            iconClose={<IconsRepository.MinusIcon size={24} />}
            isOpen={i === active}
            key={`${i}`}
            extraClass={styles.customAccordion}
          />
        )
      })}
    </div>
  )
}

interface IHeaderProps extends ILessonPlan {
  index: number
}

const Header: React.FC<IHeaderProps> = ({ index, chapter }) => {
  return (
    <div className={styles.header}>
      <span>{index + 1}</span>
      <h6>{chapter}</h6>
    </div>
  )
}

const Topic: React.FC<{ index: number; topic: string; length: number }> = ({
  topic,
  length,
  index,
}) => {
  return (
    <div className={styles.topic}>
      <span>
        {index === 0 ? (
          <IconsRepository.PlayCircleFilledIcon size={19} />
        ) : (
          <IconsRepository.LockIcon size={19} />
        )}
      </span>
      <div className={styles.details}>
        <p>{topic}</p>
        <span>{timeConvert(length)}</span>
      </div>
    </div>
  )
}

export default LessonAccordion
