import { useState, useEffect, useRef } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import styles from './navbar.module.css'

// general imports
import Button from '../../molecules/button/button.molecule'
import CardRef from '../cards/details-card/DetailsCard.organism'
// import { courses } from '../../../repository/data/course-data'
import { defaultImage, trainingLogo } from '../../../repository/assets'
import { ButtonTypes } from '../../atoms/enums'
import { IconsRepository } from '../../../repository/icons/icon.repository'
import { appPaths } from '../../../constants/app-paths'
import { useAppDispatch, useAppSelector } from '../../../lib/hooks'
import Loader from '../../atoms/loader/Loader'
import { setSearch } from '../../../features/course/slices/course.slice'
import { CourseEntityType } from '../../../types/models'
import { UseScrollPosition } from '../../../lib/hooks/useScrollPosition'

type Props = {
  padding?: string
  isError?: boolean
}

const Navbar = ({ padding, isError }: Props) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const searchInputRef = useRef<HTMLInputElement>(null)
  const searchInputRef2 = useRef<HTMLInputElement>(null)
  const { courses, isLoading } = useAppSelector((state) => state.course)
  const { user, isSuccess } = useAppSelector((state) => state.auth)
  const position = UseScrollPosition()

  const [menuOpen, setMenuOpen] = useState(false)
  const [searchClicked, setSearchClickeed] = useState(false)
  const [typing, setTyping] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  const location = useLocation()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [location])

  const toggleMenuState = () => {
    setMenuOpen((menuOpen) => !menuOpen)
    setSearchClickeed(false)
  }

  const toggleSearchState = (state: boolean) => {
    setSearchClickeed(state)
    // setSearchClickeed((searchClicked) => !searchClicked)
    setMenuOpen(false)
    if (state) {
      setTimeout(() => {
        searchInputRef.current?.focus()
      }, 50)
    }
  }

  const searchData = (_data: Array<CourseEntityType>) => {
    const data = [..._data]
    try {
      const searchResult = data.filter((course) => {
        const regex = new RegExp(
          `${['+', '-'].includes(searchTerm) ? `\\${searchTerm}` : searchTerm}`,
          'ig',
        )
        if (regex.test(course.name || '')) return true
        if (regex.test(course.author?.name || '')) return true
        return false
      })

      return searchResult
    } catch (e) {
      // console.error(e);
      return data
    }
  }

  const filteredCourses = searchData(courses)

  return (
    <>
      {isError ? (
        <div style={{ padding: '32px var(--px)', paddingBottom: '0', position: 'fixed', top: 0 }}>
          <Link to='/'>
            <IconsRepository.LogoIcon />
          </Link>
        </div>
      ) : (
        <nav
          style={{
            padding: padding !== undefined ? padding : '32px var(--px)',
          }}
          className={`${styles.navbar} ${position > 5 ? styles.shadow : ''}`}
        >
          <div className={styles.mobileNav}>
            <span className={styles.btnContainer} style={{ width: '32px', height: '32px' }}>
              <button
                className={`${styles.navButton} ${
                  !menuOpen ? styles.activeButton : styles.inActiveButton
                }`}
                onClick={toggleMenuState}
              >
                <IconsRepository.NavMenuIcon />
              </button>
              <button
                className={`${styles.navButton} ${
                  menuOpen ? styles.activeButton : styles.inActiveButton
                }`}
                onClick={toggleMenuState}
              >
                <IconsRepository.CloseIcon size={20} height={19} />
              </button>
            </span>
            <span>
              <Link to={'/'} reloadDocument>
                <span style={{ display: `${searchClicked ? 'none' : 'block'}` }}>
                  <IconsRepository.LogoAndDepartmentIcon />
                </span>
              </Link>
              <div
                className={styles.inputDivMobile}
                style={{ display: `${searchClicked ? 'block' : 'none'}` }}
              >
                <input
                  ref={searchInputRef}
                  onChange={(event) => {
                    setTyping(true)
                    setSearchTerm(event.target.value)
                  }}
                  onKeyDown={(event: React.KeyboardEvent) => {
                    if (event.which === 13) {
                      dispatch(setSearch(searchTerm))
                      navigate('/search-results')
                    }
                  }}
                  type='text'
                  placeholder='Search for a course'
                />
                <button className={`${styles.navButton} ${styles.inputSearch}`}>
                  <IconsRepository.SearchIcon size={16} height={16} />
                </button>
                <div
                  className={`${styles.suggestionCardMobile} ${
                    typing ? styles.suggestionCardMobileActive : ''
                  }`}
                >
                  <div className={styles.suggestedDiv}>
                    <p className={styles.suggested}>Suggested courses</p>
                    <p className={styles.suggestedText}>
                      Press enter or click on view all courses link to view the complete list of
                      results.
                    </p>
                  </div>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <div className={styles.cardsDiv}>
                      {filteredCourses.length > 0 ? (
                        filteredCourses.slice(0, 2).map((course) => (
                          <Link
                            to={`/course-details/${course.id}`}
                            key={course.id}
                            className={styles.card}
                          >
                            <CardRef
                              categories={course.categories || []}
                              image={course.cover?.url as string}
                              title={course.name || ''}
                              description={course.description || ''}
                              lessons={course.lessons?.length || 0}
                              length={course.duration || 0}
                              hasDescription={false}
                            />
                          </Link>
                        ))
                      ) : (
                        <h2>No courses found</h2>
                      )}
                    </div>
                  )}
                  <div className={styles.allSuggestionsDiv}>
                    <p>
                      <Link
                        to='/search-results'
                        onClick={() => {
                          dispatch(setSearch(searchTerm))
                        }}
                      >
                        All suggestions
                      </Link>
                    </p>
                    <IconsRepository.ArrowRight size={18} height={16} />
                  </div>
                </div>
              </div>
            </span>
            <span className={styles.btnContainer} style={{ width: '32px', height: '32px' }}>
              <button
                className={`${styles.navButton} ${
                  !searchClicked ? styles.activeButton : styles.inActiveButton
                }`}
                onClick={() => toggleSearchState(true)}
              >
                <IconsRepository.SearchIcon size={24} height={25} />
              </button>
              <button
                className={`${styles.navButton} ${
                  searchClicked ? styles.activeButton : styles.inActiveButton
                }`}
                onClick={() => {
                  toggleSearchState(false)
                  setTyping(false)
                }}
              >
                <IconsRepository.CloseIcon size={20} height={20} />
              </button>
            </span>
          </div>

          <div className={styles.logoDiv}>
            <Link
              style={{
                width: '147px',
              }}
              to={user !== null && isSuccess ? '/dashboard' : '/'}
            >
              <img
                style={{
                  width: '147px',
                }}
                src={trainingLogo}
                alt='camsol logo'
              />
            </Link>
          </div>
          <div
            className={`${styles.navLinkDiv} ${searchClicked ? styles.navLinkDivInactive : ''} ${
              menuOpen ? styles.navLinkDiv : styles.navLinkDivInactiveMobile
            }`}
          >
            <Link className={styles.navLink} to={'/all-courses'}>
              All Courses
            </Link>
            <Link className={styles.navLink} to={'/course-category'}>
              Categories
            </Link>
            <Link className={styles.navLink} to={'https://datagirltech.com/about-us/'}>
              About Us
            </Link>
            <div className={styles.mobileButtons}>
              <Link
                style={{
                  cursor: 'pointer',
                  textDecoration: 'none',
                }}
                to={appPaths.LOGIN}
              >
                <button
                  className={`${styles.buttonLogin} ${
                    menuOpen ? styles.buttonDivMobileActive : styles.buttonDivMobileInactive
                  }`}
                >
                  Login
                </button>
              </Link>
              <Link
                style={{
                  cursor: 'pointer',
                  textDecoration: 'none',
                }}
                to='/auth/register'
              >
                <button
                  className={`${
                    menuOpen ? styles.buttonDivMobileActive : styles.buttonDivMobileInactive
                  }`}
                >
                  Sign up
                </button>
              </Link>
            </div>
          </div>
          <div className={`${styles.buttonDiv}`}>
            <button
              onClick={() => {
                setSearchClickeed(true)
                setTimeout(() => {
                  searchInputRef2.current?.focus()
                }, 50)
              }}
              style={{
                backgroundColor: 'white',
                border: 'none',
                display: `${searchClicked ? 'none' : 'block'}`,
                cursor: 'pointer',
              }}
            >
              <IconsRepository.SearchIcon />
            </button>
            <div
              className={`${styles.inputFieldDiv} ${
                searchClicked ? styles.inputFieldDivActive : ''
              }`}
            >
              <button className={`${styles.iconButton} ${styles.searchButton}`}>
                <IconsRepository.SearchIcon />
              </button>
              <button
                className={`${styles.iconButton} ${styles.closeButton}`}
                onClick={() => {
                  setSearchClickeed(false)
                  setTyping(false)
                }}
              >
                <IconsRepository.CloseIcon />
              </button>
              <input
                ref={searchInputRef2}
                className={styles.inputField}
                onChange={(event) => {
                  setTyping(true)
                  setSearchTerm(event.target.value)
                }}
                onKeyDown={(event: React.KeyboardEvent) => {
                  if (event.which === 13) {
                    dispatch(setSearch(searchTerm))
                    navigate('/search-results')
                  }
                }}
                type='text'
                placeholder='Search for a course'
              />
              <div
                className={`${styles.suggestionCard} ${typing ? styles.suggestionCardActive : ''}`}
              >
                <div className={styles.suggestedDiv}>
                  <p className={styles.suggested}>Suggested courses</p>
                  <p className={styles.suggestedText}>
                    Press enter or click on view all courses link to view the complete list of
                    results.
                  </p>
                </div>
                {isLoading ? (
                  <Loader />
                ) : (
                  <div className={styles.cardsDiv}>
                    {filteredCourses.length > 0 ? (
                      filteredCourses.slice(0, 2).map((course) => (
                        <Link
                          to={`/course-details/${course.id}`}
                          key={course.id}
                          className={styles.card}
                        >
                          <CardRef
                            categories={course.categories || []}
                            image={course.cover?.url || defaultImage}
                            title={course.name || ''}
                            description={course.description || ''}
                            lessons={course.lessons?.length || 0}
                            length={course.duration || 0}
                            hasDescription={false}
                          />
                        </Link>
                      ))
                    ) : (
                      <h2>No courses found</h2>
                    )}
                  </div>
                )}
                <div className={styles.allSuggestionsDiv}>
                  <p>
                    <Link
                      to='/search-results'
                      onClick={() => {
                        dispatch(setSearch(searchTerm))
                      }}
                    >
                      All suggestions
                    </Link>
                  </p>
                  <IconsRepository.ArrowRight size={18} height={16} />
                </div>
              </div>
            </div>
            <Link
              style={{
                cursor: 'pointer',
                textDecoration: 'none',
              }}
              className={styles.loginBtnWrapper}
              to={appPaths.LOGIN}
            >
              <Button
                className={styles.btn__login}
                text='Login'
                fontSize='16px'
                type={ButtonTypes.SECONDARY}
                height='48px'
              />
            </Link>
            <Link
              style={{
                cursor: 'pointer',
                textDecoration: 'none',
              }}
              to={appPaths.REGISTER}
            >
              <Button
                className={styles.btn__end}
                text='Sign up'
                fontSize='16px'
                type={ButtonTypes.PRIMARY}
                height='48px'
              />
            </Link>
          </div>
        </nav>
      )}
    </>
  )
}

export default Navbar
