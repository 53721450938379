/* eslint-disable */
import React, { useState } from 'react'

// stylesheet
import styles from '../modules.module.css'

// general imports
import CustomInputField from '../../../../../components/molecules/input/CustomInput.molecule'
import Button from '../../../../../components/molecules/button/button.molecule'
import { ButtonTypes } from '../../../../../components/atoms/enums'
import { useAppDispatch, useAppSelector } from '../../../../../lib/hooks'
import Loader from '../../../../../components/atoms/loader/Loader'
import Popup from '../../../../../components/organisms/popup/Popup.organism'
import { updateProfileThunkService } from '../../../../../features/authentication/authenticationThunks'
import { LocalStorage } from '../../../../../services/storage/Local.storage'

// import OpenLock from '../../../../Icons/OpenLock'
// import { Lock } from '../../../../Icons'

const Settings = () => {
  const authState = useAppSelector((state) => state.authentication)
  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [formData, setFormData] = useState({
    firstName: authState.user?.first_name,
    lastName: authState.user?.last_name,
    email: authState.user?.email,
    confirmEmail: authState.user?.email,
  })
  const { firstName, lastName, confirmEmail, email } = formData

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    console.log(formData)
    console.log(LocalStorage.getAccessToken())
    setLoading(true)
    dispatch(
      updateProfileThunkService({
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
      }),
    )
      .then(() => {
        setSuccess(true)
      })
      .catch(() => {
        setError(true)
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className={styles.module}>
      {loading ? <Loader /> : <></>}
      {success ? (
        <Popup
          toggleActive={setSuccess}
          active={success}
          title='success'
          msg={'Successfully Updated!'}
        />
      ) : (
        <></>
      )}
      {error ? (
        <Popup
          toggleActive={setError}
          active={error}
          title='error'
          msg={'Something went wrong. Please Try Again'}
        />
      ) : (
        <></>
      )}
      <div className={styles.head}>
        <h2>Basic Info</h2>
        <h3>Change user personal information here</h3>
      </div>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.form__control}>
          <div className={styles.input__wrapper}>
            <div className={styles.label}>
              <label htmlFor='first name'>First Name</label>
            </div>
            <CustomInputField
              name='firstName'
              value={firstName}
              onChange={onChange}
              width='100%'
              placeholder='Alane'
            />
          </div>

          <div className={styles.input__wrapper}>
            <div className={styles.label}>
              <label htmlFor='last name'>Last Name</label>
            </div>
            <CustomInputField
              name='lastName'
              value={lastName}
              onChange={onChange}
              width='100%'
              placeholder='Johan'
            />
          </div>
        </div>
        <div className={styles.form__control}>
          <div className={styles.input__wrapper}>
            <div className={styles.label}>
              <label htmlFor='email'>Email</label>
            </div>
            <CustomInputField
              name='email'
              value={email}
              onChange={onChange}
              width='100%'
              placeholder='alanejohan@gmail.com'
              disabled
            />
          </div>

          <div className={styles.input__wrapper}>
            <div className={styles.label}>
              <label htmlFor='confirm email'>Confirm Email</label>
            </div>
            <CustomInputField
              name='confirmEmail'
              value={confirmEmail}
              onChange={onChange}
              width='100%'
              placeholder='alanejohan@gmail.com'
              disabled
            />
          </div>
        </div>

        {/* <div className={styles.form__control}>
          <div className={styles.input__wrapper}>
            <div className={styles.label}>
              <label htmlFor='password'>Old Password</label>
            </div>
            <CustomInputField
              name='password'
              value={password}
              onChange={onChange}
              // left={true}
              width='100%'
              placeholder=''
              type='password'
              // type={passwordShow ? 'text' : 'password'}
              // icon={
              //   passwordShow ? (
              //     <OpenLock
              //       onClick={() => {
              //         handleShowPassword(true)
              //       }}
              //       style={{ cursor: 'pointer' }}
              //     />
              //   ) : (
              //     <Lock
              //       onClick={() => {
              //         handleShowPassword(true)
              //       }}
              //       style={{ cursor: 'pointer' }}
              //     />
              //   )
              // }
            />
          </div>

          <div className={styles.input__wrapper}>
            <div className={styles.label}>
              <label htmlFor='new password'>New Password</label>
            </div>
            <CustomInputField
              // left={true}
              name='confirmPassword'
              value={confirmPassword}
              onChange={onChange}
              width='100%'
              placeholder=''
              type='password'
              // type={showNewPassword ? 'text' : 'password'}
              // icon={
              //   showNewPassword ? (
              //     <OpenLock
              //       onClick={() => {
              //         handleShowPassword(false)
              //       }}
              //       style={{ cursor: 'pointer' }}
              //     />
              //   ) : (
              //     <Lock
              //       onClick={() => {
              //         handleShowPassword(false)
              //       }}
              //       style={{ cursor: 'pointer' }}
              //     />
              //   )
              // }
            />
          </div>
        </div> */}

        <div
          style={{
            marginBottom: '0',
          }}
          className={styles.form__control}
        >
          <Button
            type={ButtonTypes.PRIMARY}
            text={'Update Information'}
            width='100%'
            height='48px'
            fontSize='1.6rem'
          />
        </div>
      </form>
    </div>
  )
}

export default Settings
