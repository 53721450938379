import React from 'react'
import { IconProps } from '../types'

const StarOutlinedIcon = ({
  size = 35,
  color = 'currentColor',
  stroke = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      width={size}
      height='35'
      stroke={`${stroke}`}
      viewBox='0 0 28 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M27.3333 9.98683L17.7467 9.16016L14 0.333496L10.2533 9.1735L0.666656 9.98683L7.94666 16.2935L5.75999 25.6668L14 20.6935L22.24 25.6668L20.0667 16.2935L27.3333 9.98683ZM14 18.2002L8.98666 21.2268L10.32 15.5202L5.89332 11.6802L11.7333 11.1735L14 5.80016L16.28 11.1868L22.12 11.6935L17.6933 15.5335L19.0267 21.2402L14 18.2002Z'
        fill={color}
      />
    </svg>
  )
}

export default StarOutlinedIcon
