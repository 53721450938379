import React from 'react'

// stylesheet
import styles from './toggle.module.css'

type TogglerProps = {
  toggled: boolean | number
  setToggleState: (state: number | boolean) => void
  index?: number
}

const Toggle: React.FC<TogglerProps> = ({ toggled, index, setToggleState }) => {
  return (
    <div
      onClick={() => {
        if (index !== undefined) {
          setToggleState(index)
        } else {
          setToggleState(!toggled)
        }
      }}
      className={`${styles.toggle} ${toggled ? styles.toggled : ''}`}
    >
      <div className={styles.bubble}></div>
    </div>
  )
}

export default Toggle
