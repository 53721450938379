import React from 'react'
import { IconProps } from '../types'

const WorkspacePremiumIcon = ({
  size = 24,
  color = 'currentColor',
  stroke = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      stroke={`${stroke}`}
      viewBox='0 0 16 16'
      fill='none'
      {...props}
    >
      <path
        d='M6.45366 8.79333L8.00033 7.62L9.54033 8.79333L8.95366 6.89333L10.5003 5.66667H8.60699L8.00033 3.79333L7.39366 5.66667H5.50033L7.04033 6.89333L6.45366 8.79333ZM13.3337 6.33333C13.3337 3.38667 10.947 1 8.00033 1C5.05366 1 2.66699 3.38667 2.66699 6.33333C2.66699 7.68667 3.17366 8.91333 4.00033 9.85333V15L8.00033 13.6667L12.0003 15V9.85333C12.827 8.91333 13.3337 7.68667 13.3337 6.33333ZM8.00033 2.33333C10.207 2.33333 12.0003 4.12667 12.0003 6.33333C12.0003 8.54 10.207 10.3333 8.00033 10.3333C5.79366 10.3333 4.00033 8.54 4.00033 6.33333C4.00033 4.12667 5.79366 2.33333 8.00033 2.33333ZM8.00033 12.3333L5.33366 13.0133V10.9467C6.12033 11.4 7.02699 11.6667 8.00033 11.6667C8.97366 11.6667 9.88033 11.4 10.667 10.9467V13.0133L8.00033 12.3333Z'
        fill={color}
      />
    </svg>
  )
}

export default WorkspacePremiumIcon
