import React from 'react'

import styles from './custominputfield.module.css'

type Props = {
  placeholder: string
  icon?: JSX.Element
  rightIcon?: JSX.Element
  left?: boolean
  right?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  name?: string
  // eslint-disable-next-line
  value?: any
  type?: string
  width?: string
  color?: string
  marginBottom?: string
  disabled?: boolean
  id?: string
}

const CustomInputField: React.FC<Props> = ({
  placeholder,
  icon,
  rightIcon,
  left,
  right,
  onChange,
  name,
  value,
  type,
  width,
  color,
  marginBottom,
  disabled,
  id,
}) => {
  return (
    <>
      <div className={styles.container}>
        <input
          value={value}
          name={name}
          type={type}
          id={id}
          onChange={onChange}
          // width={'360px'}
          className={`${styles.input} ${left !== undefined && left ? styles.left : ''} ${
            right ? styles.right : ''
          }`}
          placeholder={placeholder}
          style={{
            width: `${width ? width : ''}`,
            color: `${color ? color : 'black'}`,
            marginBottom: `${marginBottom ? marginBottom : ''}`,
          }}
          disabled={disabled}
        />
        <span className={styles.iconLeft}>{icon}</span>
        <span className={styles.iconRight}>{rightIcon}</span>
      </div>
    </>
  )
}

export default CustomInputField
