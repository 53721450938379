import React from 'react'
import { IconProps } from '../types'

const ShareIcon = ({ size = 40, color = 'currentColor', stroke = 2, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      stroke={`${stroke}`}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M26.25 24.2917C25.4583 24.2917 24.75 24.6042 24.2083 25.0938L16.7812 20.7708C16.8333 20.5312 16.875 20.2917 16.875 20.0417C16.875 19.7917 16.8333 19.5521 16.7812 19.3125L24.125 15.0312C24.6875 15.5521 25.4271 15.875 26.25 15.875C27.9792 15.875 29.375 14.4792 29.375 12.75C29.375 11.0208 27.9792 9.625 26.25 9.625C24.5208 9.625 23.125 11.0208 23.125 12.75C23.125 13 23.1667 13.2396 23.2188 13.4792L15.875 17.7604C15.3125 17.2396 14.5729 16.9167 13.75 16.9167C12.0208 16.9167 10.625 18.3125 10.625 20.0417C10.625 21.7708 12.0208 23.1667 13.75 23.1667C14.5729 23.1667 15.3125 22.8437 15.875 22.3229L23.2917 26.6562C23.2396 26.875 23.2083 27.1042 23.2083 27.3333C23.2083 29.0104 24.5729 30.375 26.25 30.375C27.9271 30.375 29.2917 29.0104 29.2917 27.3333C29.2917 25.6562 27.9271 24.2917 26.25 24.2917ZM26.25 11.7083C26.8229 11.7083 27.2917 12.1771 27.2917 12.75C27.2917 13.3229 26.8229 13.7917 26.25 13.7917C25.6771 13.7917 25.2083 13.3229 25.2083 12.75C25.2083 12.1771 25.6771 11.7083 26.25 11.7083ZM13.75 21.0833C13.1771 21.0833 12.7083 20.6146 12.7083 20.0417C12.7083 19.4688 13.1771 19 13.75 19C14.3229 19 14.7917 19.4688 14.7917 20.0417C14.7917 20.6146 14.3229 21.0833 13.75 21.0833ZM26.25 28.3958C25.6771 28.3958 25.2083 27.9271 25.2083 27.3542C25.2083 26.7812 25.6771 26.3125 26.25 26.3125C26.8229 26.3125 27.2917 26.7812 27.2917 27.3542C27.2917 27.9271 26.8229 28.3958 26.25 28.3958Z'
        fill={color}
      />
      <rect x='0.5' y='0.5' width='39' height='39' rx='3.5' stroke='#5C187A' />
    </svg>
  )
}

export default ShareIcon
