import React from 'react'
import styles from './peek.module.css'

import {
  language as languageIcon,
  lessons as lessonIcon,
  Vector,
  price as priceIcon,
  tick,
} from '../../../../repository/assets/svg'
import { ButtonTypes } from '../../../atoms/enums'
import Button from '../../../molecules/button/button.molecule'
import { CourseEntityType } from '../../../../types/models'
import { Link } from 'react-router-dom'
import { appPaths } from '../../../../constants/app-paths'
import formatNumber from '../../../../lib/utils/formatNumber'

const BootcampPeek: React.FC<CourseEntityType> = ({
  categories,
  lessons,
  language,
  description,
  price,
}) => {
  return (
    <div className={styles.rootContenaire}>
      <div className={styles.rootGrid}>
        <div className={styles.gridItem}>
          <p className={styles.peek}>Take a peek at what you will get inside the Bootcamp</p>
          <p className={styles.description}>
            If you want to move from being a complete beginner to creating awesome interactive
            designs, search no more. Enroll now and follow the courses that make up this bootcamp
            and join the adventure.
          </p>
          <Link to={appPaths.REGISTER}>
            <Button
              text={'Enroll into Course 1'}
              type={ButtonTypes.PRIMARY}
              fontSize='16px'
              className={styles.button}
            />
          </Link>
        </div>

        <div className={styles.gridItem2}>
          <p className={styles.course}>Course Content</p>
          <div className={styles.textIcon}>
            <img src={Vector} className={styles.Vector} />
            <p className={styles.category}>
              {' '}
              Category:{' '}
              {categories?.map((cat, i, arr) => {
                return `${cat.name}${i < arr.length - 1 ? ',' : ''} `
              })}
            </p>
          </div>
          <div className={styles.textIcon}>
            <img src={lessonIcon} className={styles.Vector} />
            <p className={styles.category}> Lessons: {lessons?.length || 0} Lesson(s) </p>
          </div>
          <div className={styles.textIcon}>
            <img src={languageIcon} className={styles.Vector} />
            <p className={styles.lang}>{language}</p>
          </div>
          <div className={styles.textIcon}>
            <img src={priceIcon} className={styles.Vector} />
            <p className={styles.lang}>
              Price:{' '}
              <span className={styles.lang} style={{ color: 'var(--platform-secondary-500)' }}>
                {formatNumber(price || 0)}frs
              </span>
            </p>
          </div>

          <p className={styles.youWillLearn}>You will learn</p>
          <div className={styles.textIcon}>
            <img src={tick} className={styles.Vector} />
            <p className={styles.tick1}> Ideation, sketching, wireframing </p>
          </div>
          <div className={styles.textIcon}>
            <img src={tick} className={styles.Vector} />
            <p className={styles.tick2}> Prototyping Low Fidelity: Paper Prototyping </p>
          </div>
          <div className={styles.textIcon}>
            <img src={tick} className={styles.Vector} />
            <p className={styles.tick2}> High Fidelity Design </p>
          </div>
          <div className={styles.textIcon}>
            <img src={tick} className={styles.Vector} />
            <p className={styles.tick1}> User Testing </p>
          </div>
        </div>

        <div className={styles.gridItem3}>
          <div>
            <p className={styles.courseDescription}>Requirements</p>
            <p className={styles.inThisCourse}>
              There are no prerequisites or requirements for this course. Just internet connection
              and a pc or mobile phone will be sufficient.
            </p>
          </div>
          <div>
            <p className={styles.courseDescription}>Registration Process</p>
            <p className={styles.inThisCourse}>
              There are no prerequisites or requirements for this course. Just internet connection
              and a pc or mobile phone will be sufficient.3 months course on Full-stack Web
              Development 6 weeks course on Design Tentative Start Date: Every 3 Months
            </p>
          </div>
          <div>
            <p className={styles.courseDescription}> Description </p>
            <p className={styles.inThisCourse}>{description}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BootcampPeek
