import React from 'react'
// import { Link } from 'react-router-dom'

// stylesheet
import styles from './dashboardfooter.module.css'

// general imports
import { logo } from '../../../repository/assets'
import { ButtonTypes } from '../../atoms/enums'
import Button from '../../molecules/button/button.molecule'
import CustomInputField from '../../molecules/input/CustomInput.molecule'
import { Link } from 'react-router-dom'

const DashboardFooter = () => {
  return (
    <footer className={styles.body}>
      <div style={{ width: '100%', borderTop: '1px solid black' }} />
      <div className={styles.container}>
        <div className={styles.upperHalf}>
          <div className={styles.logoDiv}>
            <img src={logo} alt='Camsol Logo' />
            <p>
              Empowering people through technology. We offer quality and ICT and Computer training
              services in Cameroon.
            </p>
          </div>
          <div className={styles.controlDiv}>
            <Link to={'/all-courses'} className={styles.link}>
              All Courses
            </Link>
            <Link to={'/dashboard/profile'} className={styles.link}>
              Settings
            </Link>
            <Link to={'/dashboard/my-courses'} className={styles.link}>
              My courses
            </Link>
            <Link to={'/dashboard/profile'} className={styles.link}>
              profile
            </Link>
          </div>
          <form
            className={`${styles.emailDiv} validate`}
            action='https://datagirltech.us5.list-manage.com/subscribe/post?u=7e166b48e7483aab3f85badf1&amp;id=8087bace1c&amp;f_id=00d2c8edf0'
            target='__blank'
            method='post'
            id='mc-embedded-subscribe-form'
            name='mc-embedded-subscribe-form'
          >
            <p>Subscribe to our newsletter</p>
            <div aria-hidden='true' style={{ position: 'absolute', left: '-5000px' }}>
              <input
                type='text'
                name='b_7e166b48e7483aab3f85badf1_8087bace1c'
                tabIndex={-1}
                value=''
              />
            </div>
            <div className={styles.inputDiv}>
              <CustomInputField
                placeholder={'Enter your email'}
                type={'email'}
                width={'242px'}
                color={'#a9a9a9'}
                name='EMAIL'
                id='mce-EMAIL'
              />
              <Button
                formType='submit'
                text={'Send'}
                className={styles.btn}
                type={ButtonTypes.PRIMARY}
                height='48px'
                fontSize='1.6rem'
              />
            </div>
          </form>
        </div>
        <div style={{ width: '100%', borderTop: '1px solid black', margin: '24px 0px' }} />
        <div className={styles.lowerHalf}>
          <div className={styles.social}>
            <span>Connect:</span>
            <a target='__blank' href='https://www.linkedin.com/company/data-girl-technologies/'>
              Linkedin
            </a>
            <a href='https://www.facebook.com/datagirltech' target='__blank'>
              Facebook
            </a>
            <a target='__blank' href='https://twitter.com/datagirltech1'>
              Twitter
            </a>
            <a target='__blank' href='https://www.youtube.com/channel/UCsEwWYC0KSGtES41b_dmZtw'>
              YouTube
            </a>
            <a target='__blank' href='https://www.tiktok.com/@datagirltech'>
              Tiktok
            </a>
            <a href='https://www.instagram.com/datagirltech/' target='__blank'>
              Instagram
            </a>
          </div>
          <p className={styles.copyright}>© 2024 DataGirl Technologies. All rights reserved</p>
        </div>
      </div>
    </footer>
  )
}

export default DashboardFooter
