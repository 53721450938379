import React from 'react'

import styles from './popup.module.css'
import { IconsRepository } from '../../../repository/icons/icon.repository'

type Props = {
  title: string
  msg: string
  active?: boolean
  toggleActive?: React.Dispatch<React.SetStateAction<boolean>>
}

const Popup: React.FC<Props> = ({ msg, title, toggleActive, active }) => {
  return (
    <div className={`${styles.popup__wrapper} ${active === true ? styles.show : ''}`}>
      <div className={`${title === 'error' ? styles.error : styles.success} ${styles.state}`}>
        {title === 'error' ? <IconsRepository.FailedIcon /> : <IconsRepository.CheckCircleIcon />}
      </div>
      <div className={styles.content}>
        <h1>{title === 'error' ? 'Error' : 'Successful'}</h1>
        <p>{msg}</p>
      </div>
      <div
        onClick={() => {
          if (toggleActive) {
            toggleActive(false)
          }
        }}
        className={styles.cancel}
      >
        <p>Cancel</p>
      </div>
    </div>
  )
}

export default Popup
