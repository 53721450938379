import React from 'react'
import { IconProps } from '../types'

const PlayIcon = ({ size = 18, color = 'currentColor', stroke = 2, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      stroke={`${stroke}`}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M9.00033 0.666664C4.40033 0.666664 0.666992 4.4 0.666992 9C0.666992 13.6 4.40033 17.3333 9.00033 17.3333C13.6003 17.3333 17.3337 13.6 17.3337 9C17.3337 4.4 13.6003 0.666664 9.00033 0.666664ZM9.00033 15.6667C5.32533 15.6667 2.33366 12.675 2.33366 9C2.33366 5.325 5.32533 2.33333 9.00033 2.33333C12.6753 2.33333 15.667 5.325 15.667 9C15.667 12.675 12.6753 15.6667 9.00033 15.6667ZM6.91699 12.75L12.7503 9L6.91699 5.25V12.75Z'
        fill={color}
      />
    </svg>
  )
}

export default PlayIcon
