import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import ImageContainer from '../../../components/molecules/image-container/ImageContainer.module'
import Block3 from '../../../components/templates/forgot-password/Block3.template'
import Block4 from '../../../components/templates/forgot-password/Block4.template'
import { appPaths } from '../../../constants/app-paths'
import useWindowSize from '../../../lib/hooks/useWindowSize'
import { forgotPasswordImage } from '../../../repository/assets'
import { IconsRepository } from '../../../repository/icons/icon.repository'

import styles from '../login/login.module.css'
import emailverificationstyles from './forgotpassword.module.css'
import Block1 from '../../../components/templates/forgot-password/Block1.template'
import Block5 from '../../../components/templates/forgot-password/Block5.template'

const ForgotPassword = () => {
  const [block, setBlock] = useState<number>(0)
  const { width } = useWindowSize()

  const [email, setEmail] = useState('')
  const [pin, setPin] = useState('')

  return (
    <div className={styles.parent__div}>
      <div className={styles.input__div}>
        <div
          className={`${emailverificationstyles.logo__styles} ${
            block === 3 ? emailverificationstyles.logoLeft : ''
          }`}
        >
          <Link to='/'>
            {width < 900 ? (
              <span>
                <IconsRepository.LogoIcon />
              </span>
            ) : (
              <IconsRepository.LogoIcon />
            )}
          </Link>
        </div>
        <div className={styles.content}>
          {block === 0 && <Block1 setEmail={setEmail} setBlock={setBlock} />}
          {block === 1 && <Block3 email={email} setBlock={setBlock} pin={pin} setPin={setPin} />}
          {block === 2 && <Block4 />}
          {block === 3 && (
            <Block5 email={email} setEmail={setEmail} setBlock={setBlock} pin={pin} />
          )}
          {block !== 2 && (
            <div className={styles.form__control}>
              <h4>
                <span className={emailverificationstyles.link}>
                  <Link to={appPaths.LOGIN}>
                    <IconsRepository.ArrowLeftIcon />
                    <p> Back to login</p>
                  </Link>
                </span>
              </h4>
            </div>
          )}
        </div>
      </div>
      <div className={styles.img__container}>
        <ImageContainer
          image={
            <img
              src={forgotPasswordImage}
              alt='image'
              width={466}
              height={404}
              style={
                {
                  // objectFit: 'cover',
                }
              }
            />
          }
        />
      </div>
    </div>
  )
}

export default ForgotPassword
