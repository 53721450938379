import React from 'react'
import { useNavigate } from 'react-router-dom'

// stylesheet
import styles from './sidepanel.module.css'

// general imports
import { IconsRepository } from '../../../../../repository/icons/icon.repository'
import Button from '../../../../../components/molecules/button/button.molecule'
import { ButtonTypes, IconDirection } from '../../../../../components/atoms/enums'
import { useAppDispatch } from '../../../../../lib/hooks'
import { LocalStorage } from '../../../../../services/storage/Local.storage'
import { appPaths } from '../../../../../constants/app-paths'
import { resetAuthenticationState } from '../../../../../features/authentication/authenticationSlice'

export type SidePanelProps = {
  image: string
  name: string
  email: string
  enrolledCourses: number
  completedCourses: number
  learningTime: string
  dueAssessments: number
  completedAssessments: number
}

const SidePanel = ({ image, name, email, enrolledCourses, completedCourses }: SidePanelProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  return (
    <div className={styles.side__panel}>
      <div className={styles.image}>
        <img src={image} alt={name} />
      </div>
      <h2>{name}</h2>
      <h3>{email}</h3>

      <div className={styles.svg}>
        <svg
          width='288'
          height='1'
          viewBox='0 0 288 1'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <line y1='0.5' x2='288' y2='0.5' stroke='url(#paint0_linear_2078_12232)' />
          <defs>
            <linearGradient
              id='paint0_linear_2078_12232'
              x1='288'
              y1='1'
              x2='0'
              y2='1'
              gradientUnits='userSpaceOnUse'
            >
              <stop stopColor='#AFAFAF' stopOpacity='0' />
              <stop offset='0.515625' stopColor='#AFAFAF' />
              <stop offset='1' stopColor='#C5C5C5' stopOpacity='0' />
            </linearGradient>
          </defs>
        </svg>
      </div>

      <div className={styles.info}>
        <div className={styles.data}>
          <div className={styles.icon}>
            <span style={{ color: 'var(--platform-secondary-700)' }}>
              <IconsRepository.MenuBookIcon size={16} />
            </span>
          </div>
          <h4>
            Enrolled Courses: <span>{enrolledCourses}</span>
          </h4>
        </div>

        <div className={styles.data}>
          <div className={styles.icon}>
            <span style={{ color: 'var(--platform-secondary-700)' }}>
              <IconsRepository.MenuBookIcon size={16} />
            </span>
          </div>
          <h4>
            Completed Courses: <span>{completedCourses}</span>
          </h4>
        </div>

        {/* <div className={styles.data}>
          <div className={styles.icon}>
            <span style={{ color: '#5C187A' }}>
              <IconsRepository.SignalCellularAltIcon size={16} />
            </span>
          </div>
          <h4>
            Learning Time: <span>{learningTime}</span>
          </h4>
        </div> */}

        {/* <div className={styles.data}>
          <div className={styles.icon}>
            <span style={{ color: '#5C187A' }}>
              <IconsRepository.MenuBookIcon size={16} />
            </span>
          </div>
          <h4>
            Assessments Completed: <span>{completedAssessments}</span>
          </h4>
        </div> */}
      </div>

      <div className={styles.svg}>
        <svg
          width='288'
          height='1'
          viewBox='0 0 288 1'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <line y1='0.5' x2='288' y2='0.5' stroke='url(#paint0_linear_2078_12232)' />
          <defs>
            <linearGradient
              id='paint0_linear_2078_12232'
              x1='288'
              y1='1'
              x2='0'
              y2='1'
              gradientUnits='userSpaceOnUse'
            >
              <stop stopColor='#AFAFAF' stopOpacity='0' />
              <stop offset='0.515625' stopColor='#AFAFAF' />
              <stop offset='1' stopColor='#C5C5C5' stopOpacity='0' />
            </linearGradient>
          </defs>
        </svg>
      </div>

      <div style={{ marginTop: '2.4rem' }} className={styles.btn__logout}>
        <Button
          type={ButtonTypes.PRIMARY}
          text={'Logout'}
          iconDir={IconDirection.LEFT}
          icon={
            <>
              <IconsRepository.LogoutIcon />
            </>
          }
          onClick={() => {
            dispatch(resetAuthenticationState())
            LocalStorage.removeCurrentUser()
            localStorage.removeItem('email_tracker')
            navigate(appPaths.HOME_PAGE)
          }}
          width='100%'
          className={styles.btn}
          height='48px'
          fontSize='1.6rem'
        />
      </div>
    </div>
  )
}

export default SidePanel
