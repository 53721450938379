import React from 'react'
import { IconsRepository } from '../../../repository/icons/icon.repository'

import styles from '../progressbar/progressbar.module.css'

type ProgressBarTypes = {
  bgcolor: string
  completed: number
  heading?: string
}

const ShadowedProgressBar: React.FC<ProgressBarTypes> = ({ bgcolor, completed, heading }) => {
  return (
    <div className={`${styles.parent__container} ${styles.more}`}>
      <h2 className={styles.course__heading}>
        {heading !== undefined ? heading : 'The complete UI/UX Bootcamp'}
      </h2>

      <div className={styles.container}>
        <div
          className={styles.filler__styles}
          style={{ backgroundColor: bgcolor, width: `${completed}%` }}
        >
          <span className={styles.label}>{`${completed}%`}</span>
        </div>
      </div>
      <div className={styles.percentage__completed}>
        <span
          style={{
            color: 'var(--platform-secondary-700)',
            marginTop: '5px',
            marginRight: '11.12px',
          }}
        >
          <IconsRepository.TrophyIcon />
        </span>

        <p>
          {' '}
          <span className={styles.percentage}>{completed}% </span> Complete
        </p>
      </div>
    </div>
  )
}

export default ShadowedProgressBar
