import React from 'react'
import { IconProps } from '../types'

const TrophyIcon = ({ size = 25, color = 'currentColor', stroke = 2, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      stroke={`${stroke}`}
      viewBox='0 0 21 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M16.3656 4.66667H14.6989V3H6.36556V4.66667H4.69889C3.78223 4.66667 3.03223 5.41667 3.03223 6.33333V7.16667C3.03223 9.29167 4.63223 11.025 6.69056 11.2833C7.21556 12.5333 8.34056 13.475 9.69889 13.75V16.3333H6.36556V18H14.6989V16.3333H11.3656V13.75C12.7239 13.475 13.8489 12.5333 14.3739 11.2833C16.4322 11.025 18.0322 9.29167 18.0322 7.16667V6.33333C18.0322 5.41667 17.2822 4.66667 16.3656 4.66667ZM4.69889 7.16667V6.33333H6.36556V9.51667C5.39889 9.16667 4.69889 8.25 4.69889 7.16667ZM10.5322 12.1667C9.15723 12.1667 8.03223 11.0417 8.03223 9.66667V4.66667H13.0322V9.66667C13.0322 11.0417 11.9072 12.1667 10.5322 12.1667ZM16.3656 7.16667C16.3656 8.25 15.6656 9.16667 14.6989 9.51667V6.33333H16.3656V7.16667Z'
        fill={color}
      />
    </svg>
  )
}

export default TrophyIcon
