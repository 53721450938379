import React, { useEffect, useState } from 'react'
import Button from '../button/button.molecule'
import { ButtonTypes } from '../../atoms/enums'

import styles from './reviewcard.module.css'
import { IconsRepository } from '../../../repository/icons/icon.repository'
import { ReviewType } from '../../../services/api/course/Course.service'
import { useAppDispatch, useAppSelector } from '../../../lib/hooks'
import { RootState } from '../../../app/store'
import { courseReviewThunk } from '../../../features/course/thunks/course.thunk'
import Loader from '../../atoms/loader/Loader'
import { clearReviewState } from '../../../features/course/slices/courseReview.slice'
import { UserType } from '../../../types/models'
import Popup from '../../organisms/popup/Popup.organism'

const ReviewCard = ({ courseID }: { courseID: string }) => {
  const [hoveredIndex, setHoveredIndex] = useState(-1)
  const [reviewed, setReviewed] = useState(false)
  const [error, setError] = useState(false)
  const authState = useAppSelector((state: RootState) => state.authentication)
  const reviewState = useAppSelector((state: RootState) => state.courseReview)

  const dispatch = useAppDispatch()

  const [data, setData] = useState({
    rating: hoveredIndex + 1,
    message: '',
  })

  const submitReview = () => {
    /**
     * @todo Validate data before submitting to the server :)
     *
     * message should not be empty
     * rating + 1 (since the rating increments from 0) should be > 0 && <=5
     */

    const reviewData: { courseId: string; body: ReviewType } = {
      courseId: courseID,
      body: {
        message: data.message,
        rating: hoveredIndex + 1,
        user: authState.user as UserType,
      },
    }

    dispatch(courseReviewThunk(reviewData)).then(() => {
      setHoveredIndex(-1)
      setData({ rating: 0, message: '' })
    })
  }

  useEffect(() => {
    if (reviewState.isError) {
      /**
       * @todo Show error modal for error messages :(
       */
      setError(true)
      setTimeout(() => {
        setError(false)
      }, 3500)
      dispatch(clearReviewState())
    }

    if (reviewState.isSuccess) {
      /**
       * @todo Show success modal for success messages :)
       */
      setReviewed(true)
      setTimeout(() => {
        setReviewed(false)
      }, 3500)
      dispatch(clearReviewState())
    }
  }, [reviewState])

  return (
    <div className={styles.content__wrapper}>
      <Popup
        toggleActive={setReviewed}
        active={reviewed}
        title='Success'
        msg={'Review Made Successfully'}
      />
      <Popup
        toggleActive={setError}
        active={error}
        title='error'
        msg={'An Error Occured while saving your review.'}
      />
      <h3>Add Course Review</h3>
      <p>Rating</p>

      {reviewState.isLoading ? <Loader /> : ''}

      <div className={styles.icons}>
        {Array(5)
          .fill(0)
          .map((_v, i) => (
            <span
              onClick={() => {
                setHoveredIndex(hoveredIndex === 0 ? -1 : i)
              }}
              key={i}
              className={`${styles.icon} ${i <= hoveredIndex ? styles.scale : ''}`}
            >
              {i <= hoveredIndex ? (
                <IconsRepository.StarIcon />
              ) : (
                <IconsRepository.StarOutlinedIcon />
              )}
            </span>
          ))}
      </div>
      <p>Write review</p>
      <textarea
        name='message'
        id='message'
        cols={30}
        rows={5}
        onChange={(e) => {
          setData({ ...data, message: e.target.value })
        }}
        value={data.message}
        placeholder='How did you experience the course ?'
      ></textarea>
      <Button
        text={'Submit Review'}
        className={styles.btn}
        type={ButtonTypes.PRIMARY}
        height='48px'
        fontSize='1.6rem'
        onClick={submitReview}
      />
    </div>
  )
}

export default ReviewCard
