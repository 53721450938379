import React from 'react'

// stylesheet
import styles from './icons.module.css'

// general imports
import { IconsRepository } from '../../repository/icons/icon.repository'
import DesignGridLayout from '../../components/layouts/Grid.layout'

const IconSets = () => {
  return (
    <DesignGridLayout>
      <div className={styles.icons}>
        <div className={styles.icon}>
          <IconsRepository.UploadIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.SignalCellularAltIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.TextIncreaseIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.MenuBookIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.ArrowLeftIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.LogoIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.WorkspacePremiumIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.TrophyIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.TimeLapseIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.StarIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.SortIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.ShareIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.SettingsIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.SearchIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.PlusIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.PlayCircleFilledIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.PatternIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.OpenLockIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.OndemanVideoIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.NavMenuIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.MinusIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.MenuIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.CloseIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.LogoutIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.DropDownIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.BellIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.ActiveRadioButtonIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.DevicesOthersIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.DoneIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.FilterIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.GlobeIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.GoogleIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.LockIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.LogoAndDepartmentIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.LessonsIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.LanguageIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.InactiveRadioButtonIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.VideoPlayerIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.MobileLogoIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.ChevronRightIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.ChevronLeftIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.RocketLaunchIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.CodeIcon />
        </div>
        <div className={styles.icon}>
          <IconsRepository.QuestionAnswer />
        </div>
        <div className={styles.icon}>
          <IconsRepository.Task />
        </div>
        <div className={styles.icon}>
          <IconsRepository.Person />
        </div>
      </div>
    </DesignGridLayout>
  )
}

export default IconSets
