/* eslint-disable */

import React from 'react'
import { IconProps } from '../types'

const PatternIcon = ({
  size = 175,
  height = 155,
  color = 'currentColor',
  stroke = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      width={size}
      height={height}
      viewBox='0 0 175 155'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <circle opacity='0.1' cx='3.5' cy='4.39404' r='3.5' fill='black' />
      <circle opacity='0.1' cx='3.5' cy='25.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='3.5' cy='46.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='3.5' cy='67.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='3.5' cy='88.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='3.5' cy='109.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='3.5' cy='130.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='3.5' cy='151.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='24.5' cy='4.39404' r='3.5' fill='black' />
      <circle opacity='0.1' cx='24.5' cy='25.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='24.5' cy='46.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='24.5' cy='67.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='24.5' cy='88.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='24.5' cy='109.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='24.5' cy='130.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='24.5' cy='151.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='45.5' cy='4.39404' r='3.5' fill='black' />
      <circle opacity='0.1' cx='45.5' cy='25.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='45.5' cy='46.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='45.5' cy='67.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='45.5' cy='88.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='45.5' cy='109.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='45.5' cy='130.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='45.5' cy='151.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='66.5' cy='4.39404' r='3.5' fill='black' />
      <circle opacity='0.1' cx='66.5' cy='25.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='66.5' cy='46.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='66.5' cy='67.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='66.5' cy='88.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='66.5' cy='109.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='66.5' cy='130.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='66.5' cy='151.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='87.5' cy='4.39404' r='3.5' fill='black' />
      <circle opacity='0.1' cx='87.5' cy='25.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='87.5' cy='46.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='87.5' cy='67.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='87.5' cy='88.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='87.5' cy='109.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='87.5' cy='130.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='87.5' cy='151.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='108.5' cy='4.39404' r='3.5' fill='black' />
      <circle opacity='0.1' cx='108.5' cy='25.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='108.5' cy='46.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='108.5' cy='67.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='108.5' cy='88.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='108.5' cy='109.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='108.5' cy='130.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='108.5' cy='151.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='129.5' cy='4.39404' r='3.5' fill='black' />
      <circle opacity='0.1' cx='129.5' cy='25.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='129.5' cy='46.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='129.5' cy='67.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='129.5' cy='88.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='129.5' cy='109.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='129.5' cy='130.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='129.5' cy='151.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='150.5' cy='4.39404' r='3.5' fill='black' />
      <circle opacity='0.1' cx='150.5' cy='25.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='150.5' cy='46.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='150.5' cy='67.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='150.5' cy='88.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='150.5' cy='109.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='150.5' cy='130.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='150.5' cy='151.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='171.5' cy='4.39404' r='3.5' fill='black' />
      <circle opacity='0.1' cx='171.5' cy='25.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='171.5' cy='46.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='171.5' cy='67.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='171.5' cy='88.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='171.5' cy='109.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='171.5' cy='130.394' r='3.5' fill='black' />
      <circle opacity='0.1' cx='171.5' cy='151.394' r='3.5' fill='black' />
    </svg>
  )
}

export default PatternIcon
