import { createSlice } from '@reduxjs/toolkit'
import { courseReviewThunk } from '../thunks/course.thunk'

export interface CourseState {
  isError: boolean
  isSuccess: boolean
  isLoading: boolean
}

const initialState: CourseState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
}

const courseReviewSlice = createSlice({
  name: 'courseReviewSlice',
  initialState,
  reducers: {
    clearReviewState: (state) => {
      // eslint-disable-next-line
      state = initialState
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(courseReviewThunk.pending, (state) => {
        state.isLoading = true
        state.isError = false
        state.isSuccess = false
      })
      .addCase(courseReviewThunk.fulfilled, (state) => {
        state.isLoading = false
        state.isSuccess = true
      })
      .addCase(courseReviewThunk.rejected, (state) => {
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
      })
  },
})

export const { clearReviewState } = courseReviewSlice.actions
export default courseReviewSlice.reducer
