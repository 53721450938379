import React from 'react'
import { IconProps } from '../types'

const NavMenuIcon = ({
  size = 24,
  height = 20,
  color = 'currentColor',
  stroke = 2,
  ...props
}: IconProps) => {
  return (
    <svg
      width={size}
      height={height}
      stroke={`${stroke}`}
      viewBox='0 0 24 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.799805 2.36831C0.799805 1.48465 1.51615 0.768311 2.3998 0.768311H21.5998C22.4835 0.768311 23.1998 1.48465 23.1998 2.36831C23.1998 3.25197 22.4835 3.96831 21.5998 3.96831H2.3998C1.51615 3.96831 0.799805 3.25197 0.799805 2.36831Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.799805 10.3683C0.799805 9.48466 1.51615 8.76831 2.3998 8.76831H21.5998C22.4835 8.76831 23.1998 9.48466 23.1998 10.3683C23.1998 11.252 22.4835 11.9683 21.5998 11.9683H2.3998C1.51615 11.9683 0.799805 11.252 0.799805 10.3683Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.799805 18.3683C0.799805 17.4847 1.51615 16.7683 2.3998 16.7683H21.5998C22.4835 16.7683 23.1998 17.4847 23.1998 18.3683C23.1998 19.252 22.4835 19.9683 21.5998 19.9683H2.3998C1.51615 19.9683 0.799805 19.252 0.799805 18.3683Z'
        fill={color}
      />
    </svg>
  )
}

export default NavMenuIcon
