import { AppRouteType } from '../types'
import { appPaths } from '../constants/app-paths'

import guardController from './guards'
import IconSets from '../pages/local/IconSets'
import LandingPage from '../pages/landing/Landing.page'
import Login from '../pages/auth/login/Login.page'
import SignUp from '../pages/auth/signup/Signup.page'
import EmailVerification from '../pages/auth/email-verification/VerificationMail.page'
import ForgotPassword from '../pages/auth/forgot-password/ForgotPassword.page'
import VerificationConfirmation from '../pages/auth/email-verification/Confirmation.page'
import AllCourses from '../pages/all-courses/AllCourses.page'
import CourseCategory from '../pages/course-category/CourseCategory.page'
import FullCourse from '../pages/full-course/FullCourse.page'
import CourseDetails from '../pages/course-details/CourseDetails.page'
import CoursePreview from '../pages/course-preview/CoursePreview.page'
import Dashboard from '../pages/dashboard/index/Index.page'
import DashboardEntry from '../pages/dashboard/entry/Dashboard.page'
import Profile from '../pages/dashboard/profile/Profile.page'
import Settings from '../pages/dashboard/profile/modules/settings/Settings.module'
import Notifications from '../pages/dashboard/profile/modules/notifications/Notifications.module'
import Password from '../pages/dashboard/profile/modules/password/Password.module'
import Account from '../pages/dashboard/profile/modules/account/Account.module'
import MyCourses from '../pages/my-courses/MyCourse.page'
import SearchResults from '../pages/search-results/SearchResults'
import MaintenancePage from '../pages/maintenance/Maintenance.page'
import BootcampUI from '../pages/bootcamp-ui/BootcampUI.page'
import BootcampFullStack from '../pages/bootcamp-fullstack/BootcampFullStack.page'

export const routes: Array<AppRouteType> = [
  {
    path: appPaths.ICONS_SET,
    component: IconSets,
    guards: [],
  },
  {
    path: appPaths.HOME_PAGE,
    component: LandingPage,
    guards: [],
  },
  {
    path: appPaths.LOGIN,
    component: Login,
    guards: [guardController.verifyAuthRouteAccess],
  },
  {
    path: appPaths.REGISTER,
    component: SignUp,
    guards: [],
  },
  {
    path: appPaths.VERIFY_EMAIL,
    component: EmailVerification,
    guards: [],
  },
  {
    path: appPaths.FORGOT_PASS,
    component: ForgotPassword,
    guards: [],
  },
  {
    path: appPaths.VERIFICATION_CONFIRMATION,
    component: VerificationConfirmation,
    guards: [],
  },
  {
    path: appPaths.COURSES_PAGE,
    component: AllCourses,
    guards: [],
  },
  {
    path: appPaths.COURSE_CATEGORY,
    component: CourseCategory,
    guards: [],
  },
  {
    path: appPaths.FULL_COURSE_PAGE,
    component: FullCourse,
    guards: [],
  },
  {
    path: appPaths.COURSE_PREVIEW_PAGE,
    component: CoursePreview,
    guards: [],
  },
  {
    path: appPaths.COURSE_DETAILS,
    component: CourseDetails,
    guards: [],
  },
  {
    path: appPaths.UI_BOOTCAMP,
    component: BootcampUI,
    guards: [],
  },
  {
    path: appPaths.FULLSTACK_BOOTCAMP,
    component: BootcampFullStack,
    guards: [],
  },
  {
    path: appPaths.SEARCH_RESULTS,
    component: SearchResults,
    guards: [],
  },
  {
    path: appPaths.MAINTENANCE,
    component: MaintenancePage,
    guards: [],
  },
  {
    path: appPaths.DASHBOARD,
    component: DashboardEntry,
    guards: [guardController.verifyUserAuthenticity],
    nestedComponents: [
      {
        path: '/',
        component: Dashboard,
        guards: [],
      },
      {
        path: appPaths.MY_COURSES,
        component: MyCourses,
        guards: [],
      },
      {
        path: appPaths.D_PROFILE,
        component: Profile,
        guards: [],
        nestedComponents: [
          {
            path: '/',
            component: Settings,
            guards: [],
          },
          {
            path: appPaths.D_NOTIFICATIONS,
            component: Notifications,
            guards: [],
          },
          {
            path: appPaths.D_PASSWORD,
            component: Password,
            guards: [],
          },
          {
            path: appPaths.D_ACCOUNT,
            component: Account,
            guards: [],
          },
        ],
      },
    ],
  },
]
