import React from 'react'
import styles from './bootcamp.module.css'
import { IconsRepository } from '../../../../repository/icons/icon.repository'
import Button from '../../../molecules/button/button.molecule'
import { ButtonTypes } from '../../../atoms/enums'
import formatNumber from '../../../../lib/utils/formatNumber'
import { useNavigate } from 'react-router-dom'

interface BootcampProps {
  image: string
  title: string
  descr1: string
  descr2: string
  duration: string
  price: number
  link?: string
}

const BootcampCard: React.FC<BootcampProps> = ({
  image,
  title,
  descr1,
  descr2,
  duration,
  price,
  link,
}) => {
  const navigate = useNavigate()

  return (
    <div className={styles.body}>
      <img className={styles.image} src={image} alt={title} />
      <div className={styles.content}>
        <h5 className={styles.title}>{title}</h5>
        <p className={styles.text}>{descr1}</p>
        <p className={styles.text}>{descr2}</p>
        <div className={styles.infos}>
          <span className={styles.info}>
            <IconsRepository.TimeLapseIcon
              size={15}
              height={15}
              color='var(--camsol-neutral-700)'
            />
            {duration}
          </span>
          {/* <span className={styles.info}>
            <IconsRepository.NumbersIcon size={17} height={17} color='var(--camsol-neutral-700)' />
            {students}
          </span> */}
        </div>
        <Button
          type={ButtonTypes.PRIMARY}
          onClick={() => {
            if (link) {
              navigate(link)
            }
          }}
          text={
            <span className='price_span'>
              <span>Enroll Now for</span>
              {formatNumber(price)}frs
            </span>
          }
          fontSize='16px'
        />
      </div>
    </div>
  )
}

export default BootcampCard
