import React, { useEffect, useState } from 'react'
import Button from '../../molecules/button/button.molecule'

import styles from './blocks.module.css'
import { ButtonTypes } from '../../atoms/enums'
import { EmailStructure } from '../../../types/auth.types'
import CustomInputField from '../../molecules/input/CustomInput.molecule'
import { BlockClickProps } from '../email-verification/Block1.template'
import { useAppDispatch, useAppSelector } from '../../../lib/hooks'
import { forgotPasswordThunkService } from '../../../features/authentication/authenticationThunks'
import { RootState } from '../../../app/store'
import UseError from '../../../lib/hooks/useError'
import { resetForgotPasswordSlice } from '../../../features/authentication/forgotPasswordSlice'
import Popup from '../../organisms/popup/Popup.organism'
import { APIStatus } from '../../../constants/configs'
import Loader from '../../atoms/loader/Loader'

const Block1: React.FC<BlockClickProps> = ({ setBlock, setEmail }) => {
  const [formData, setFormData] = useState<EmailStructure>({
    email: '',
  })
  const dispatch = useAppDispatch()
  const forgotPasswordState = useAppSelector((state: RootState) => state.forgotPassword)
  const { setErrorActive, errorActive } = UseError()

  const { email } = formData

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
    if (setEmail !== undefined) {
      setEmail(e.target.value)
    }
  }

  const resetPassword = () => {
    /**
     * @todo validate email before submission
     */

    dispatch(forgotPasswordThunkService({ email: formData.email }))
  }

  useEffect(() => {
    if (forgotPasswordState.errorTrace === true) {
      setErrorActive(true)
    }

    if (forgotPasswordState.status === APIStatus.FULFILLED) {
      setBlock(1)
      dispatch(resetForgotPasswordSlice())
    }

    setTimeout(() => {
      dispatch(resetForgotPasswordSlice())
    }, 5000)
  }, [forgotPasswordState.errorTrace, forgotPasswordState.status])

  return (
    <div className={styles.container}>
      {errorActive && forgotPasswordState.message.length > 0 ? (
        <Popup
          toggleActive={setErrorActive}
          active={errorActive}
          title='error'
          msg={forgotPasswordState.message}
        />
      ) : (
        ''
      )}
      {forgotPasswordState.status === APIStatus.FULFILLED ? (
        <Popup
          toggleActive={setErrorActive}
          active={forgotPasswordState.status === APIStatus.FULFILLED}
          title='success'
          msg={'Code sent!'}
        />
      ) : (
        ''
      )}
      {forgotPasswordState.codeSent === APIStatus.PENDING ? <Loader /> : ''}
      <h3>Forgot Password?</h3>
      <p style={{ marginBottom: '2.5rem' }}>No worries we will send you reset instructions</p>
      <div>
        <span className={styles.field__title}>
          Email<sup>*</sup>
        </span>
        <div style={{ marginBottom: '2.4rem', marginTop: '0.8rem' }}>
          <CustomInputField
            name='email'
            type='email'
            value={email}
            onChange={onChange}
            placeholder='Enter your Email'
          />
        </div>
        <Button
          type={ButtonTypes.PRIMARY}
          text={
            forgotPasswordState.status === APIStatus.PENDING ? 'Please wait...' : 'Reset Password'
          }
          width='100%'
          disabled={forgotPasswordState.status === APIStatus.PENDING}
          // height='48px'
          fontSize='16px'
          onClick={() => {
            resetPassword()
          }}
        />
      </div>
    </div>
  )
}

export default Block1
