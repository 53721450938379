import React from 'react'

import { IconProps } from '../types'

const MenuBookIcon = ({ size = 24, color = 'currentColor', stroke = 2, ...props }: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      stroke={`${stroke}`}
      viewBox='0 0 16 16'
      fill='none'
      {...props}
    >
      <path
        d='M14.0003 2.66667C13.2603 2.43333 12.447 2.33333 11.667 2.33333C10.367 2.33333 8.96699 2.6 8.00033 3.33333C7.03366 2.6 5.63366 2.33333 4.33366 2.33333C3.03366 2.33333 1.63366 2.6 0.666992 3.33333V13.1C0.666992 13.2667 0.833659 13.4333 1.00033 13.4333C1.06699 13.4333 1.10033 13.4 1.16699 13.4C2.06699 12.9667 3.36699 12.6667 4.33366 12.6667C5.63366 12.6667 7.03366 12.9333 8.00033 13.6667C8.90032 13.1 10.5337 12.6667 11.667 12.6667C12.767 12.6667 13.9003 12.8667 14.8337 13.3667C14.9003 13.4 14.9337 13.4 15.0003 13.4C15.167 13.4 15.3337 13.2333 15.3337 13.0667V3.33333C14.9337 3.03333 14.5003 2.83333 14.0003 2.66667ZM14.0003 11.6667C13.267 11.4333 12.467 11.3333 11.667 11.3333C10.5337 11.3333 8.90032 11.7667 8.00033 12.3333V4.66667C8.90032 4.1 10.5337 3.66667 11.667 3.66667C12.467 3.66667 13.267 3.76667 14.0003 4V11.6667Z'
        fill={color}
      />
      <path
        d='M11.667 6.33333C12.2537 6.33333 12.8203 6.39333 13.3337 6.50667V5.49333C12.807 5.39333 12.2403 5.33333 11.667 5.33333C10.5337 5.33333 9.50699 5.52667 8.66699 5.88667V6.99333C9.42033 6.56667 10.467 6.33333 11.667 6.33333Z'
        fill={color}
      />
      <path
        d='M8.66699 7.66V8.76667C9.42033 8.34 10.467 8.10667 11.667 8.10667C12.2537 8.10667 12.8203 8.16667 13.3337 8.28V7.26667C12.807 7.16667 12.2403 7.10667 11.667 7.10667C10.5337 7.10667 9.50699 7.30667 8.66699 7.66Z'
        fill={color}
      />
      <path
        d='M11.667 8.88667C10.5337 8.88667 9.50699 9.08 8.66699 9.44V10.5467C9.42033 10.12 10.467 9.88667 11.667 9.88667C12.2537 9.88667 12.8203 9.94667 13.3337 10.06V9.04667C12.807 8.94 12.2403 8.88667 11.667 8.88667Z'
        fill={color}
      />
    </svg>
  )
}

export default MenuBookIcon
